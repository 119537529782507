<app-aside [isLoadEnable]="this.isLoadEnable"></app-aside>
<div class="container-fluid container-application">
  <app-sidebar [UserModel]="DataModel"></app-sidebar>
  <!-- Content -->
  <div class="main-content position-relative">
    <app-header [UserModel]="DataModel"></app-header>
    <!-- Page content -->
    <div class="page-content">
      <!-- Page title -->
      <div class="page-title">
        <div class="row justify-content-between align-items-center">
          <div class="col-md-6 d-flex align-items-center justify-content-between justify-content-md-start mb-3 mb-md-0">
            <!-- Page title + Go Back button -->
            <div class="d-inline-block">
              <h5 class="h4 d-inline-block font-weight-400 mb-0 text-white">Account settings</h5>
            </div>
            <!-- Additional info -->
          </div>
          <div class="col-md-6 d-flex align-items-center justify-content-between justify-content-md-end">
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-4 order-lg-2">
          <div class="card">
            <div class="list-group list-group-flush">
              <div class="list-group-item active">
                <div class="media">
                  <i class="far fa-user"></i>
                  <div class="media-body ml-3">
                    <a [routerLink]="" routerLinkActive="router-link-active" class="stretched-link h6 mb-1">Profile</a>
                    <p class="mb-0 text-sm">Details about your personal information</p>
                  </div>
                </div>
              </div>
              <div class="list-group-item">
                <div class="media">
                  <i class="fas fa-cog"></i>
                  <div class="media-body ml-3">
                    <a [routerLink]="['/admin/setting']" routerLinkActive="router-link-active"
                      class="stretched-link h6 mb-1">Settings</a>
                    <p class="mb-0 text-sm">Security and passwords</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-8 order-lg-1">
          <form role="form" name="updateProfileForm" [formGroup]="updateProfileForm" (ngSubmit)="updateProfile()">

            <!--REACTIVE FORM VALIDATION-->
            <div *ngIf="this.isLoginOnSubmitErrorEnabled" class="alert alert-danger">
              {{ this.errorOnLoginSubmit }}
            </div>
            <!--REACTIVE FORM VALIDATION-->

            <!-- Change avatar -->
            <div class="card bg-gradient-info hover-shadow-lg border-0">
              <div class="card-body py-3">
                <div class="row row-grid align-items-center">
                  <div class="col-lg-8">
                    <div class="media align-items-center">
                      <a href="#" class="avatar avatar-lg rounded-circle mr-3">
                        <img alt="Image placeholder" src="{{ this.baseUrl+this.DataModel.UserImage }}">
                      </a>
                      <div class="media-body">
                        <h5 class="text-white mb-0">{{ this.DataModel.UserFullName }}</h5>
                        <div>
                          <input type="file" (change)="uploadFile($event)" accept=".png, .jpg, .jpeg" name="UserImage"
                            id="file-1" class="custom-input-file custom-input-file-link" />
                          <label for="file-1">
                            <span class="text-white">{{ this.avatarLabel }}</span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-auto flex-fill mt-4 mt-sm-0 text-sm-right d-none d-lg-block">
                    <a class="btn btn-sm btn-white rounded-pill btn-icon shadow">
                      <span class="btn-inner--icon"><i class="fas fa-fire"></i></span>
                      <span class="btn-inner--text">Super {{ this.DataModel.Role }}</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div class="card">
              <div class="card-body">
                <!-- General information -->
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label class="form-control-label">Full Name*</label>
                      <input formControlName="UserFullName" class="form-control" type="text"
                        placeholder="Enter your full name">
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="form-control-label">Email Address*</label>
                      <input readonly class="form-control" type="text" value="{{ this.DataModel.UserEmail }}">
                      <small class="form-text text-muted mt-2">This email address can't be changed.</small>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="form-control-label">Phone*</label>
                      <input formControlName="UserPhone" class="form-control" type="text" placeholder="+40-777 245 549">
                    </div>
                  </div>
                </div>
                <div class="row align-items-center">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="form-control-label">Birthday*</label>
                      <input formControlName="UserBirthDate" class="form-control" type="date" id="example-date-input">
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="form-control-label">Gender*</label>
                      <select formControlName="IsUserMale" class="form-control">
                        <option selected value="" disabled>Please select your gender</option>
                        <option value="1">Male</option>
                        <option value="0">Female</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label class="form-control-label">Country</label>
                      <select formControlName="UserCountry" class="form-control" data-toggle="select" title="Country"
                        data-live-search="true" data-live-search-placeholder="Country">
                        <option selected value="{{ this.DataModel.UserCountry }}">{{ this.DataModel.UserCountry }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                <hr />
                <!-- Description -->
                <div class="row">
                  <div class="col">
                    <div class="form-group">
                      <div class="form-group">
                        <label class="form-control-label">About Myself*</label>
                        <textarea formControlName="UserAboutDetail" class="form-control"
                          placeholder="Tell us a few words about yourself" rows="3"></textarea>
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
                <!-- Social Media -->
                <div class="row">
                  <div class="col-6">
                    <div class="form-group">
                      <div class="form-group">
                        <label class="form-control-label">Instagram (Optional)</label>
                        <input formControlName="UserInstagram" class="form-control" type="text"
                          placeholder="instagram.com/trendsfore">
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="form-group">
                      <div class="form-group">
                        <label class="form-control-label">Linkedin (Optional)</label>
                        <input formControlName="UserLinkedin" class="form-control" type="text"
                          placeholder="linkedin.com/in/trendsfore">
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="form-group">
                      <div class="form-group">
                        <label class="form-control-label">Youtube (Optional)</label>
                        <input formControlName="UserYoutube" class="form-control" type="text"
                          placeholder="youtube.com/trendsfore">
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="form-group">
                      <div class="form-group">
                        <label class="form-control-label">Twitter (Optional)</label>
                        <input formControlName="UserTwitter" class="form-control" type="text"
                          placeholder="twitter.com/trendsfore">
                      </div>
                    </div>
                  </div>

                </div>
                <hr />
                <!-- Save changes buttons -->
                <button type="submit" class="btn btn-sm btn-primary rounded-pill">Save changes</button>
                <button type="button" class="btn btn-link text-muted">Cancel</button>

              </div>
            </div>

          </form>
        </div>

      </div>
    </div>
    <app-footer></app-footer>
  </div>
</div>
