import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TrendsforeService } from '../../../services/trendsfore.service';
import { adminLoginScreen } from '../../../models/admin/login';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  PasswordText: string;
  isPasswordFocussed: boolean;
  isEmailFocussed: boolean;
  isLoginOnSubmitErrorEnabled: boolean;
  errorOnLoginSubmit: string;
  registrationCommunity: adminLoginScreen.RegistrationCommunity;
  loginFormData: adminLoginScreen.LoginForm;
  adminLogin: adminLoginScreen.AdminLogin;

  constructor(
    private router: Router,
    private toastr: ToastrService,
    private trendsfore: TrendsforeService
  ) {}
  ngOnInit(): void {
    this.PasswordText = 'Password';
    this.isLoginOnSubmitErrorEnabled = false;
    this.loginForm = new FormGroup({
      AdminEmail: new FormControl('', [Validators.required, Validators.email]),
      AdminPassword: new FormControl('', [
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(30),
      ]),
    });
  }

  setFocussed(type: number) {
    if (type === 1) {
      this.isPasswordFocussed = false;
      this.isEmailFocussed = true;
    } else {
      this.isPasswordFocussed = true;
      this.isEmailFocussed = false;
    }
  }

  setblur() {
    this.isPasswordFocussed = false;
    this.isEmailFocussed = false;
  }

  setPasswordField(type: number) {
    if (type === 1) {
      this.PasswordText = 'Text';
    } else {
      this.PasswordText = 'Password';
    }
  }

  submitLoginForm() {
    if (this.loginForm.valid) {
      //alert(JSON.stringify(this.loginForm.value));
      //this.toastr.success("Welcome Abroad", "Login");
      this.loginFormData = new adminLoginScreen.LoginForm();
      this.loginFormData.email = this.loginForm.get('AdminEmail').value;
      this.loginFormData.password = this.loginForm.get('AdminPassword').value;
      this.trendsfore
        .adminLogin(this.loginFormData)
        .subscribe((response: adminLoginScreen.AdminLogin) => {
          this.adminLogin = response;
          if (this.adminLogin.Flag) {
            this.trendsfore.setToken(
              this.adminLogin.Data.Token,
              this.adminLogin.Data
            );
            this.trendsfore.login();
          } else {
            this.loginForm.reset();
            this.enableError(this.adminLogin.Message);
          }
        });
    } else {
      this.enableError('Invalid login credentials');
      //this.toastr.error("Invalid Login Credentials!", "Login");
    }
    //this.router.navigateByUrl('/admin/dashboard');
  }

  private enableError(error: string): void {
    this.errorOnLoginSubmit = error;
    this.isLoginOnSubmitErrorEnabled = true;
    setTimeout(() => {
      this.isLoginOnSubmitErrorEnabled = false;
    }, 5000);
  }
}
