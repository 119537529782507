import { Component, Input, OnInit } from '@angular/core';
import { TrendsforeService } from '../../../../services/trendsfore.service';
import { userManagement } from '../../../../models/admin/login';
@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css'],
})
export class UsersComponent implements OnInit {
  Users: userManagement.User[];
  NumId: number;
  NumStars: number;
  IsStars: boolean;
  baseUrl: String;
  errorOnLoginSubmit: String;
  isLoginOnSubmitErrorEnabled: boolean;
  pageNo: number;
  isLoadMoreVisible: boolean;
  isLoadEnable: boolean;
  volunteers: number;
  nonVolunteer: number;
  constructor(protected trendsfore: TrendsforeService) {}

  ngOnInit(): void {
    if (this.trendsfore.VerifyToken()) {
      this.baseUrl = this.trendsfore.imageUrl();
      this.isLoadMoreVisible = true;
      this.isLoadEnable = true;
      this.pageNo = 1;
      this.getUsers(this.pageNo.toString());
    }
  }

  setId(Id: number, mode: boolean) {
    this.NumId = Id;
    this.IsStars = mode;
  }

  addCoins() {
    if (this.NumId !== 0 && this.NumStars !== 0) {
      this.isLoadEnable = true;
      const formData = new FormData();
      formData.append('ReferenceType', 'user');
      formData.append('ReceiverId', this.NumId.toString());
      formData.append('Donation', this.NumStars.toString());
      this.trendsfore.addCoins(formData).subscribe((data) => {
        if (data.Flag) {
          this.refresh();
          this.trendsfore.success(
            'User received coins successfully',
            'Trendsfore Users'
          );
          this.NumStars = 0;
        } else {
          this.isLoadEnable = false;
          this.trendsfore.error(data.Message, 'Trendsfore Users');
        }
      });
    } else {
      this.trendsfore.error("Coins Can't be null or empty", 'Trendsfore Users');
    }
  }

  addStars() {
    if (this.NumId !== 0 && this.NumStars !== 0) {
      this.isLoadEnable = true;
      const formData = new FormData();
      formData.append('ReferenceType', 'user');
      formData.append('ReceiverId', this.NumId.toString());
      formData.append('Stars', this.NumStars.toString());
      this.trendsfore.addStars(formData).subscribe((data) => {
        if (data.Flag) {
          this.refresh();
          this.trendsfore.success(
            'User received stars successfully',
            'Trendsfore Users'
          );
          this.NumStars = 0;
        } else {
          this.isLoadEnable = false;
          this.trendsfore.error(data.Message, 'Trendsfore Users');
        }
      });
    } else {
      this.trendsfore.error("Stars Can't be null or empty", 'Trendsfore Users');
    }
  }

  search(value: string) {
    if (value !== '') {
      this.trendsfore
        .searchedUsers(value)
        .subscribe((data: userManagement.AdminUsers) => {
          if (data.Flag) {
            this.Users = data.Data;
            this.countUsers(this.Users);
          }
        });
    } else {
      this.refresh();
    }
  }
  delete(Id: number) {
    if (confirm('Are you sure, you want to delete this user?')) {
      this.trendsfore.deleteUser(Id).subscribe((data) => {
        if (data.Flag) {
          this.trendsfore.success('User has been deleted', 'Trendsfore Users');
          this.refresh();
        } else {
          this.trendsfore.error(data.Message, 'Trendsfore Users');
        }
      });
    }
  }
  refresh() {
    this.pageNo = 1;
    this.isLoadEnable = true;
    this.getUsers(this.pageNo.toString());
    this.isLoadMoreVisible = true;
  }

  loadMore() {
    this.pageNo += 1;
    this.isLoadEnable = true;
    this.getUsers(this.pageNo.toString());
  }

  private getUsers(page: string) {
    this.trendsfore.users(page).subscribe((data: userManagement.AdminUsers) => {
      if (data.Flag) {
        if (page == '1') {
          this.Users = data.Data;
          this.isLoadEnable = false;
          this.countUsers(this.Users);
        } else {
          this.Users = this.Users.concat(data.Data);
          this.countUsers(this.Users);
          this.isLoadEnable = false;
        }
      } else {
        this.isLoadMoreVisible = false;
        this.isLoadEnable = false;
      }
    });
  }

  private countUsers(users: any) {
    this.volunteers = 0;
    this.nonVolunteer = 0;
    users.forEach((data) => {
      if (data.UserRole.toLowerCase() == 'digital volunteer') {
        this.volunteers += 1;
      } else {
        this.nonVolunteer += 1;
      }
    });
  }

  private enableError(error: string): void {
    this.errorOnLoginSubmit = error;
    this.isLoginOnSubmitErrorEnabled = true;
    setTimeout(() => {
      this.isLoginOnSubmitErrorEnabled = false;
    }, 3000);
  }
}
