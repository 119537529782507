import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-community-profile',
  templateUrl: './community-profile.component.html',
  styleUrls: ['./community-profile.component.css']
})
export class CommunityProfileComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
