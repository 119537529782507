import { Component, OnInit } from '@angular/core';
import { TrendsforeService } from '../../../../services/trendsfore.service';
import { pageManagement } from '../../../../models/admin/login';

@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.css'],
})
export class PagesComponent implements OnInit {
  PageModel: pageManagement.Page[];
  NumId: number;
  NumStars: number;
  IsStars: boolean;
  pageNo: number;
  baseUrl: String;
  isLoadEnable: boolean;
  isLoadMoreVisible: boolean;
  constructor(protected trendsfore: TrendsforeService) {}

  ngOnInit(): void {
    if (this.trendsfore.VerifyToken()) {
      this.baseUrl = this.trendsfore.imageUrl();
      this.isLoadMoreVisible = true;
      this.isLoadEnable = true;
      this.pageNo = 1;
      this.getPages(this.pageNo.toString());
    }
  }

  setId(Id: number, mode: boolean) {
    this.NumId = Id;
    this.IsStars = mode;
  }

  addCoins() {
    if (this.NumId !== 0 && this.NumStars !== 0) {
      this.isLoadEnable = true;
      const formData = new FormData();
      formData.append('ReferenceType', 'page');
      formData.append('ReceiverId', this.NumId.toString());
      formData.append('Donation', this.NumStars.toString());
      this.trendsfore.addCoins(formData).subscribe((data) => {
        if (data.Flag) {
          this.refresh();
          this.trendsfore.success(
            'Page received coins successfully',
            'Trendsfore Pages'
          );
          this.NumStars = 0;
        } else {
          this.isLoadEnable = false;
          this.trendsfore.error(data.Message, 'Trendsfore Pages');
        }
      });
    } else {
      this.trendsfore.error("Coins Can't be null or empty", 'Trendsfore Pages');
    }
  }

  addStars() {
    if (this.NumId !== 0 && this.NumStars !== 0) {
      this.isLoadEnable = true;
      const formData = new FormData();
      formData.append('ReferenceType', 'page');
      formData.append('ReceiverId', this.NumId.toString());
      formData.append('Stars', this.NumStars.toString());
      this.trendsfore.addStars(formData).subscribe((data) => {
        if (data.Flag) {
          this.refresh();
          this.trendsfore.success(
            'Page received stars successfully',
            'Trendsfore Pages'
          );
          this.NumStars = 0;
        } else {
          this.isLoadEnable = false;
          this.trendsfore.error(data.Message, 'Trendsfore Pages');
        }
      });
    } else {
      this.trendsfore.error("Stars Can't be null or empty", 'Trendsfore Pages');
    }
  }

  search(value: string) {
    if (value !== '') {
      this.trendsfore
        .searchPages(value)
        .subscribe((data: pageManagement.Pages) => {
          if (data.Flag) {
            this.PageModel = data.Data;
          }
        });
    } else {
      this.refresh();
    }
  }

  delete(Id: number) {
    if (confirm('Are you sure, you want to delete this page?')) {
      this.trendsfore.deletePage(Id).subscribe((data) => {
        if (data.Flag) {
          this.trendsfore.success('Page has been deleted', 'Trendsfore Pages');
          this.refresh();
        } else {
          this.trendsfore.error(data.Message, 'Trendsfore Pages');
        }
      });
    }
  }

  upgrade(Id: number) {
    if (confirm('Are you sure, you want to upgrade this page?')) {
      this.trendsfore.upgradePage(Id).subscribe((data) => {
        if (data.Flag) {
          this.trendsfore.success(
            'Page has been upgraded to premium',
            'Trendsfore Pages'
          );
          this.refresh();
        } else {
          this.trendsfore.error(data.Message, 'Trendsfore Pages');
        }
      });
    }
  }

  refresh() {
    this.pageNo = 1;
    this.isLoadEnable = true;
    this.getPages(this.pageNo.toString());
    this.isLoadMoreVisible = true;
  }

  loadMore() {
    this.pageNo += 1;
    this.isLoadEnable = true;
    this.getPages(this.pageNo.toString());
  }

  private getPages(page: string) {
    this.trendsfore.pages(page).subscribe((data: pageManagement.Pages) => {
      if (data.Flag) {
        if (page == '1') {
          this.PageModel = data.Data;
          this.isLoadEnable = false;
        } else {
          this.PageModel = this.PageModel.concat(data.Data);
          this.isLoadEnable = false;
        }
      } else {
        this.isLoadMoreVisible = false;
        this.isLoadEnable = false;
      }
    });
  }
}
