import { Component, OnInit } from '@angular/core';
import {
  FormControl,
  FormGroup,
  Validators,
  FormBuilder,
} from '@angular/forms';
import { TrendsforeService } from '../../../../services/trendsfore.service';
import { adminProfile, adminLoginScreen } from '../../../../models/admin/login';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css'],
})
export class ProfileComponent implements OnInit {
  DataModel: adminProfile.User;
  baseUrl: String;
  updateProfileForm: FormGroup;
  updateProfileModel: adminProfile.UpdateProfile;
  errorOnLoginSubmit: String;
  isLoginOnSubmitErrorEnabled: boolean;
  allowedExtensions: Array<string>;
  avatarLabel: String = 'Change avatar';
  isLoadEnable: boolean;

  constructor(
    private formBuilder: FormBuilder,
    private trendsfore: TrendsforeService
  ) {}

  ngOnInit(): void {
    if (this.trendsfore.VerifyToken()) {
      this.isLoadEnable = true;
      setTimeout(() => {
        this.isLoadEnable = false;
      }, 1000);
      this.allowedExtensions = ['png', 'jpg', 'jpeg'];
      this.baseUrl = this.trendsfore.imageUrl();
      this.DataModel = this.trendsfore.getData()['User'];
      this.updateProfileForm = this.formBuilder.group({
        UserFullName: new FormControl(''),
        UserBirthDate: new FormControl(''),
        UserPhone: new FormControl(''),
        IsUserMale: new FormControl(''),
        UserCountry: new FormControl(''),
        UserAboutDetail: new FormControl(''),
        UserImage: new FormControl(''),
        UserInstagram: new FormControl(''),
        UserLinkedin: new FormControl(''),
        UserYoutube: new FormControl(''),
        UserTwitter: new FormControl(''),
      });
      this.updateProfileForm.patchValue({
        UserFullName: this.DataModel.UserFullName,
      });
      this.updateProfileForm.patchValue({
        UserBirthDate: this.DataModel.UserBirthDate,
      });
      this.updateProfileForm.patchValue({
        UserPhone: this.DataModel.UserPhone,
      });
      this.updateProfileForm.patchValue({
        UserInstagram: this.DataModel.UserInstagram,
      });
      this.updateProfileForm.patchValue({
        UserLinkedin: this.DataModel.UserLinkedin,
      });
      this.updateProfileForm.patchValue({
        UserYoutube: this.DataModel.UserYoutube,
      });
      this.updateProfileForm.patchValue({
        UserTwitter: this.DataModel.UserTwitter,
      });
      this.updateProfileForm.patchValue({
        UserAboutDetail: this.DataModel.UserAboutDetail,
      });
      this.updateProfileForm.patchValue({
        UserCountry: this.DataModel.UserCountry,
      });
      this.updateProfileForm.patchValue({
        IsUserMale: this.DataModel.IsUserMale,
      });
    }
  }

  uploadFile(event) {
    const file = (event.target as HTMLInputElement).files[0];
    this.updateProfileForm.patchValue({ UserImage: file });
    this.avatarLabel = file.name;
    /*
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.updateProfileForm.patchValue({ UserImage: reader.result });
    };*/
    //this.updateProfileForm.get('UserImage').updateValueAndValidity();
    //this.form.get('avatar').updateValueAndValidity()
  }

  updateProfile() {
    if (this.updateProfileForm.valid) {
      const formData = new FormData();
      formData.append(
        'UserImage',
        this.updateProfileForm.get('UserImage').value
      );
      formData.append(
        'UserFullName',
        this.updateProfileForm.get('UserFullName').value
      );
      formData.append(
        'UserPhone',
        this.updateProfileForm.get('UserPhone').value
      );
      formData.append(
        'UserCountry',
        this.updateProfileForm.get('UserCountry').value
      );
      formData.append(
        'UserBirthDate',
        this.updateProfileForm.get('UserBirthDate').value
      );
      formData.append(
        'UserAboutDetail',
        this.updateProfileForm.get('UserAboutDetail').value
      );
      formData.append(
        'IsUserMale',
        this.updateProfileForm.get('IsUserMale').value
      );
      formData.append(
        'UserInstagram',
        this.updateProfileForm.get('UserInstagram').value
      );
      formData.append(
        'UserLinkedin',
        this.updateProfileForm.get('UserLinkedin').value
      );
      formData.append(
        'UserTwitter',
        this.updateProfileForm.get('UserTwitter').value
      );
      formData.append(
        'UserYoutube',
        this.updateProfileForm.get('UserYoutube').value
      );
      this.trendsfore
        .updateProfile(formData)
        .subscribe((response: adminProfile.UpdatedProfile) => {
          if (response.Flag) {
            this.trendsfore.success('Profile has been updated', 'Profile');
            this.DataModel = response.Data.User;
            this.trendsfore.setAdminData(response.Data);
            this.ngOnInit();
          } else {
            this.enableError(response.Message);
          }
        });
    } else {
      this.enableError('Incomplete update request');
    }
  }

  private enableError(error: string): void {
    this.errorOnLoginSubmit = error;
    this.isLoginOnSubmitErrorEnabled = true;
    setTimeout(() => {
      this.isLoginOnSubmitErrorEnabled = false;
    }, 10000);
  }
}
