<!--LOADER-->
<div class="loader-container" *ngIf="this.isLoadEnable">
  <div class="main-loader">
  </div>
</div>
<!--LOADER-->
<app-aside></app-aside>
<div class="container-fluid container-application">
  <app-sidebar></app-sidebar>
  <!-- Content -->
  <div class="main-content position-relative">
    <app-header></app-header>

    <!-- Modal -->
      <div class="modal fade" id="modal-change-username" tabindex="-1" role="dialog"
        aria-labelledby="modal-change-username" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <div class="modal-title d-flex align-items-center" id="modal-title-change-username">
                  <div>
                    <div class="icon icon-sm icon-shape icon-info rounded-circle shadow mr-3">
                      <i class="fas fa-star" *ngIf="this.IsStars"></i>
                      <i class="fas fa-donate" *ngIf="!this.IsStars"></i>
                    </div>
                  </div>
                  <div>
                    <h6 class="mb-0" *ngIf="this.IsStars">Add Stars</h6>
                    <h6 class="mb-0" *ngIf="!this.IsStars">Add Coins</h6>
                  </div>
                </div>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <div class="row">
                  <div class="col-12">
                    <div class="form-group">
                      <label class="form-control-label" *ngIf="this.IsStars">Stars</label>
                      <label class="form-control-label" *ngIf="!this.IsStars">Coins</label>
                      <input class="form-control" type="hidden" [(ngModel)]="NumId">
                      <input class="form-control" type="number" placeholder="Enter numbers" [(ngModel)]="NumStars">
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button *ngIf="!this.IsStars" type="button" (click)="addCoins()" class="btn btn-sm btn-secondary rounded-pill" data-dismiss="modal">Add</button>
                <button *ngIf="this.IsStars" type="button" (click)="addStars()" class="btn btn-sm btn-secondary rounded-pill" data-dismiss="modal">Add</button>
              </div>
            </div>
        </div>
      </div>



    <!-- Page content -->
    <div class="page-content">
      <!-- Page title -->
      <div class="page-title" *ngIf="this.Users?.length > 0">
        <div class="row justify-content-between align-items-center">
          <div class="col-md-6 d-flex align-items-center justify-content-between justify-content-md-start mb-3 mb-md-0">
            <!-- Page title + Go Back button -->
            <div class="d-inline-block">
              <h5 class="h4 d-inline-block font-weight-400 mb-0 text-white">Application Users</h5>
            </div>
            <!-- Additional info -->
            <div class="align-items-center ml-4 d-inline-flex">
              <span class="h4 text-info mb-0 mr-2">{{ this.Users?.length }}</span>
              <span class="text-sm opacity-7 text-white">Total users</span>
            </div>
          </div>
          <div class="col-md-6 d-flex align-items-center justify-content-between justify-content-md-end">
            <!-- Filter input -->
            <form class="bg-neutral rounded-pill d-inline-block">
              <div class="input-group input-group-sm input-group-merge input-group-flush">
                <div class="input-group-prepend">
                  <span class="input-group-text bg-transparent"><i class="fas fa-filter"></i></span>
                </div>
                <input type="text" (keyup)="search($event.target.value)" class="form-control form-control-flush"
                  placeholder="Filter results">
              </div>
            </form>
            <a [routerLink]="" (click)="refresh()" class="btn btn-sm btn-white btn-icon-only rounded-circle ml-4">
              <span class="btn-inner--icon"><i class="fas fa-sync"></i></span>
            </a>
          </div>
        </div>
      </div>

      <div class="card" *ngIf="this.Users?.length <= 0">
        <img class="img-center img" src="../../../assets/img/errors/nodata-found.png" alt="NO DATA">
      </div>
      <!-- Stats -->
      <div class="row" *ngIf="this.Users?.length > 0">
        <div class="col-lg-4">
          <div class="card card-stats">
            <!-- Card body -->
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h6 class="text-muted mb-1">Total Users</h6>
                  <span class="h5 font-weight-bold mb-0">{{ this.Users?.length }}</span>
                </div>
                <div class="col-auto">
                  <div class="icon bg-gradient-info text-white rounded-circle icon-shape">
                    <i class="fas fa-users"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="card card-stats">
            <!-- Card body -->
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h6 class="text-muted mb-1">Total Volunteers</h6>
                  <span class="h5 font-weight-bold mb-0">{{ this.volunteers }}</span>
                </div>
                <div class="col-auto">
                  <div class="icon bg-gradient-info text-white rounded-circle icon-shape">
                    <i class="fas fa-user-tag"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="card card-stats">
            <!-- Card body -->
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h6 class="text-muted mb-1">Total Non-Volunteers</h6>
                  <span class="h5 font-weight-bold mb-0">{{ this.nonVolunteer }}</span>
                </div>
                <div class="col-auto">
                  <div class="icon bg-gradient-info text-white rounded-circle icon-shape">
                    <i class="fas fa-user"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="this.Users?.length > 0">
        <div class="col-lg-3 col-sm-6" *ngFor="let user of Users">
          <div class="card hover-shadow-lg">
            <div class="card-body text-center">
              <div class="avatar-parent-child">
                <img alt="Image placeholder" src="{{this.baseUrl+user.UserImage }}"
                  class="avatar avatar-lg  rounded-circle">
                <span class="avatar-child avatar-badge bg-info"></span>
              </div>
              <br>
              <button type="button" class="mt-4 mb-0 btn btn-xs btn-light rounded-pill mr-auto">Trendsfore {{
                user.UserRole }}</button>
              <h3 class="h5 mt-4 mb-0">{{ user.UserFullName }}</h3>
              <a [routerLink]="" class="d-block text-sm text-muted">{{ user.UserEmail }}</a>
              <a [routerLink]="" class="d-block text-sm text-muted">{{ user.UserPhone }}</a>
            </div>
            <div class="card-body border-top">
              <div class="row justify-content-between align-items-center">
                <div class="col-6 text-center">
                  <span class="d-block h6 mb-0">{{ user.UserStars }}</span>
                  <span class="d-block text-sm text-muted">Stars</span>
                </div>
                <div class="col-6 text-center">
                  <span class="d-block h6 mb-0">{{ user.UserCoins }}</span>
                  <span class="d-block text-sm text-muted">Coins</span>
                </div>
              </div>
              <div class="row justify-content-between align-items-center mt-2">
                <div class="col-6 text-center">
                  <span class="d-block h6 mb-0">{{ user.UserFollowers }}</span>
                  <span class="d-block text-sm text-muted">Followers</span>
                </div>
                <div class="col-6 text-center">
                  <span class="d-block h6 mb-0">{{ user.UserFollowing }}</span>
                  <span class="d-block text-sm text-muted">Following</span>
                </div>
              </div>
            </div>
            <div class="card-footer">
              <div class="actions d-flex justify-content-between px-4">
                  <a [routerLink]="['/admin/users/'+user.UserId]" class="action-item text-primary">
                    <i class="fas fa-user"></i>
                  </a>
                  <a *ngIf="user.UserRole!=='User'" (click)="this.setId(user.UserId,true)" href="#modal-change-username" data-toggle="modal" class="action-item text-primary">
                    <i class="fas fa-star"></i>
                  </a>
                  <a *ngIf="user.UserRole!=='User'" (click)="this.setId(user.UserId,false)" href="#modal-change-username" data-toggle="modal" class="action-item text-primary">
                    <i class="fas fa-donate"></i>
                  </a>
                  <a (click)="this.delete(user.UserId)" class="action-item text-danger">
                    <i class="fas fa-trash-alt"></i>
                  </a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-sm-6">
          <div class="card card-fluid card-placeholder align-items-center justify-content-center">
            <div class="text-center">
              <a [routerLink]="" (click)="this.loadMore()" *ngIf="this.isLoadMoreVisible" class="stretched-link">
                <i class="fas fa-plus fa-2x text-muted"></i>
                <span class="h6 text-muted d-block mt-3">More users</span>
              </a>
              <a [routerLink]="" (click)="this.refresh()" *ngIf="!this.isLoadMoreVisible" class="stretched-link">
                <i class="fas fa-recycle fa-2x text-muted"></i>
                <span class="h6 text-muted d-block mt-3">Refresh</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <app-footer></app-footer>
  </div>
</div>
