import { Component, OnInit, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TrendsforeService } from '../../../services/trendsfore.service';
import { broadcastMessaging } from '../../../models/admin/login';
@Component({
  selector: 'app-aside',
  templateUrl: './aside.component.html',
  styleUrls: ['./aside.component.css'],
})
export class AsideComponent implements OnInit {
  @Input() isLoadEnable: boolean;

  BroadCastModel: broadcastMessaging.Broadcasts;
  broadcastForm: FormGroup;
  isLoginOnSubmitErrorEnabled: boolean;
  errorOnLoginSubmit: string;
  constructor(private trendsfore: TrendsforeService) {}

  ngOnInit(): void {
    if (this.trendsfore.VerifyToken()) {
      this.getBroadcasts();
      this.broadcastForm = new FormGroup({
        Title: new FormControl('', [
          Validators.required,
          Validators.minLength(10),
        ]),
        Message: new FormControl('', [
          Validators.required,
          Validators.minLength(20),
        ]),
      });
    }
  }

  submitBroadcast() {
    if (this.broadcastForm.valid) {
      this.trendsfore
        .broadcasting(this.broadcastForm.value)
        .subscribe((data) => {
          if (data.Flag) {
            this.trendsfore.success(
              'Message has been broadcast',
              'Broadcast Message'
            );
            this.getBroadcasts();
            this.broadcastForm.patchValue({ Title: '' });
            this.broadcastForm.patchValue({ Message: '' });
          } else {
            this.trendsfore.error(data.Message, 'Broadcast Message');
          }
        });
    } else {
      this.isLoginOnSubmitErrorEnabled = true;
      this.errorOnLoginSubmit = 'Something Went Wrong!';
      if (this.broadcastForm.get('Title').errors?.required) {
        this.errorOnLoginSubmit += '<br>-Broadcast Title field is required.';
      } else if (this.broadcastForm.get('Title').errors?.minlength) {
        this.errorOnLoginSubmit +=
          '<br>-Broadcast Title must be at least 10 characters long.';
      } else if (this.broadcastForm.get('Message').errors?.required) {
        this.errorOnLoginSubmit += '<br>-Broadcast Meassage field is required.';
      } else if (this.broadcastForm.get('Message').errors?.minlength) {
        this.errorOnLoginSubmit +=
          '<br>-Broadcast Meassage must be at least 20 characters long.';
      }
      setTimeout(() => {
        this.isLoginOnSubmitErrorEnabled = false;
      }, 5000);
    }
  }

  getBroadcasts() {
    this.isLoadEnable = true;
    this.trendsfore
      .broadcasts()
      .subscribe((data: broadcastMessaging.Broadcasts) => {
        if (data.Flag) {
          this.BroadCastModel = data;
          this.isLoadEnable = false;
        } else {
          this.isLoadEnable = false;
        }
      });
  }
}
