import { Component, OnInit, ViewChild } from '@angular/core';
import { TrendsforeService } from '../../../services/trendsfore.service';
import { dataBank } from '../../../models/admin/login';
import {
  ChartComponent,
  ApexNonAxisChartSeries,
  ApexResponsive,
  ApexChart,
  ApexTheme,
  ApexTitleSubtitle,
  ApexFill,
  ApexStroke,
  ApexYAxis,
  ApexXAxis,
  ApexLegend,
  ApexAxisChartSeries,
  ApexPlotOptions,
} from 'ng-apexcharts';
export type ChartOptions = {
  series: ApexNonAxisChartSeries;
  chart: ApexChart;
  responsive: ApexResponsive[];
  labels: any;
  theme: ApexTheme;
  title: ApexTitleSubtitle;
  fill: ApexFill;
  yaxis: ApexYAxis;
  stroke: ApexStroke;
  legend: ApexLegend;
  plotOptions: ApexPlotOptions;
  xaxis: ApexXAxis;
};
@Component({
  selector: 'app-databank',
  templateUrl: './databank.component.html',
  styleUrls: ['./databank.component.css'],
})
export class DatabankComponent implements OnInit {
  @ViewChild('chart') chart: ChartComponent;
  public CountryChartOptions: Partial<any>;
  DataBankModel: dataBank.DataBank;
  isLoadEnable: boolean;
  date: string;
  searchTxt: string;
  constructor(private trendsfore: TrendsforeService) {
    let dat = new Date();
    var months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];
    this.date = months[dat.getMonth()] + ' ' + dat.getFullYear();
  }
  ngOnInit(): void {
    this.trendsfore.VerifyToken();
  }

  search(value: string) {
    if (value !== '' && value.length > 2) {
      this.isLoadEnable = true;
      this.trendsfore.dataBank(value).subscribe((data: dataBank.DataBank) => {
        if (data.Flag) {
          this.DataBankModel = data;
          this.searchTxt = value;
          this.CountryChartOptions = {
            series: [
              {
                name: 'Questions',
                data: this.DataBankModel.Data.Questions,
              },
              {
                name: 'Answers',
                data: this.DataBankModel.Data.Answers,
              },
            ],
            chart: {
              height: 350,
              type: 'bar',
              color: ['#153a6c', '#ff5630'],
            },
            plotOptions: {
              bar: {
                color: ['#153a6c', '#ff5630'],
              },
            },
            xaxis: {
              categories: this.DataBankModel.Data.Categories,
            },
            color: ['#153a6c', '#ff5630'],
          };
          this.isLoadEnable = false;
        } else {
          this.isLoadEnable = false;
          this.DataBankModel = data;
        }
      });
    } else {
      this.isLoadEnable = false;
      this.DataBankModel = null;
    }
  }
}
