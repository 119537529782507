<!-- Main nav -->
<nav class="navbar navbar-main navbar-expand-lg navbar-dark bg-primary navbar-border" id="navbar-main">
  <div class="container-fluid">
    <!-- Brand + Toggler (for mobile devices) -->
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbar-main-collapse"
      aria-controls="navbar-main-collapse" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <!-- User's navbar -->
    <div class="navbar-user d-lg-none ml-auto">
      <ul class="navbar-nav flex-row align-items-center">
        <!--
        <li class="nav-item dropdown dropdown-animate">
          <a class="nav-link nav-link-icon" href="#" role="button" data-toggle="dropdown" aria-haspopup="true"
            aria-expanded="false"><i class="fas fa-bell"></i></a>
          <div class="dropdown-menu dropdown-menu-right dropdown-menu-lg dropdown-menu-arrow p-0">
            <div class="py-3 px-3">
              <h5 class="heading h6 mb-0">Notifications</h5>
            </div>
            <div class="list-group list-group-flush">
              <a href="#" class="list-group-item list-group-item-action">
                <div class="d-flex align-items-center" data-toggle="tooltip" data-placement="right"
                  data-title="3 hrs ago">
                  <div>
                    <span class="avatar bg-warning text-white rounded-circle">SW</span>
                  </div>
                  <div class="flex-fill ml-3">
                    <div class="h6 text-sm mb-0">Sandra Wayne <small class="float-right text-muted">3 hrs
                        ago</small></div>
                    <p class="text-sm lh-140 mb-0">
                      Some quick example text to build on the card title.
                    </p>
                  </div>
                </div>
              </a>
            </div>
            <div class="py-3 text-center">
              <a href="#" class="link link-sm link--style-3">View all notifications</a>
            </div>
          </div>
        </li>
        -->
        <li class="nav-item dropdown dropdown-animate">
          <a class="nav-link pr-lg-0" href="#" role="button" data-toggle="dropdown" aria-haspopup="true"
            aria-expanded="false">
            <div class="media media-pill align-items-center">
              <span class="avatar rounded-circle">
                <img alt="Image placeholder" src="{{ this.baseUrl+this.DataModel.UserImage }}">
              </span>
              <div class="ml-2 d-none d-lg-block">
                <span class="mb-0 text-sm  font-weight-bold">{{ this.DataModel.UserFullName }}</span>
              </div>
            </div>
          </a>
          <div class="dropdown-menu dropdown-menu-sm dropdown-menu-right dropdown-menu-arrow">
            <h6 class="dropdown-header px-0">Super {{ this.DataModel.Role }}</h6>
            <a [routerLink]="['/admin/profile']" routerLinkActive="router-link-active" class="dropdown-item">
              <i class="fas fa-user"></i>
              <span>My profile</span>
            </a>
            <a [routerLink]="['/admin/setting']" routerLinkActive="router-link-active" class="dropdown-item">
              <i class="fas fa-cog"></i>
              <span>Settings</span>
            </a>
            <div class="dropdown-divider"></div>
            <a [routerLink]="" (click)="logout()" routerLinkActive="router-link-active" class="dropdown-item">
              <i class="fas fa-sign-out-alt"></i>
              <span>Logout</span>
            </a>
          </div>
        </li>
      </ul>
    </div>
    <!-- Navbar nav -->
    <div class="collapse navbar-collapse navbar-collapse-fade" id="navbar-main-collapse">
      <ul class="navbar-nav align-items-lg-center">
        <!-- Application menu -->
        <li class="nav-item dropdown dropdown-animate" data-toggle="hover">
          <a class="nav-link dropdown-toggle" href="#" role="button" data-toggle="dropdown" aria-haspopup="true"
            aria-expanded="false">
            Admin Controls
          </a>

          <div class="dropdown-menu dropdown-menu-arrow p-lg-0">

            <!-- Bottom dropdown menu -->
            <div class="dropdown-menu-links rounded-bottom delimiter-top p-lg-4">
              <div class="row">
                <div class="col-sm-12">
                  <a [routerLink]="['/admin/dashboard']" routerLinkActive="text-light bg-primary font-weight-bolder"
                    class="dropdown-item p-2">Home Analytics</a>
                  <a [routerLink]="['/admin/users']" routerLinkActive="text-light bg-primary font-weight-bolder"
                    class="dropdown-item  p-2">Trendsfore Users</a>
                  <a [routerLink]="['/admin/volunteers']" routerLinkActive="text-light bg-primary font-weight-bolder"
                    class="dropdown-item  p-2">Volunteer Levels</a>
                  <a [routerLink]="['/admin/opportunities']" routerLinkActive="text-light bg-primary font-weight-bolder"
                    class="dropdown-item  p-2">Opportunity Management</a>
                  <a [routerLink]="['/admin/complaints']" routerLinkActive="text-light bg-primary font-weight-bolder"
                    class="dropdown-item  p-2">Reports & Complaints</a>
                  <!--
                    <a [routerLink]="['/admin/trendsfore']" routerLinkActive="text-light bg-primary font-weight-bolder"
                    class="dropdown-item  p-2">Trendsfore Official</a>
                  -->
                  <a [routerLink]="['/admin/databank']" routerLinkActive="text-light bg-primary font-weight-bolder"
                    class="dropdown-item  p-2">Trendsfore Databank</a>
                  <a [routerLink]="['/admin/pages']" routerLinkActive="text-light bg-primary font-weight-bolder"
                    class="dropdown-item  p-2">Trendsfore Pages</a>
                  <a [routerLink]="['/admin/communities']" routerLinkActive="text-light bg-primary font-weight-bolder"
                    class="dropdown-item  p-2">Trendsfore Pages</a>
                  <a [routerLink]="['/admin/pages']" routerLinkActive="text-light bg-primary font-weight-bolder"
                    class="dropdown-item  p-2">Trendsfore Communities</a>
                  <a [routerLink]="['/admin/initiatives']" routerLinkActive="text-light bg-primary font-weight-bolder"
                    class="dropdown-item  p-2">Trendsfore Initiatives</a>
                  <a [routerLink]="['/admin/keyword']" routerLinkActive="text-light bg-primary font-weight-bolder"
                    class="dropdown-item  p-2">Keyword Management</a>
                  <!--
                    <a [routerLink]="" routerLinkActive="text-light bg-primary font-weight-bolder"
                    class="dropdown-item p-2">Category Management</a>
                  -->
                  <a [routerLink]="['/admin/questions']" routerLinkActive="text-light bg-primary font-weight-bolder"
                    class="dropdown-item p-2">Trendsfore Questions</a>
                  <a [routerLink]="['/admin/videos']" routerLinkActive="text-light bg-primary font-weight-bolder"
                    class="dropdown-item p-2">Live Video Management</a>
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>

      <!-- Right menu -->
      <ul class="navbar-nav ml-lg-auto align-items-center d-none d-lg-flex">
        <li class="nav-item">
          <a href="#" class="nav-link nav-link-icon sidenav-toggler" data-action="sidenav-pin"
            data-target="#sidenav-main"><i class="fas fa-bars"></i></a>
        </li>
        <!--
        <li class="nav-item dropdown dropdown-animate">
          <a class="nav-link nav-link-icon" href="#" role="button" data-toggle="dropdown" aria-haspopup="true"
            aria-expanded="false"><i class="fas fa-bell"></i></a>
          <div class="dropdown-menu dropdown-menu-right dropdown-menu-lg dropdown-menu-arrow p-0">
            <div class="py-3 px-3">
              <h5 class="heading h6 mb-0">Notifications</h5>
            </div>
            <div class="list-group list-group-flush">
              <a href="#" class="list-group-item list-group-item-action">
                <div class="d-flex align-items-center" data-toggle="tooltip" data-placement="right"
                  data-title="2 hrs ago">
                  <div>
                    <span class="avatar bg-primary text-white rounded-circle">AM</span>
                  </div>
                  <div class="flex-fill ml-3">
                    <div class="h6 text-sm mb-0">Alex Michael <small class="float-right text-muted">2 hrs
                        ago</small></div>
                    <p class="text-sm lh-140 mb-0">
                      Some quick example text to build on the card title.
                    </p>
                  </div>
                </div>
              </a>
              <a href="#" class="list-group-item list-group-item-action">
                <div class="d-flex align-items-center" data-toggle="tooltip" data-placement="right"
                  data-title="3 hrs ago">
                  <div>
                    <span class="avatar bg-warning text-white rounded-circle">SW</span>
                  </div>
                  <div class="flex-fill ml-3">
                    <div class="h6 text-sm mb-0">Sandra Wayne <small class="float-right text-muted">3 hrs
                        ago</small></div>
                    <p class="text-sm lh-140 mb-0">
                      Some quick example text to build on the card title.
                    </p>
                  </div>
                </div>
              </a>
              <a href="#" class="list-group-item list-group-item-action">
                <div class="d-flex align-items-center" data-toggle="tooltip" data-placement="right"
                  data-title="5 hrs ago">
                  <div>
                    <span class="avatar bg-info text-white rounded-circle">JM</span>
                  </div>
                  <div class="flex-fill ml-3">
                    <div class="h6 text-sm mb-0">Jason Miller <small class="float-right text-muted">5 hrs
                        ago</small></div>
                    <p class="text-sm lh-140 mb-0">
                      Some quick example text to build on the card title.
                    </p>
                  </div>
                </div>
              </a>
              <a href="#" class="list-group-item list-group-item-action">
                <div class="d-flex align-items-center" data-toggle="tooltip" data-placement="right"
                  data-title="2 hrs ago">
                  <div>
                    <span class="avatar bg-dark text-white rounded-circle">MJ</span>
                  </div>
                  <div class="flex-fill ml-3">
                    <div class="h6 text-sm mb-0">Mike Thomson <small class="float-right text-muted">2 hrs
                        ago</small></div>
                    <p class="text-sm lh-140 mb-0">
                      Some quick example text to build on the card title.
                    </p>
                  </div>
                </div>
              </a>
              <a href="#" class="list-group-item list-group-item-action">
                <div class="d-flex align-items-center" data-toggle="tooltip" data-placement="right"
                  data-title="3 hrs ago">
                  <div>
                    <span class="avatar bg-primary text-white rounded-circle">RN</span>
                  </div>
                  <div class="flex-fill ml-3">
                    <div class="h6 text-sm mb-0">Richard Nixon <small class="float-right text-muted">3 hrs
                        ago</small></div>
                    <p class="text-sm lh-140 mb-0">
                      Some quick example text to build on the card title.
                    </p>
                  </div>
                </div>
              </a>
            </div>
            <div class="py-3 text-center">
              <a href="#" class="link link-sm link--style-3">View all notifications</a>
            </div>
          </div>
        </li>
        -->
        <li class="nav-item dropdown dropdown-animate">
          <a class="nav-link pr-lg-0" href="#" role="button" data-toggle="dropdown" aria-haspopup="true"
            aria-expanded="false">
            <div class="media media-pill align-items-center">
              <span class="avatar rounded-circle">
                <img alt="Image placeholder" src="{{ this.baseUrl+this.DataModel.UserImage }}">
              </span>
              <div class="ml-2 d-none d-lg-block">
                <span class="mb-0 text-sm  font-weight-bold">{{ this.DataModel.UserFullName }}</span>
              </div>
            </div>
          </a>
          <div class="dropdown-menu dropdown-menu-sm dropdown-menu-right dropdown-menu-arrow">
            <h6 class="dropdown-header px-0">Super {{ this.DataModel.Role }}</h6>
            <a [routerLink]="['/admin/profile']" routerLinkActive="router-link-active" class="dropdown-item">
              <i class="fas fa-user"></i>
              <span>My profile</span>
            </a>
            <a [routerLink]="['/admin/setting']" routerLinkActive="router-link-active" class="dropdown-item">
              <i class="fas fa-cog"></i>
              <span>Settings</span>
            </a>
            <div class="dropdown-divider"></div>
            <a [routerLink]="" (click)="logout()" routerLinkActive="router-link-active" class="dropdown-item">
              <i class="fas fa-sign-out-alt"></i>
              <span>Logout</span>
            </a>
          </div>
        </li>
      </ul>
    </div>
  </div>
</nav>
