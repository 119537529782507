import { Component, OnInit } from '@angular/core';
import { TrendsforeService } from '../../../../services/trendsfore.service';
import {
  FormControl,
  FormGroup,
  Validators,
  FormBuilder,
} from '@angular/forms';
import {
  opportunityManagement,
  generalUtilities,
  volunteerManagement,
} from '../../../../models/admin/login';
@Component({
  selector: 'app-opportunities',
  templateUrl: './opportunities.component.html',
  styleUrls: ['./opportunities.component.css'],
})
export class OpportunitiesComponent implements OnInit {
  OpportunityModel: opportunityManagement.Opportunity[];
  ApplicantModel: opportunityManagement.Application;
  ApplicationsModel: opportunityManagement.Application[];
  UtilModel: generalUtilities.Utils;
  OptionLevel: volunteerManagement.Option[];
  Categories: opportunityManagement.Opportunity[];
  OpportunityForm: FormGroup;
  OpportunityImage: string;

  isModalCreate: boolean;
  errorOnLoginSubmit: String;
  isLoginOnSubmitErrorEnabled: boolean;
  isLoadEnable: boolean;
  isLoadMoreVisible: boolean;
  baseUrl: String;
  pageNo: number;

  constructor(
    private trendsfore: TrendsforeService,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit(): void {
    if (this.trendsfore.VerifyToken()) {
      this.UtilModel = new generalUtilities.Utils();
      this.baseUrl = this.trendsfore.imageUrl();
      this.isLoadMoreVisible = true;
      this.isLoadEnable = true;
      this.pageNo = 1;
      this.getOpportunities(this.pageNo.toString());
      this.getVolunteerLevels();
      this.getCategories();
      this.OpportunityForm = this.formBuilder.group({
        OpportunityId: new FormControl(''),
        OpportunityTitle: new FormControl(''),
        LevelId: new FormControl(''),
        OpportunityCompanyName: new FormControl(''),
        Industry: new FormControl(''),
        Location: new FormControl(''),
        Description: new FormControl(''),
        Image: new FormControl(''),
      });
    }
  }
  applicationUser(applicant: opportunityManagement.Application) {
    this.ApplicantModel = applicant;
  }

  setApplications(model: opportunityManagement.Application[]){
    this.ApplicationsModel=model;
  }

  deleteApplicant(Id: number) {
    if (confirm('Are you sure, you want to delete this applicant?')) {
      this.trendsfore.deleteApplicant(Id).subscribe((data) => {
        if (data.Flag) {
          this.trendsfore.success(
            'Applicant has been deleted',
            'Opportunity Management'
          );
          this.refresh();
        } else {
          this.trendsfore.error(data.Message, 'Opportunity Management');
        }
      });
    }
  }

  create() {
    if (this.OpportunityForm.valid) {
      const formData = new FormData();
      formData.append(
        'Title',
        this.OpportunityForm.get('OpportunityTitle').value
      );
      formData.append('LevelId', this.OpportunityForm.get('LevelId').value);
      formData.append(
        'CompanyName',
        this.OpportunityForm.get('OpportunityCompanyName').value
      );
      formData.append('Industry', this.OpportunityForm.get('Industry').value);
      formData.append('Location', this.OpportunityForm.get('Location').value);
      formData.append(
        'Description',
        this.OpportunityForm.get('Description').value
      );
      formData.append('Image', this.OpportunityForm.get('Image').value);
      this.trendsfore
        .addOpportunity(formData)
        .subscribe((data: opportunityManagement.AddOpportunity) => {
          if (data.Flag) {
            this.trendsfore.success(
              'New opportunity has been added',
              'Opportunity Management'
            );
            this.OpportunityForm.reset();
            this.refresh();
          } else {
            this.formValidation(data);
            //console.log(data.Message.split('.'));
            //this.trendsfore.error(data.Message, 'Opportunity Management');
          }
        });
    } else {
      console.log('Something Went wrong');
    }
  }

  update() {
    if (this.OpportunityForm.valid) {
      const formData = new FormData();
      formData.append(
        'OpportunityId',
        this.OpportunityForm.get('OpportunityId').value
      );
      formData.append(
        'Title',
        this.OpportunityForm.get('OpportunityTitle').value
      );
      formData.append('LevelId', this.OpportunityForm.get('LevelId').value);
      formData.append(
        'CompanyName',
        this.OpportunityForm.get('OpportunityCompanyName').value
      );
      formData.append('Industry', this.OpportunityForm.get('Industry').value);
      formData.append('Location', this.OpportunityForm.get('Location').value);
      formData.append(
        'Description',
        this.OpportunityForm.get('Description').value
      );
      formData.append('Image', this.OpportunityForm.get('Image').value);
      this.trendsfore
        .updateOpportunity(formData)
        .subscribe((data: opportunityManagement.UpdateOpportunity) => {
          if (data.Flag) {
            this.trendsfore.success(
              'Opportunity has been updated',
              'Opportunity Management'
            );
            this.refresh();
          } else {
            if (data.Message === 'No changes applied') {
              this.trendsfore.success(data.Message, 'Opportunity Management');
            } else {
              if (data.Errors.OpportunityId?.length > 0) {
                this.enableError(data.Errors.OpportunityId[0]);
              }
              this.formValidation(data);
            }
          }
        });
    } else {
      console.log('Something Went wrong');
    }
  }

  save() {
    this.isModalCreate = true;
    this.OpportunityForm.patchValue({ LevelId: '' });
    this.OpportunityForm.patchValue({ Industry: '' });
    this.OpportunityForm.patchValue({ Location: '' });
    this.OpportunityForm.patchValue({ OpportunityTitle: '' });
    this.OpportunityForm.patchValue({ OpportunityCompanyName: '' });
    this.OpportunityForm.patchValue({ Description: '' });
  }
  uploadFile(event) {
    const file = (event.target as HTMLInputElement).files[0];
    this.OpportunityForm.patchValue({ Image: file });
  }
  edit(model: opportunityManagement.Opportunity) {
    this.isModalCreate = false;
    this.OpportunityForm.patchValue({ OpportunityId: model.OpportunityId });
    this.OpportunityForm.patchValue({ OpportunityTitle: model.Title });
    this.OpportunityForm.patchValue({ LevelId: model.LevelId });
    this.OpportunityForm.patchValue({
      OpportunityCompanyName: model.CompanyName,
    });
    this.OpportunityForm.patchValue({ Industry: model.Industry });
    this.OpportunityForm.patchValue({ Location: model.Location });
    this.OpportunityForm.patchValue({ Description: model.Description });
    this.OpportunityImage = this.baseUrl + model.Image;
  }

  delete(Id: number) {
    if (confirm('Are you sure, you want to delete this opportunity?')) {
      this.trendsfore.deleteOpportunity(Id).subscribe((data) => {
        if (data.Flag) {
          this.trendsfore.success(
            'Opportunity has been deleted',
            'Opportunity Management'
          );
          this.refresh();
        } else {
          this.trendsfore.error(data.Message, 'Opportunity Management');
        }
      });
    }
  }

  search(value: string) {
    if (value !== '') {
      this.trendsfore
        .searchedopportunities(value)
        .subscribe((data: opportunityManagement.Opportunities) => {
          if (data.Flag) {
            this.OpportunityModel = data.Data;
          }
        });
    } else {
      this.refresh();
    }
  }

  loadMore() {
    this.pageNo += 1;
    this.isLoadEnable = true;
    this.getOpportunities(this.pageNo.toString());
  }

  refresh() {
    this.pageNo = 1;
    this.isLoadMoreVisible = true;
    this.isLoadEnable = true;
    this.getOpportunities(this.pageNo.toString());
  }

  private formValidation(data?: any) {
    if (data.Errors?.LevelId?.length > 0) {
      this.enableError(data.Errors.LevelId[0]);
    }
    if (data.Errors?.Title?.length > 0) {
      this.enableError(data.Errors.Title[0]);
    }
    if (data.Errors?.CompanyName?.length > 0) {
      this.enableError(data.Errors.CompanyName[0]);
    }
    if (data.Errors?.Industry?.length > 0) {
      this.enableError(data.Errors.Industry[0]);
    }
    if (data.Errors?.Location?.length > 0) {
      this.enableError(data.Errors.Location[0]);
    }
    if (data.Errors?.Description?.length > 0) {
      this.enableError(data.Errors.Description[0]);
    }
    if (data.Errors?.Image?.length > 0) {
      this.enableError(data.Errors.Image[0]);
    }
  }

  private getOpportunities(page: string) {
    this.trendsfore
      .opportunities(page)
      .subscribe((data: opportunityManagement.Opportunities) => {
        if (data.Flag) {
          if (page == '1') {
            this.OpportunityModel = data.Data;
            this.isLoadEnable = false;
          } else {
            this.OpportunityModel = this.OpportunityModel.concat(data.Data);
            this.isLoadEnable = false;
          }
        } else {
          this.isLoadEnable = false;
          this.isLoadMoreVisible = false;
        }
      });
  }

  private getVolunteerLevels() {
    this.trendsfore
      .userVolunteerOptionLevels()
      .subscribe((data: volunteerManagement.VolunteerOptions) => {
        if (data.Flag) {
          this.OptionLevel = data.Data;
        }
      });
  }

  private getCategories() {
    this.trendsfore
      .categories()
      .subscribe((data: opportunityManagement.Opportunities) => {
        if (data.Flag) {
          this.Categories = data.Data;
        }
      });
  }

  private enableError(error: string): void {
    if (this.errorOnLoginSubmit === undefined) {
      this.errorOnLoginSubmit = error;
    } else {
      this.errorOnLoginSubmit += '<br>' + error;
    }
    this.isLoginOnSubmitErrorEnabled = true;
    setTimeout(() => {
      this.isLoginOnSubmitErrorEnabled = false;
      this.errorOnLoginSubmit = undefined;
    }, 10000);
  }
}
