import { QuestionsComponent } from './admin/dashboard/trendsfore-questions/questions/questions.component';
import { DatabankComponent } from './admin/dashboard/databank/databank.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './admin/dashboard/home/home.component';
import { LoginComponent } from './admin/auth/login/login.component';
import { RecoverComponent } from './admin/auth/recover/recover.component';
import { ProfileComponent } from './admin/dashboard/account/profile/profile.component';
import { SettingComponent } from './admin/dashboard/account/setting/setting.component';
import { KeywordComponent } from './admin/dashboard/keyword/keyword/keyword.component';
import { UsersComponent } from './admin/dashboard/user/users/users.component';
import { UserProfileComponent } from './admin/dashboard/user/user-profile/user-profile.component';
import { VolunteersComponent } from './admin/dashboard/volunteer/volunteers/volunteers.component';
import { OpportunitiesComponent } from './admin/dashboard/opportunity/opportunities/opportunities.component';
import { ComplaintsComponent } from './admin/dashboard/feedback/complaints/complaints.component';
import { PagesComponent } from './admin/dashboard/page/pages/pages.component';
import { CommunitiesComponent } from './admin/dashboard/community/communities/communities.component';
import { InitiativesComponent } from './admin/dashboard/initiative/initiatives/initiatives.component';
import { TrendsforeComponent } from './admin/dashboard/page/trendsfore/trendsfore.component';
import { VideosComponent } from './admin/dashboard/live/videos/videos.component';
const routes: Routes = [
  {
    path: '',
    component: LoginComponent,
  },
  {
    path: 'admin',
    component: LoginComponent,
  },
  {
    path: 'admin/dashboard',
    component: HomeComponent,
  },
  {
    path: 'recover',
    component: RecoverComponent,
  },
  {
    path: 'admin/profile',
    component: ProfileComponent,
  },
  {
    path: 'admin/setting',
    component: SettingComponent,
  },
  {
    path: 'admin/keyword',
    component: KeywordComponent,
  },
  {
    path: 'admin/users',
    component: UsersComponent,
  },
  {
    path: 'admin/users/:id',
    component: UserProfileComponent,
  },
  {
    path: 'admin/volunteers',
    component: VolunteersComponent,
  },

  {
    path: 'admin/opportunities',
    component: OpportunitiesComponent,
  },
  {
    path: 'admin/complaints',
    component: ComplaintsComponent,
  },

  {
    path: 'admin/pages',
    component: PagesComponent,
  },
  {
    path: 'admin/communities',
    component: CommunitiesComponent,
  },
  {
    path: 'admin/initiatives',
    component: InitiativesComponent,
  },
  {
    path: 'admin/trendsfore',
    component: TrendsforeComponent,
  },
  {
    path: 'admin/databank',
    component: DatabankComponent,
  },
  {
    path: 'admin/questions',
    component: QuestionsComponent,
  },

  {
    path: 'admin/videos',
    component: VideosComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
