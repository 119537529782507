<app-aside [isLoadEnable]="this.isLoadEnable"></app-aside>
<div class="container-fluid container-application">
  <app-sidebar></app-sidebar>
  <!-- Content -->
  <div class="main-content position-relative">
    <app-header></app-header>
    <!-- Modal -->
      <div class="modal fade" id="modal-change-username" tabindex="-1" role="dialog"
        aria-labelledby="modal-change-username" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <div class="modal-title d-flex align-items-center" id="modal-title-change-username">
                  <div>
                    <div class="icon icon-sm icon-shape icon-info rounded-circle shadow mr-3">
                      <i class="fas fa-star" *ngIf="this.IsStars"></i>
                      <i class="fas fa-donate" *ngIf="!this.IsStars"></i>
                    </div>
                  </div>
                  <div>
                    <h6 class="mb-0" *ngIf="this.IsStars">Add Stars</h6>
                    <h6 class="mb-0" *ngIf="!this.IsStars">Add Coins</h6>
                  </div>
                </div>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <div class="row">
                  <div class="col-12">
                    <div class="form-group">
                      <label class="form-control-label" *ngIf="this.IsStars">Stars</label>
                      <label class="form-control-label" *ngIf="!this.IsStars">Coins</label>
                      <input class="form-control" type="hidden" [(ngModel)]="NumId">
                      <input class="form-control" type="number" placeholder="Enter numbers" [(ngModel)]="NumStars">
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button *ngIf="!this.IsStars" type="button" (click)="addCoins()" class="btn btn-sm btn-secondary rounded-pill" data-dismiss="modal">Add</button>
                <button *ngIf="this.IsStars" type="button" (click)="addStars()" class="btn btn-sm btn-secondary rounded-pill" data-dismiss="modal">Add</button>
              </div>
            </div>
        </div>
      </div>

    <!-- Page content -->
    <div class="page-content">
      <div class="card" *ngIf="this.CommunityModel?.length <= 0">
        <img class="img-center img" src="../../../assets/img/errors/nodata-found.png" alt="NO DATA">
      </div>

      <!-- Page title -->
      <div class="page-title" *ngIf="this.CommunityModel?.length > 0">
        <div class="row justify-content-between align-items-center">
          <div class="col-md-6 d-flex align-items-center justify-content-between justify-content-md-start mb-3 mb-md-0">
            <!-- Page title + Go Back button -->
            <div class="d-inline-block">
              <h5 class="h4 d-inline-block font-weight-400 mb-0 text-white">Trendsfore Communities</h5>
            </div>
            <!-- Additional info -->
            <div class="align-items-center ml-4 d-inline-flex">
              <span class="h4 text-info mb-0 mr-2">{{ this.CommunityModel?.length }}</span>
              <span class="text-sm opacity-7 text-white">Total Communities</span>
            </div>
          </div>
          <div class="col-md-6 d-flex align-items-center justify-content-between justify-content-md-end">
            <!-- Filter input -->
            <form class="bg-neutral rounded-pill d-inline-block">
              <div class="input-group input-group-sm input-group-merge input-group-flush">
                <div class="input-group-prepend">
                  <span class="input-group-text bg-transparent"><i class="fas fa-filter"></i></span>
                </div>
                <input type="text" (keyup)="search($event.target.value)" class="form-control form-control-flush"
                  placeholder="Filter results">
              </div>
            </form>
            <a [routerLink]="" (click)="this.refresh()" class="btn btn-sm btn-white btn-icon-only rounded-circle ml-4">
              <span class="btn-inner--icon"><i class="fas fa-sync"></i></span>
            </a>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="this.CommunityModel?.length > 0">
        <div class="col-lg-3 col-sm-6" *ngFor="let community of CommunityModel">
          <div class="card hover-shadow-lg">
            <div class="card-body text-center">
              <div class="avatar-parent-child">
                <img alt="Image placeholder" src="{{ this.baseUrl + community.CommunityImage }}"
                  class="avatar  rounded-circle avatar-lg">
                <span class="avatar-child avatar-badge bg-primary"></span>
              </div>

              <div class="text-muted text-sm mt-4  mb-1">
                Created By <span class="text-dark">{{ community.UserFullName }}</span>
              </div>
              <h5 class="h6 mb-1">{{ community.CommunityName }}</h5>
              <h5 class="h6 text-muted">{{ community.CommunityLocation }}</h5>
              <a class="btn btn-primary text-sm text-light  btn-sm" *ngIf="community.IsCommunityUpgrade">Premium
                Community</a>
              <a [routerLink]="" (click)="this.upgrade(community.CommunityId)"
                class="btn btn-light text-sm text-dark  btn-sm" *ngIf="!community.IsCommunityUpgrade">Upgrade to
                premium</a>
            </div>
            <div class="card-body border-top">
              <div class="row justify-content-between align-items-center">
                <div class="col-6 text-center">
                  <span class="d-block h4 mb-0">{{ community.Stars }}</span>
                  <span class="d-block text-sm text-muted">Stars</span>
                </div>
                <div class="col-6 text-center">
                  <span class="d-block h4 mb-0">{{ community.Coins }}</span>
                  <span class="d-block text-sm text-muted">Coins</span>
                </div>
              </div>
            </div>
            <div class="card-footer">
              <div class="actions d-flex justify-content-between px-4">
                <a [routerLink]="" class="action-item text-primary">
                  <i class="fas fa-eye"></i>
                </a>
                <a (click)="this.setId(community.CommunityId,true)" href="#modal-change-username" data-toggle="modal" class="action-item text-primary">
                  <i class="fas fa-star"></i>
                </a>
                <a *ngIf="community.IsCommunityUpgrade" (click)="this.setId(community.CommunityId,false)" href="#modal-change-username" data-toggle="modal" class="action-item text-primary">
                  <i class="fas fa-donate"></i>
                </a>
                <a (click)="this.delete(community.CommunityId)" class="action-item text-danger">
                  <i class="fas fa-trash-alt"></i>
                </a>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-3 col-sm-6">
          <div class="card card-fluid card-placeholder align-items-center justify-content-center">
            <div class="text-center">
              <a [routerLink]="" (click)="this.loadMore()" *ngIf="this.isLoadMoreVisible" class="stretched-link">
                <i class="fas fa-plus fa-2x text-muted"></i>
                <span class="h6 text-muted d-block mt-3">More Communities</span>
              </a>

              <a [routerLink]="" (click)="this.refresh()" *ngIf="!this.isLoadMoreVisible" class="stretched-link">
                <i class="fas fa-sync fa-2x text-muted"></i>
                <span class="h6 text-muted d-block mt-3">Refresh</span>
              </a>
            </div>
          </div>
        </div>

      </div>

    </div>
    <app-footer></app-footer>
  </div>
</div>
