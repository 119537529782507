<!-- Background animtion-->
<div class="cube">T</div>
<div class="cube">T</div>
<div class="cube">T</div>
<div class="cube">T</div>
<div class="cube">T</div>
<div class="cube">T</div>
<div class="cube">T</div>
<div class="cube">T</div>
<div class="cube">T</div>
<!-- Background animtion-->
<!-- Chat modal -->
<!-- Application container -->
<div class="container-fluid container-application">
  <!-- Sidenav -->
  <!-- Content -->
  <div class="main-content position-relative">
    <!-- Page content -->
    <div class="page-content">
      <div class="min-vh-100 py-5 d-flex align-items-center">
        <div class="w-100">
          <div class="row justify-content-center">
            <div class="col-sm-8 col-lg-4">
              <div class="card shadow-lg zindex-100 mb-0 hover-translate-y-n3">
                <div class="card-body px-md-5 py-5">
                  <div class="text-center mb-5 animate-this">
                    <img src="assets/img/brand/logo.png" class="avatar avatar-xxl hover-translate-y-n3"
                      alt="Trendsfore">
                  </div>
                  <span class="clearfix"></span>
                  <form role="form" name="Login">
                    <div class="form-group">
                      <label class="form-control-label">Email address </label>
                      <div class="input-group input-group-merge">
                        <div class="input-group-prepend">
                          <span class="input-group-text"><i class="fas fa-user"></i></span>
                        </div>
                        <input type="email" class="form-control" id="input-email" placeholder="name@example.com"
                          formControlName="AdminEmail">
                      </div>
                    </div>

                    <div class="mt-4">
                      <button type="submit" class="btn btn-sm btn-primary btn-icon rounded-pill">
                        <span class="btn-inner--text">Reset password </span>
                        <span class="btn-inner--icon"><i class="fas fa-long-arrow-alt-right"></i></span>
                      </button>
                    </div>
                  </form>
                </div>
                <div class="card-footer px-md-5"><small>Remember passsword ? </small>
                  <a [routerLink]="['/admin']" routerLinkActive="router-link-active"
                    class="small font-weight-bold">Login</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Footer -->
  </div>
</div>
