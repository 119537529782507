<!-- Background animtion-->
<div class="cube">T</div>
<div class="cube">T</div>
<div class="cube">T</div>
<div class="cube">T</div>
<div class="cube">T</div>
<div class="cube">T</div>
<div class="cube">T</div>
<div class="cube">T</div>
<div class="cube">T</div>
<!-- Background animtion-->
<!-- Chat modal -->
<!-- Application container -->
<div class="container-fluid container-application">
  <!-- Sidenav -->
  <!-- Content -->
  <div class="main-content position-relative">
    <!-- Page content -->
    <div class="page-content">
      <div class="min-vh-100 py-5 d-flex align-items-center">
        <div class="w-100">
          <div class="row justify-content-center">
            <div class="col-sm-8 col-lg-4">
              <div class="card shadow-lg zindex-100 mb-0 hover-translate-y-n3">
                <div class="card-body px-md-5 py-5">
                  <div class="text-center mb-5 animate-this">
                    <img src="assets/img/brand/logo.png" class="avatar avatar-xxl hover-translate-y-n3"
                      alt="Trendsfore">
                  </div>
                  <span class="clearfix"></span>
                  <form role="form" name="Login" [formGroup]="loginForm" (ngSubmit)="submitLoginForm()">
                    <!--REACTIVE-FORM-VALIDATIONS-->

                    <div *ngIf="this.isLoginOnSubmitErrorEnabled" class="alert alert-danger">
                      {{ this.errorOnLoginSubmit }}
                    </div>

                    <div
                      *ngIf="loginForm.get('AdminEmail').invalid && (loginForm.get('AdminEmail').dirty || loginForm.get('AdminEmail').touched)"
                      class="alert alert-danger">
                      <div *ngIf="loginForm.get('AdminEmail').errors.required">
                        Email address is required.
                      </div>
                      <div *ngIf="loginForm.get('AdminEmail').errors.email">
                        Email must be valid email address.
                      </div>
                    </div>
                    <div
                      *ngIf="loginForm.get('AdminEmail').valid && loginForm.get('AdminPassword').invalid && (loginForm.get('AdminPassword').dirty || loginForm.get('AdminPassword').touched)"
                      class="alert alert-danger">
                      <div *ngIf="loginForm.get('AdminPassword').errors.required">
                        Password is required.
                      </div>
                      <div *ngIf="loginForm.get('AdminPassword').errors.maxLength">
                        Password cannot be more than 30 characters long.
                      </div>
                      <div *ngIf="loginForm.get('AdminPassword').errors.minlength">
                        Password must be at least 8 characters long.
                      </div>
                    </div>
                    <!--REACTIVE-FORM-VALIDATIONS-->

                    <div class="form-group" [ngClass]="{ 'focused' : isEmailFocussed }">
                      <label class="form-control-label">Email address </label>
                      <div class="input-group input-group-merge">
                        <div class="input-group-prepend">
                          <span class="input-group-text"><i class="fas fa-user"></i></span>
                        </div>
                        <input (focus)='this.setFocussed(1)' (blur)='this.setblur()' type="email" class="form-control" id="input-email" placeholder="name@example.com"
                          formControlName="AdminEmail">
                      </div>
                    </div>
                    <div class="form-group mb-4" [ngClass]="{ 'focused' : isPasswordFocussed }">
                      <div class="d-flex align-items-center justify-content-between">
                        <div>
                          <label class="form-control-label">Password</label>
                        </div>
                      </div>
                      <div class="input-group input-group-merge">
                        <div class="input-group-prepend">
                          <span class="input-group-text"><i class="fas fa-key"></i></span>
                        </div>
                        <input (focus)='this.setFocussed(0)' (blur)='this.setblur()' type="{{ this.PasswordText }}" class="form-control" id="input-password" placeholder="Password"
                          formControlName="AdminPassword">
                        <div class="input-group-append">
                          <span class="input-group-text">
                            <a [routerLink]="" *ngIf="this.PasswordText==='Password'" (click)='this.setPasswordField(1)'>
                              <i class="fas fa-eye"></i>
                            </a>
                            <a [routerLink]="" *ngIf="this.PasswordText==='Text'" (click)='this.setPasswordField(2)'>
                              <i class="fas fa-eye-slash"></i>
                            </a>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="mt-4"><button type="submit" class="btn btn-sm btn-primary btn-icon rounded-pill">
                        <span class="btn-inner--text">Sign in </span>
                        <span class="btn-inner--icon"><i class="fas fa-long-arrow-alt-right"></i></span>
                      </button>
                    </div>
                  </form>
                </div>
                <div class="card-footer px-md-5"><small>Have you checked our website? </small>
                  <a [routerLink]="" routerLinkActive="router-link-active"
                    class="small font-weight-bold">trendsfore</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Footer -->
  </div>
</div>
