<!--LOADER-->
<div class="loader-container" *ngIf="this.isLoadEnable">
  <div class="main-loader"></div>
</div>
<!--LOADER-->
<!-- Chat modal -->
<!-- Customizer modal -->
<div class="modal fade fixed-right" id="modal-chat" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-vertical" role="document">
    <div class="modal-content">
      <div class="modal-header align-items-center">
        <div class="modal-title">
          <h6 class="mb-0">Broadcast Messaging</h6>
          <span class="d-block text-sm">{{ this.BroadCastModel?.Data.length }} Total Broadcasts</span>
        </div>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="scrollbar-inner">
        <div class="text-left" *ngIf="!this.BroadCastModel?.Flag">
          <img width="100%" src="../../../assets/img/errors/nodata-found.png" alt="NO DATA">
        </div>
        <!-- Chat contacts -->
        <div class="list-group list-group-flush" *ngIf="this.BroadCastModel?.Flag">
          <a [routerLink]="" *ngFor="let broadcast of this.BroadCastModel?.Data"
            class="list-group-item list-group-item-action">
            <div class="d-flex align-items-center" data-toggle="tooltip" data-placement="right" data-title="">
              <div>
                <div class="avatar-parent-child">
                  <span class="fas fa-broadcast-tower"></span>
                </div>
              </div>
              <div class="flex-fill ml-3">
                <h6 class="text-sm mb-0">{{ broadcast.Title}}</h6>
                <p class="text-sm mb-0">
                  {{ broadcast.Message}}
                </p>
                <p class="text-sm mb-0">
                  {{ broadcast.Date}}
                </p>
              </div>
            </div>
          </a>
        </div>
      </div>
      <div class="modal-footer py-3 mt-auto">
        <a href="#modal-chat-broadcast" data-toggle="modal"
          class="btn btn-block btn-sm btn-neutral btn-icon rounded-pill">
          <span class="btn-inner--icon"><i class="fas fa-broadcast-tower"></i></span>
          <span class="btn-inner--text">New Broadcast</span>
        </a>
      </div>
    </div>
  </div>
</div>

<!-- APPLICANT MODAL -->
<div class="modal fade" id="modal-chat-broadcast" tabindex="-2" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-body">
        <div class="modal-header mb-5">
          <h6>New Broadcast</h6>
        </div>
        <div class="card card-fluid">
          <div class="card-body">
            <form [formGroup]="broadcastForm">
              <div *ngIf="this.isLoginOnSubmitErrorEnabled" class="alert alert-danger"
                [innerHTML]="this.errorOnLoginSubmit">
              </div>
              <!-- BROADCAST FIELDS -->
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label class="form-control-label">Broadcast Title*</label>
                    <input formControlName="Title" class="form-control" type="text" placeholder="Enter broadcast title">
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    <label class="form-control-label">Broadcast Message*</label>
                    <textarea formControlName="Message" class="form-control" placeholder="Enter your broadcast message"
                      rows="6"></textarea>
                  </div>
                </div>

                <div class="col-md-12">
                  <button type="submit" (click)="submitBroadcast()"
                    class="btn btn-sm btn-primary rounded-pill">Submit</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-sm btn-link text-primary px-2"
          onclick="$('#modal-application-user').modal('hide');">Cancel</button>
      </div>
    </div>
  </div>
</div>
