<app-aside [isLoadEnable]="this.isLoadEnable"></app-aside>
<div class="container-fluid container-application">
  <app-sidebar></app-sidebar>
  <!-- Content -->
  <div class="main-content position-relative">
    <app-header></app-header>
    <!-- Page content -->
    <div class="page-content">
      <!-- Page title -->
      <div class="page-title">
        <div class="row justify-content-between align-items-center">
          <div class="col-md-6 d-flex align-items-center justify-content-between justify-content-md-start mb-3 mb-md-0">
            <!-- Page title + Go Back button -->
            <div class="d-inline-block">
              <h5 class="h4 d-inline-block font-weight-400 mb-0 text-white">Account settings</h5>
            </div>
            <!-- Additional info -->
          </div>
          <div class="col-md-6 d-flex align-items-center justify-content-between justify-content-md-end">
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-4 order-lg-2">
          <div class="card">
            <div class="list-group list-group-flush">

              <div class="list-group-item">
                <div class="media">
                  <i class="far fa-user"></i>
                  <div class="media-body ml-3">
                    <a [routerLink]="['/admin/profile']" routerLinkActive="router-link-active"
                      class="stretched-link h6 mb-1">Profile</a>
                    <p class="mb-0 text-sm">Details about your personal information</p>
                  </div>
                </div>
              </div>
              <div class="list-group-item active">
                <div class="media">
                  <i class="fas fa-cog"></i>
                  <div class="media-body ml-3">
                    <a [routerLink]="" routerLinkActive="router-link-active" class="stretched-link h6 mb-1">Settings</a>
                    <p class="mb-0 text-sm">Security and passwords</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-8 order-lg-1">
          <div class="card">
            <div class="card-header">
              <h5 class=" h6 mb-0">Change password</h5>
            </div>
            <div class="card-body">
              <form role="form" name="changePasswordForm" [formGroup]="changePasswordForm"
                (ngSubmit)="changePassword()">


                <!--REACTIVE-FORM-VALIDATIONS-->
                <div *ngIf="this.isLoginOnSubmitErrorEnabled" class="alert alert-danger">
                  {{ this.errorOnLoginSubmit }}
                </div>

                <div
                  *ngIf="changePasswordForm.get('CurrentPassword').invalid && (changePasswordForm.get('CurrentPassword').dirty || changePasswordForm.get('CurrentPassword').touched)"
                  class="alert alert-danger">
                  <div *ngIf="changePasswordForm.get('CurrentPassword').errors.required">
                    Current Password is required.
                  </div>
                  <div *ngIf="changePasswordForm.get('CurrentPassword').errors.maxLength">
                    Current Password cannot be more than 30 characters long.
                  </div>
                  <div *ngIf="changePasswordForm.get('CurrentPassword').errors.minlength">
                    Current Password must be at least 6 characters long.
                  </div>
                </div>
                <div
                  *ngIf="changePasswordForm.get('CurrentPassword').valid &&  changePasswordForm.get('NewPassword').invalid && (changePasswordForm.get('NewPassword').dirty || changePasswordForm.get('NewPassword').touched)"
                  class="alert alert-danger">
                  <div *ngIf="changePasswordForm.get('NewPassword').errors.required">
                    Current Password is required.
                  </div>
                  <div *ngIf="changePasswordForm.get('NewPassword').errors.maxLength">
                    Current Password cannot be more than 30 characters long.
                  </div>
                  <div *ngIf="changePasswordForm.get('NewPassword').errors.minlength">
                    Current Password must be at least 8 characters long.
                  </div>
                </div>
                <div
                  *ngIf="changePasswordForm.get('NewPassword').valid &&  changePasswordForm.get('ConfirmPassword').invalid && (changePasswordForm.get('ConfirmPassword').dirty || changePasswordForm.get('ConfirmPassword').touched)"
                  class="alert alert-danger">
                  <div *ngIf="changePasswordForm.get('ConfirmPassword').errors.required">
                    Current Password is required.
                  </div>
                  <div *ngIf="changePasswordForm.get('ConfirmPassword').errors.maxLength">
                    Current Password cannot be more than 30 characters long.
                  </div>
                  <div *ngIf="changePasswordForm.get('ConfirmPassword').errors.minlength">
                    Current Password must be at least 8 characters long.
                  </div>
                  <div *ngIf="changePasswordForm.get('ConfirmPassword').errors.mustMatch">
                    Confirm password must be same as new password.
                  </div>
                </div>
                <!--REACTIVE-FORM-VALIDATIONS-->

                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label class="form-control-label">Current password</label>
                      <input class="form-control" type="password" formControlName="CurrentPassword">
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="form-control-label">New password</label>
                      <input class="form-control" type="password" formControlName="NewPassword">
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="form-control-label">Confirm password</label>
                      <input class="form-control" type="password" formControlName="ConfirmPassword">
                    </div>
                  </div>

                </div>
                <div class="mt-4">
                  <button type="submit" class="btn btn-sm btn-primary rounded-pill">Change password</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <app-footer></app-footer>
  </div>
</div>
