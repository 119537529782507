export namespace adminLoginScreen {
  export class Errors {
    email: string[];
    password: string[];
  }
  export class User {
    UserFullName: string;
    UserBirthDate: string;
    UserEmail: string;
    UserPhone: string;
    UserCountry: string;
    UserAboutDetail: string;
    UserImage: string;
    UserQr: string;
    UserPoints: number;
    UserInstagram: string;
    UserLinkedin: string;
    UserYoutube: string;
    UserTwitter: string;
    created_at: Date;
    Role: string;
    IsUserMale: boolean;
  }

  export class Data {
    User: User;
    Token: string;
  }

  export class AdminLogin {
    Flag: number;
    Message: string;
    Data: Data;
    Errors: Errors;
  }

  export class Community {
    CommunityId: number;
    CommunityName: string;
    CommunityDescription: string;
    CommunityImage: string;
  }

  export class RegistrationCommunity {
    Flag: number;
    Message: string;
    Data: Community[];
    Errors?: any;
  }
  export class LoginForm {
    email: string;
    password: string;
  }
}

export namespace adminProfile {
  export class ChangePassword {
    CurrentPassword: String;
    NewPassword: String;
    ConfirmPassword: String;
  }
  export interface Errors {
    UserFullName: string[];
    UserCountry: string[];
    UserImage: string[];
    UserBirthDate: string[];
    UserPhone: string[];
    IsUserMale: string[];
    UserAboutDetail: string[];
    UserInstagram: string[];
    UserLinkedin: string[];
    UserYoutube: string[];
    UserTwitter: string[];
  }
  export class UpdateProfile {
    UserFullName: string;
    UserBirthDate: string;
    UserPhone: string;
    UserCountry: string;
    UserAboutDetail: string;
    UserImage: File;
    UserInstagram: string;
    UserLinkedin: string;
    UserYoutube: string;
    UserTwitter: string;
    IsUserMale: Number;
  }

  export class User {
    UserFullName: string;
    UserBirthDate: string;
    UserEmail: string;
    UserPhone: string;
    UserCountry: string;
    UserAboutDetail: string;
    UserImage: string;
    UserQr: string;
    UserPoints: number;
    UserInstagram: string;
    UserLinkedin: string;
    UserYoutube: string;
    UserTwitter: string;
    IsUserMale: Number;
    created_at: Date;
    Role: string;
  }

  export class Data {
    User: User;
  }

  export class UpdatedProfile {
    Flag: number;
    Message: string;
    Data: Data;
    Errors: Errors;
  }
}

export namespace keywordManagement {
  export interface BannedUser {
    BannedId: number;
    UserId: number;
    UserFullName: string;
    UserEmail: string;
    UserImage: string;
  }

  export interface Keyword {
    KeywordId: number;
    Keyword: string;
    User: BannedUser[];
  }

  export interface Data {
    Keyword: Keyword[];
  }

  export interface KeywordRoot {
    Flag: number;
    Message: string;
    Data: Data;
    Errors?: any;
  }

  export interface KeywordResponse {
    Flag: number;
    Message: string;
    Data?: any;
    Errors?: any;
  }

  export class SaveKeyword {
    Keyword: string;
  }
  export class UpdateKeyword {
    KeywordId: number;
    Keyword: string;
  }
  export class DeleteKeyword {
    KeywordId: number;
  }
}

export namespace userManagement {
  export interface User {
    CategoryName: string;
    Level: string;
    UserId: number;
    UserFullName: string;
    UserEmail: string;
    UserCountry: string;
    UserPhone: string;
    UserBirthDate: string;
    UserImage: string;
    UserStars: number;
    UserCoins: number;
    UserRole: string;
    UserFollowers: number;
    UserFollowing: number;
  }

  export interface AdminUsers {
    Flag: number;
    Message: string;
    Data: User[];
    Errors?: any;
  }

  export interface Errors {
    UserId: string[];
  }
  export interface UserPersonal {
    CategoryName: string;
    Level: string;
    LevelId: number;
    OptionId: number;
    UserStars: number;
    UserCoins: number;
    UserFullName: string;
    UserEmail: string;
    UserCountry: string;
    UserPhone: string;
    UserImage: string;
    UserAbout: string;
    UserRole: string;
    UserFollowers: number;
    UserFollowing: number;
    UserInstagram: string;
    UserLinkedin: string;
    UserYoutube: string;
    UserTwitter: string;
  }

  export interface UserEducation {
    EducationId: number;
    SchoolName: string;
    DegreeName: string;
    FieldName: string;
    StartDate: string;
    IsPresent: number;
    EndDate: string;
  }

  export interface UserExperience {
    ExperienceId: number;
    Title: string;
    CompanyName: string;
    Industry: string;
    Location: string;
    StartDate: string;
    IsPresent: number;
    EndDate: string;
  }

  export interface UserInitiative {
    InitiativeId: number;
    InitiativeName: string;
    InitiativeImage: string;
    created_at: Date;
  }

  export interface Data {
    UserPersonal: UserPersonal;
    UserEducation: UserEducation[];
    UserExperience: UserExperience[];
    UserInitiative: UserInitiative[];
  }

  export interface UserProfile {
    Flag: number;
    Message: string;
    Data: Data;
    Errors: Errors;
  }

  export interface Level {
    LevelId: number;
    LevelName: string;
  }
  export interface Errors {
    LevelId: string[];
    OptionId: string[];
  }
  export interface VolunteerLevels {
    Flag: number;
    Message: string;
    Data: Level[];
    Errors: Errors;
  }
}

export namespace volunteerManagement {
  export interface Level {
    LevelId: number;
    OptionId: number;
    LevelName: string;
    RequiredPoints: number;
    RequiredStars: number;
    PerAnswerPoints: number;
    LevelDetail: string;
  }

  export interface Option {
    OptionId: number;
    OptionName: string;
    OptionDetail: string;
    Levels: Level[];
  }

  export interface VolunteerOptions {
    Flag: number;
    Message: string;
    Data: Option[];
    Errors?: any;
  }
}

export namespace homeAnalytics {
  export interface SplitRevenue {
    UpgradingPages: number;
    UpgradingCommunities: number;
    PurchaseCoins: number;
    BoostAndCampaigns: number;
    Total: number;
  }

  export interface Analytics {
    NewUsers: number;
    TotalPages: number;
    TotalCommunities: number;
    TotalInitiatives: number;
    TotalCampaigns: number;
    TotalQuestions: number;
    TotalAnswers: number;
    TotalLiveVideos: number;
    TotalDonations: number;
    TotalSponsorships: number;
    TotalPremiumPages: number;
    TotalPremiumCommunities: number;
    SplitRevenue: SplitRevenue;
    LocationUsers: number[];
    LocationVolunteers: number[];
    Location: string[];
    VolunteerUsers: number[];
    VolunteerLevel: string[];
  }

  export interface Home {
    Flag: number;
    Message: string;
    Data: Analytics;
    Errors?: any;
  }

  export interface Donation {
    Donation: number;
    ReferenceType: string;
    ReceiverId: number;
    UserFullName: string;
    UserImage: string;
    donated_at: string;
  }

  export interface DonationHistory {
    Flag: number;
    Message: string;
    Data: Donation[];
    Errors?: any;
  }
}

export namespace complaintManagement {
  export interface Complaint {
    ComplaintId: number;
    Complaint: string;
    IsResolved: number;
    created_at: Date;
    UserImage: string;
    UserFullName: string;
    UserEmail: string;
  }

  export interface Complaints {
    Flag: number;
    Message: string;
    Data: Complaint[];
    Errors?: any;
  }
}

export namespace opportunityManagement {
  export interface Application {
    ApplicantId: number;
    ApplicantEmail: string;
    ApplicantPhone: string;
    ApplicantImage: string;
    ApplicantResume: string;
  }

  export interface Opportunity {
    OpportunityId: number;
    LevelId: number;
    Title: string;
    CompanyName: string;
    Industry: string;
    Description: string;
    Location: string;
    Image: string;
    Applications: Application[];
  }

  export interface Opportunities {
    Flag: number;
    Message: string;
    Data: Opportunity[];
    Errors?: any;
  }

  export interface Category {
    CategoryId: number;
    CategoryName: string;
  }

  export interface Categories {
    Flag: number;
    Message: string;
    Data: Category[];
    Errors?: any;
  }

  export interface Errors {
    OpportunityId: string[];
    Title: string[];
    CompanyName: string[];
    Industry: string[];
    Location: string[];
    Description: string[];
    Image: string[];
  }

  export interface UpdateOpportunity {
    Flag: number;
    Message: string;
    Data?: any;
    Errors: Errors;
  }

  export interface AddErrors {
    Title: string[];
    LevelId: string[];
    CompanyName: string[];
    Industry: string[];
    Location: string[];
    Description: string[];
    Image: string[];
  }

  export interface AddOpportunity {
    Flag: number;
    Message: string;
    Data?: any;
    Errors: AddErrors;
  }
}

export namespace pageManagement {
  export interface Page {
    PageId: number;
    PageName: string;
    PageLocation: string;
    PageEmail: string;
    PageImage: string;
    IsPageUpgrade: number;
    UserFullName: string;
    CategoryName: string;
    Stars: number;
    Coins: number;
    Followers: number;
    created_at: string;
  }
  export interface Pages {
    Flag: number;
    Message: string;
    Data: Page[];
    Errors?: any;
  }
}

export namespace communityManagement {
  export interface Community {
    CommunityId: number;
    CommunityName: string;
    CommunityLocation: string;
    CommunityEmail: string;
    CommunityImage: string;
    IsCommunityUpgrade: number;
    UserFullName: string;
    CategoryName: string;
    Stars: number;
    Coins: number;
    Followers: number;
    created_at: string;
  }
  export interface Communities {
    Flag: number;
    Message: string;
    Data: Community[];
    Errors?: any;
  }
}

export namespace initiativeManagement {
  export interface Initiative {
    InitiativeId: number;
    InitiativeName: string;
    InitiativeRegion: string;
    InitiativeImage: string;
    UserFullName: string;
    InitiativeMinimumAmount: number;
    CategoryName: string;
    LevelName: string;
    Stars: number;
    Coins: number;
    Followers: number;
    Questions: number;
    Answers: number;
    created_at: string;
  }

  export interface Initiatives {
    Flag: number;
    Message: string;
    Data: Initiative[];
    Errors?: any;
  }
}

export namespace dataBank {
  export interface Data {
    Questions: number[];
    Answers: number[];
    Categories: string[];
  }

  export interface DataBank {
    Flag: number;
    Message: string;
    Data: Data;
    Errors?: any;
  }
}

export namespace broadcastMessaging {
  export interface Broadcasts {
    Flag: number;
    Message: string;
    Data: Broadcast[];
    Errors?: any;
  }
  export interface Broadcast {
    Title: string;
    Message: string;
    Date: string;
  }
}

export namespace trendsforeQuestions {
  export interface AllQuestions {
    Flag: number;
    Message: string;
    Data: Question[];
    Errors?: any;
  }

  export interface Question {
    UserImage: string;
    UserFullName: string;
    ReferenceType: string;
    QuestionId: number;
    QuestionType: string;
    QuestionHeadline: string;
    MainQuestion: string;
    MainQuestionOptions: Option[];
    IsPinned: boolean;
    Stars: number;
    Answers: number;
    Comments: number;
    SubQuestions?: SubQuestion[];
    Content: Content[];
    Hastags: Hastag[];
    Date: string;
  }

  export interface Hastag {
    Hashtag: string;
  }

  export interface Content {
    ContentType: string;
    ContentUrl: string;
    ContentTitle: string;
  }

  export interface SubQuestion {
    Question: string;
    Options: Option[];
  }

  export interface Option {
    option: string;
  }
}


export namespace liveVideoManagement {
  export interface Live {
    LiveId: number;
    Title: string;
    Scope: string;
    VideoUrl: string;
    StartsAt: string;
    UserFullName: string;
    UserImage: string;
    Stars: number;
    Coins: number;
    Comments: number;
    Views: number;
  }

  export interface LiveVideos {
    Flag: number;
    Message: string;
    Data: Live[];
    Errors?: any;
  }

}


export namespace generalUtilities {
  export class Utils {
    location = [
      'Afghanistan',
      'Albania',
      'Algeria',
      'American Samoa',
      'Andorra',
      'Angola',
      'Anguilla',
      'Antarctica',
      'Antigua and Barbuda',
      'Argentina',
      'Armenia',
      'Aruba',
      'Australia',
      'Austria',
      'Azerbaijan',
      'Bahamas',
      'Bahrain',
      'Bangladesh',
      'Barbados',
      'Belarus',
      'Belgium',
      'Belize',
      'Benin',
      'Bermuda',
      'Bhutan',
      'Bolivia',
      'Bosnia and Herzegowina',
      'Botswana',
      'Bouvet Island',
      'Brazil',
      'British Indian Ocean Territory',
      'Brunei Darussalam',
      'Bulgaria',
      'Burkina Faso',
      'Burundi',
      'Cambodia',
      'Cameroon',
      'Canada',
      'Cape Verde',
      'Cayman Islands',
      'Central African Republic',
      'Chad',
      'Chile',
      'China',
      'Christmas Island',
      'Cocos (Keeling) Islands',
      'Colombia',
      'Comoros',
      'Congo',
      'Congo, the Democratic Republic of the',
      'Cook Islands',
      'Costa Rica',
      "Cote d'Ivoire",
      'Croatia (Hrvatska)',
      'Cuba',
      'Cyprus',
      'Czech Republic',
      'Denmark',
      'Djibouti',
      'Dominica',
      'Dominican Republic',
      'East Timor',
      'Ecuador',
      'Egypt',
      'El Salvador',
      'Equatorial Guinea',
      'Eritrea',
      'Estonia',
      'Ethiopia',
      'Falkland Islands (Malvinas)',
      'Faroe Islands',
      'Fiji',
      'Finland',
      'France',
      'France Metropolitan',
      'French Guiana',
      'French Polynesia',
      'French Southern Territories',
      'Gabon',
      'Gambia',
      'Georgia',
      'Germany',
      'Ghana',
      'Gibraltar',
      'Greece',
      'Greenland',
      'Grenada',
      'Guadeloupe',
      'Guam',
      'Guatemala',
      'Guinea',
      'Guinea-Bissau',
      'Guyana',
      'Haiti',
      'Heard and Mc Donald Islands',
      'Holy See (Vatican City State)',
      'Honduras',
      'Hong Kong',
      'Hungary',
      'Iceland',
      'India',
      'Indonesia',
      'Iran (Islamic Republic of)',
      'Iraq',
      'Ireland',
      'Israel',
      'Italy',
      'Jamaica',
      'Japan',
      'Jordan',
      'Kazakhstan',
      'Kenya',
      'Kiribati',
      "Korea, Democratic People's Republic of",
      'Korea, Republic of',
      'Kuwait',
      'Kyrgyzstan',
      "Lao, People's Democratic Republic",
      'Latvia',
      'Lebanon',
      'Lesotho',
      'Liberia',
      'Libyan Arab Jamahiriya',
      'Liechtenstein',
      'Lithuania',
      'Luxembourg',
      'Macau',
      'Macedonia, The Former Yugoslav Republic of',
      'Madagascar',
      'Malawi',
      'Malaysia',
      'Maldives',
      'Mali',
      'Malta',
      'Marshall Islands',
      'Martinique',
      'Mauritania',
      'Mauritius',
      'Mayotte',
      'Mexico',
      'Micronesia, Federated States of',
      'Moldova, Republic of',
      'Monaco',
      'Mongolia',
      'Montserrat',
      'Morocco',
      'Mozambique',
      'Myanmar',
      'Namibia',
      'Nauru',
      'Nepal',
      'Netherlands',
      'Netherlands Antilles',
      'New Caledonia',
      'New Zealand',
      'Nicaragua',
      'Niger',
      'Nigeria',
      'Niue',
      'Norfolk Island',
      'Northern Mariana Islands',
      'Norway',
      'Oman',
      'Pakistan',
      'Palau',
      'Panama',
      'Papua New Guinea',
      'Paraguay',
      'Peru',
      'Palestine',
      'Philippines',
      'Pitcairn',
      'Poland',
      'Portugal',
      'Puerto Rico',
      'Qatar',
      'Reunion',
      'Romania',
      'Russian Federation',
      'Rwanda',
      'Saint Kitts and Nevis',
      'Saint Lucia',
      'Saint Vincent and the Grenadines',
      'Samoa',
      'San Marino',
      'Sao Tome and Principe',
      'Saudi Arabia',
      'Senegal',
      'Seychelles',
      'Sierra Leone',
      'Singapore',
      'Slovakia (Slovak Republic)',
      'Slovenia',
      'Solomon Islands',
      'Somalia',
      'South Africa',
      'South Georgia and the South Sandwich Islands',
      'Spain',
      'Sri Lanka',
      'St. Helena',
      'St. Pierre and Miquelon',
      'Sudan',
      'Suriname',
      'Svalbard and Jan Mayen Islands',
      'Swaziland',
      'Sweden',
      'Switzerland',
      'Syrian Arab Republic',
      'Taiwan, Province of China',
      'Tajikistan',
      'Tanzania, United Republic of',
      'Thailand',
      'Togo',
      'Tokelau',
      'Tonga',
      'Trinidad and Tobago',
      'Tunisia',
      'Turkey',
      'Turkmenistan',
      'Turks and Caicos Islands',
      'Tuvalu',
      'Uganda',
      'Ukraine',
      'United Arab Emirates',
      'United Kingdom',
      'United States',
      'United States Minor Outlying Islands',
      'Uruguay',
      'Uzbekistan',
      'Vanuatu',
      'Venezuela',
      'Vietnam',
      'Virgin Islands (British)',
      'Virgin Islands (U.S.)',
      'Wallis and Futuna Islands',
      'Western Sahara',
      'Yemen',
      'Yugoslavia',
      'Zambia',
      'Zimbabwe',
    ];
  }
}
