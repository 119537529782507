<app-aside [isLoadEnable]="this.isLoadEnable"></app-aside>
<div class="container-fluid container-application">
  <app-sidebar></app-sidebar>
  <!-- Content -->
  <div class="main-content position-relative">
    <app-header></app-header>
    <!-- Page content -->
    <div class="page-content">
      <div class="card" *ngIf="this.LiveVideoModel?.length <= 0">
        <img class="img-center img" src="../../../assets/img/errors/nodata-found.png" alt="NO DATA">
      </div>
      <div *ngIf="this.LiveVideoModel?.length > 0">
        <!-- Page title -->
        <div class="page-title">
          <div class="row justify-content-between align-items-center">
            <div
              class="col-md-6 d-flex align-items-center justify-content-between justify-content-md-start mb-3 mb-md-0">
              <!-- Page title + Go Back button -->
              <div class="d-inline-block">
                <h5 class="h4 d-inline-block font-weight-400 mb-0 text-white">Trendsfore Live Videos</h5>
              </div>
              <!-- Additional info -->
              <div class="align-items-center ml-4 d-inline-flex">
                <span class="h4 text-info mb-0 mr-2">{{ this.LiveVideoModel?.length }}</span>
                <span class="text-sm opacity-7 text-white">Total Live Videos</span>
              </div>
            </div>
            <div class="col-md-6 d-flex align-items-center justify-content-between justify-content-md-end">
              <!-- Filter input -->
              <form class="bg-neutral rounded-pill d-inline-block">
                <div class="input-group input-group-sm input-group-merge input-group-flush">
                  <div class="input-group-prepend">
                    <span class="input-group-text bg-transparent"><i class="fas fa-filter"></i></span>
                  </div>
                  <input type="text" (keyup)="search($event.target.value)" class="form-control form-control-flush"
                    placeholder="Filter results">
                </div>
              </form>
              <a [routerLink]="" (click)="this.refresh()"
                class="btn btn-sm btn-white btn-icon-only rounded-circle ml-4">
                <span class="btn-inner--icon"><i class="fas fa-sync"></i></span>
              </a>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-3 col-sm-6" *ngFor="let video of this.LiveVideoModel">
            <div class="card hover-shadow-lg">
              <div class="card-body text-center">
                <div class="avatar-parent-child">
                  <a href="{{ video.VideoUrl }}">
                    <img alt="Image placeholder" src="{{ this.baseUrl+video.UserImage }}"
                      class="avatar  rounded-circle avatar-lg">
                    <span class="avatar-child avatar-badge bg-danger"></span>
                  </a>
                </div>
                <h5 class="h6 mt-4 mb-2">{{video.UserFullName }}</h5>
                <a [routerLink]="" (click)="this.stop(video)" class="btn btn-sm btn-danger rounded-pill shadow-sm">End
                  Live</a>
              </div>
              <div class="card-body border-top">
                <div class="row justify-content-between align-items-center">
                  <div class="col-6 text-center">
                    <span class="d-block h6 mb-0">{{video.Stars }}</span>
                    <span class="d-block text-sm text-muted">Stars</span>
                  </div>
                  <div class="col-6 text-center">
                    <span class="d-block h6 mb-0">{{video.Coins }}</span>
                    <span class="d-block text-sm text-muted">Coins</span>
                  </div>
                </div>
                <div class="row justify-content-between align-items-center">
                  <div class="col-6 text-center">
                    <span class="d-block h6 mb-0">{{video.Comments }}</span>
                    <span class="d-block text-sm text-muted">Comments</span>
                  </div>
                  <div class="col-6 text-center">
                    <span class="d-block h6 mb-0">{{video.Views }}</span>
                    <span class="d-block text-sm text-muted">Views</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-sm-6" *ngIf="this.isLoadMoreVisible">
            <div class="card card-fluid card-placeholder align-items-center justify-content-center">
              <div class="text-center">
                <a [routerLink]="" (click)="this.loadMore()" class="stretched-link">
                  <i class="fas fa-plus fa-2x text-muted"></i>
                  <span class="h6 text-muted d-block mt-3">Load More</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <app-footer></app-footer>
  </div>
</div>
