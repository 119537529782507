<app-aside [isLoadEnable]="this.isLoadEnable"></app-aside>
<div class="container-fluid container-application">
  <app-sidebar></app-sidebar>
  <!-- Content -->
  <div class="main-content position-relative">
    <app-header></app-header>

    <!--Page Question Modal-->
    <div class="modal fade docs-example-modal-xl" id="modal-question-create" tabindex="-1" role="dialog"
      aria-labelledby="myExtraLargeModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-xl">
        <form>
          <div class="modal-content">
            <div class="modal-body">
              <div class="row">
                <div class="col-12">
                  <div *ngIf="this.isLoginOnSubmitErrorEnabled" class="alert alert-danger"
                    [innerHTML]="this.errorOnLoginSubmit">
                  </div>
                </div>
              </div>



              <!-- Question Type -->
              <div class="row">
                <div class="col-6">
                  <div class="card shadow-sm hover-shadow-none border border-info">
                    <div class="card-body text-center">
                      <h5 class="h6 my-4"><a [routerLink]="">Open Question</a></h5>
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <div class="card shadow-sm hover-shadow-none">
                    <div class="card-body text-center">
                      <h5 class="h6 my-4"><a [routerLink]="">Poll Question</a></h5>
                    </div>
                  </div>
                </div>
              </div>


              <div class="row">
                <!-- Main Category -->
                <div class="col-6 form-group">
                  <label class="form-control-label">
                    Main Category
                  </label>
                  <select class="form-control" multiple>
                    <option selected value="" disabled>Please select main categories</option>
                  </select>
                </div>
                <!-- Sub Category -->
                <div class="col-6 form-group">
                  <label class="form-control-label">
                    Sub Category
                  </label>
                  <select class="form-control" multiple>
                    <option selected value="" disabled>Please select sub categories</option>
                  </select>
                </div>
              </div>
              <!-- Question Headline -->
              <div class="form-group">
                <label class="form-control-label mb-0">
                  Question Headline
                </label>
                <input type="text" placeholder="Please enter question headline" class="form-control">
              </div>
              <!-- Main Question -->
              <div class="form-group">
                <label class="form-control-label">
                  Main Question
                </label>
                <textarea style="height: 100px;resize: none;" class="form-control"
                  placeholder="Tel us about your question?" rows="12"></textarea>
              </div>

              <!-- Question Multimedia -->
              <div class="form-group">
                <label class="form-control-label">
                  Question Multimedia
                </label>
                <div class="dropzone dropzone-multiple" data-toggle="dropzone" data-dropzone-url="http://"
                  data-dropzone-multiple>
                  <div class="fallback">
                    <div class="custom-file">
                      <input type="file" class="custom-file-input" id="dropzone-1" multiple>
                      <label class="custom-file-label" for="customFileUpload">Choose file</label>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Question Hashtags -->
              <div class="form-group">
                <label class="form-control-label">
                  Question Hashtags
                </label>
                <input type="text" class="form-control" placeholder="Finance, Business, Marketing, Trendsfore" />
              </div>

              <!-- Question Option -->
              <h6>
                Main Question Options
                <button type="button" class="btn btn-light btn-sm "><i class="fas fa-plus"></i></button>
              </h6>
              <div class="row" [innerHTML]="">
                <div class="col-6 form-group">
                  <div class="d-flex align-items-center justify-content-between justify-content-md-end">
                    <input type="text" placeholder="Please enter question option" class="form-control">
                    <button type="button" class="btn btn-light"><i class="fas fa-times"></i></button>
                  </div>
                </div>

                <div class="col-6 form-group">
                  <div class="d-flex align-items-center justify-content-between justify-content-md-end">
                    <input type="text" placeholder="Please enter question option" class="form-control">
                    <button type="button" class="btn btn-light"><i class="fas fa-times"></i></button>
                  </div>
                </div>

                <div class="col-6 form-group">
                  <div class="d-flex align-items-center justify-content-between justify-content-md-end">
                    <input type="text" placeholder="Please enter question option" class="form-control">
                    <button type="button" class="btn btn-light"><i class="fas fa-times"></i></button>
                  </div>
                </div>

                <div class="col-6 form-group">
                  <div class="d-flex align-items-center justify-content-between justify-content-md-end">
                    <input type="text" placeholder="Please enter question option" class="form-control">
                    <button type="button" class="btn btn-light"><i class="fas fa-times"></i></button>
                  </div>
                </div>

              </div>

              <hr class=" my-3">
              <!-- Sub Question-->
              <h6>
                Sub Question
                <button type="button" class="btn btn-light btn-sm "><i class="fas fa-plus"></i></button>
              </h6>
              <div class="form-group">
                <textarea style="height: 100px;resize: none;" class="form-control"
                  placeholder="Tel us about your sub question?" rows="12"></textarea>
              </div>
              <div class="row">
                <div class="col-6 form-group">
                  <label class="form-control-label mb-0">
                    Option1
                  </label>
                  <input type="text" placeholder="Please enter question option" class="form-control">
                </div>
                <div class="col-6 form-group">
                  <label class="form-control-label mb-0">
                    Option2
                  </label>
                  <input type="text" placeholder="Please enter question option" class="form-control">
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-sm btn-primary rounded-pill mr-auto">Submit</button>

              <button type="button" class="btn btn-sm btn-link text-danger px-2" data-dismiss="modal">Cancel</button>
            </div>
          </div>
        </form>

      </div>
    </div>


    <!-- Page content -->
    <div class="page-content">
      <!-- Page title -->
      <div class="page-title">
        <div class="row justify-content-between align-items-center">
          <div class="col-md-6 d-flex align-items-center justify-content-between justify-content-md-start mb-3 mb-md-0">
            <!-- Page title + Go Back button -->
            <div class="d-inline-block">
              <h5 class="h4 d-inline-block font-weight-400 mb-0 text-white">
                Trendsfore Page
              </h5>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-4">
          <!-- Profile stats -->
          <div class="card card-fluid">
            <div class="card-body">
              <div class="row align-items-center">
                <div class="col-auto">
                  <!-- Avatar -->
                  <a href="#" class="avatar avatar-xl rounded-circle">
                    <img alt="Image placeholder" src="../../assets/img/theme/light/team-3-800x800.jpg" class="" />
                  </a>
                </div>
                <div class="col ml-md-n2">
                  <a class="d-block h6 mb-0">John Sullivan</a>
                  <small class="d-block text-muted text-xs">Laravel Developer</small>
                </div>
                <div class="col-auto">
                  <button title="Edit Page" type="button" class="btn btn-xs btn-primary btn-icon rounded-pill">
                    <span class="btn-inner--icon"><i class="fas fa-edit"></i></span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <!-- Profile stats -->
          <div class="card card-fluid">
            <div class="card-body">
              <div class="row">
                <div class="col-auto text-left">
                  <h6>Description</h6>
                  <p class="text-xs">
                    Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                    Optio minima inventore deleniti sequi laborum, impedit
                    numquam unde temporibus facere consequuntur ab
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <!-- Profile stats -->
          <div class="card card-fluid">
            <div class="card-body">
              <div class="row">
                <div class="col-auto text-left">
                  <h6>Contacts</h6>
                  <span class="badge badge-light text-xs"><i class="fas fa-mobile mr-2"></i>+8372402074</span>
                  <span class="badge badge-light text-xs"><i
                      class="fas fa-envelope mr-2"></i>officialpage@trendsfore.com</span>
                  <span class="badge badge-light text-xs"><i
                      class="fas fa-globe mr-2"></i>https://trensfore.com/trendsfore</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--l
                    <div class="col-lg-3">
          <div class="card card-fluid">
            <div class="card-body">
              <div class="row align-items-center">
                <div class="col">
                  <h6 class="text-sm mb-0">
                    <i class="fas fa-envelope mr-2"></i>Email
                  </h6>
                </div>
                <div class="col-auto">
                  <span class="text-sm">john.sullivan</span>
                </div>
              </div>
              <hr class="my-3">
              <div class="row align-items-center">
                <div class="col">
                  <h6 class="text-sm mb-0">
                    <i class="fas fa-mobile mr-2"></i>Phone
                  </h6>
                </div>
                <div class="col-auto">
                  <span class="text-sm">+40-788-45-67</span>
                </div>
              </div>
              <hr class="my-3">
              <div class="row align-items-center">
                <div class="col">
                  <h6 class="text-sm mb-0">
                    <i class="fas fa-globe mr-2"></i>Website
                  </h6>
                </div>
                <div class="col-auto">
                  <span class="text-sm">@johnthedon</span>
                </div>
              </div>
            </div>
          </div>
        </div>
          <div class="col-lg-4">
          <div class="card card-fluid">
            <div class="card-body">
              <div class="row align-items-center">
                <div class="col">
                  <h6 class="text-sm mb-0">Performance</h6>
                  <span class="text-nowrap h6 text-muted text-sm">65%</span>
                  <span class="badge badge-xs badge-soft-warning ml-2"><i class="fas fa-arrow-down"></i> 13%</span>
                </div>
                <div class="col-4">
                  <div style="max-width: 100px;">
                    <div class="spark-chart" data-toggle="spark-chart" data-type="line" data-height="30"
                      data-color="warning" data-dataset="[47, 94, 24, 18, 26, 65, 31, 47, 10, 44, 45]"></div>
                  </div>
                </div>
              </div>
              <hr class="my-3">
              <div class="row align-items-center">
                <div class="col">
                  <h6 class="text-sm mb-0">Sales</h6>
                  <span class="text-nowrap h6 text-muted text-sm">$3.068,90</span>
                  <span class="badge badge-xs badge-soft-success ml-2"><i class="fas fa-arrow-up"></i> 25%</span>
                </div>
                <div class="col-4">
                  <div style="max-width: 100px;">
                    <div class="spark-chart" data-toggle="spark-chart" data-type="line" data-height="30"
                      data-color="success" data-dataset="[47, 94, 24, 18, 26, 65, 31, 47, 10, 44, 45]"></div>
                  </div>
                </div>
              </div>
              <hr class="my-3">
              <div class="row align-items-center">
                <div class="col">
                  <h6 class="text-sm mb-0">Followers</h6>
                  <span class="text-nowrap h6 text-muted text-sm">80</span>
                  <span class="badge badge-xs badge-soft-info ml-2"><i class="fas fa-arrow-up"></i> 17%</span>
                </div>
                <div class="col-4">
                  <div style="max-width: 100px;">
                    <div class="spark-chart" data-toggle="spark-chart" data-type="bar" data-height="30"
                      data-color="primary" data-dataset="[47, 94, 24, 18, 26, 65, 31, 47, 10, 44, 45]"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        -->
      </div>

      <!-- Question title -->
      <div class="page-title">
        <div class="row justify-content-between align-items-center">
          <div class="col-md-6 d-flex align-items-center justify-content-between justify-content-md-start mb-3 mb-md-0">
            <!-- Question + Go Back button -->
            <div class="d-inline-block">
              <h5 class="h4 d-inline-block font-weight-1000 mb-0 text-white">
                Questions
              </h5>
            </div>
            <!-- Additional info -->
          </div>
          <div class="col-md-6 d-flex align-items-center justify-content-between justify-content-md-end">
            <!-- Filter input -->
            <form class="bg-neutral rounded-pill d-inline-block">
              <div class="input-group input-group-sm input-group-merge input-group-flush">
                <div class="input-group-prepend">
                  <span class="input-group-text bg-transparent"><i class="fas fa-filter"></i></span>
                </div>
                <input type="text" class="form-control form-control-flush" placeholder="Filter results" />
              </div>
            </form>
            <a [routerLink]="" class="btn btn-sm btn-white btn-icon-only rounded-circle ml-2">
              <span class="btn-inner--icon"><i class="fas fa-sync"></i></span>
            </a>
            <a href="#modal-question-create" data-toggle="modal"
              class="btn btn-sm btn-white btn-icon-only rounded-circle">
              <span class="btn-inner--icon"><i class="fas fa-plus"></i></span>
            </a>
          </div>
        </div>
      </div>
      <!-- Listing -->
      <div class="card-columns mt-4">
        <!--ALL QUESTIONS-->
        <div class="card shadow-sm hover-shadow-lg">
          <div class="card-header border-0">
            <div class="row align-items-center">
              <div class="col-10">
                <div class="media mb-0 align-items-center">
                  <img alt="Image placeholder" src="../../assets/img/theme/light/brand-avatar-3.png"
                    class="avatar  rounded-circle avatar-md">
                  <div class="media-body pl-2">
                    <div class="text-md my-0">
                      <a href="#" class="text-primary font-weight-600">John Mayer</a>
                    </div>
                    <div class="text-sm my-0">
                      Asked <a href="#" class="text-primary font-weight-600">27 April, 2021</a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-2 text-right">
                <div class="actions">
                  <div class="dropdown" data-toggle="dropdown">
                    <a [routerLink]="" class="action-item"><i class="fas fa-ellipsis-v"></i></a>
                    <div class="dropdown-menu dropdown-menu-right">
                      <a [routerLink]="" class="dropdown-item">Pin Question</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body pt-0">

            <div class="p-3 border border-dashed">

              <!--IF POLL QUESTION-->
              <div class="TrendsforePollQuestions">
                <h6 class="mb-2">Question Headline</h6>
                <span class="text-md text-muted font-weight-bolder">Main Detail Question line about the detail
                  question?</span>
                <div class="MainQuestionOptions ml-2">
                  <span class="text-sm font-weight-bold">Options</span>
                  <ul class="text-sm">
                    <li>Option1</li>
                  </ul>

                </div>
                <div class="SubQuestions ml-2">
                  <hr class="my-2">
                  <div class="SubQuestion">
                    <span class="text-sm font-weight-bold">Sub Question line about the detail question?</span>
                    <ul class="text-sm">
                      <li>Option1</li>
                    </ul>
                  </div>
                </div>
              </div>

              <!--IF OPEN QUESTION-->
              <div class="TrendsforeOpenQuestions">
                <h6 class="mb-2">Question Headline</h6>
                <span class="text-md text-muted font-weight-bolder">Main Detail Question line about the detail
                  question?</span>
                <div class="SubQuestions ml-2">
                  <hr class="my-2">
                  <div class="SubQuestion">
                    <span class="text-sm font-weight-bold">Sub Question line about the detail question?</span>
                    <ul class="text-sm">
                      <li>Option1</li>
                    </ul>
                  </div>
                </div>
              </div>

              <!--IF VOICE QUESTION-->
              <div class="TrendsforeVoiceQuestions">
                <h6 class="mb-2">Question Headline</h6>
                <audio style="width: 100%;" controls>
                  <source src="horse.mp3" type="audio/mpeg">
                  Your browser does not support the audio element.
                </audio>
                <h6 class="mb-2">Main Question</h6>
                <audio style="width: 100%;" controls>
                  <source src="horse.mp3" type="audio/mpeg">
                  Your browser does not support the audio element.
                </audio>
              </div>

              <div class="ReferencesAndMedia">
                <hr class="my-3">
                <h6 class="mb-2">Media & References</h6>
                <a href="google.com" target="_blank" class="btn btn-primary btn-xs ml-1 mb-1" download>Video.mp4</a>
                <a href="google.com" target="_blank" class="btn btn-primary btn-xs ml-1 mb-1"
                  download>imag09334e32.com</a>
                <a href="google.com" target="_blank" class="btn btn-primary btn-xs  ml-1 mb-1">google</a>
              </div>
              <div class="QuestionHashtags mt-2">
                <a class="btn btn-light rounded-pill btn-xs text-dark font-weight-bolder ml-1 mb-1">#Finance</a>
              </div>
              <hr class="my-3">
              <div class="row align-items-center">
                <div class="col-3 text-center">
                  <h6 class="mb-0 text-xs">
                    <span class="text-muted"><i title="Answers" class="fas fa-edit"></i></span> 329
                  </h6>
                </div>
                <div class="col-3 text-center">
                  <h6 class="mb-0 text-xs">
                    <span class="text-muted"><i title="Comments" class="fas fa-comments"></i></span> 439
                  </h6>
                </div>
                <div class="col-3 text-center">
                  <h6 class="mb-0 text-xs">
                    <span class="text-muted"><i title="Stars" class="fas fa-star"></i></span> 439
                  </h6>
                </div>
                <div class="col-3 text-center">
                  <h6 class="mb-0 text-xs"> <span class="text-muted"><i title="Shares"
                        class="fas fa-share-alt"></i></span> 439</h6>
                </div>
              </div>
            </div>
          </div>
        </div>


      </div>
    </div>
    <app-footer></app-footer>
  </div>
</div>
