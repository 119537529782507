<!-- Sidenav -->
<div class="sidenav show" id="sidenav-main">
  <!-- Sidenav header -->
  <div class="sidenav-header d-flex align-items-center">
    <a class="navbar-brand" [routerLink]="['/admin/dashboard']">
      <img src="assets/img/brand/logo-white.png" class="navbar-brand-img" alt="Trendsfore">
      <span class="text-default"> Trendsfore</span>
    </a>
    <div class="ml-auto">
      <!-- Sidenav toggler -->
      <div class="sidenav-toggler sidenav-toggler-dark d-md-none" data-action="sidenav-unpin"
        data-target="#sidenav-main">
        <div class="sidenav-toggler-inner">
          <i class="sidenav-toggler-line bg-white"></i>
          <i class="sidenav-toggler-line bg-white"></i>
          <i class="sidenav-toggler-line bg-white"></i>
        </div>
      </div>
    </div>
  </div>
  <!-- User mini profile -->
  <div class="sidenav-user d-flex flex-column align-items-center justify-content-between text-center">
    <!-- Avatar -->
    <div>
      <a [routerLink]="['/admin/profile']" class="avatar rounded-circle avatar-xl">
        <img alt="{{ this.DataModel.UserFullName }}" src="{{ this.baseUrl+this.DataModel.UserImage }}" class="">
      </a>
      <div class="mt-4">
        <h5 class="mb-0 text-white">{{ this.DataModel.UserFullName }}</h5>
        <a class="mt-4 btn btn-sm btn-white btn-icon rounded-pill shadow hover-translate-y-n3">
          <span class="btn-inner--text">Super {{ this.DataModel.Role }}</span>
        </a>
      </div>
    </div>
    <!-- User info -->
    <!-- Actions -->
    <div class="w-100 mt-3 actions d-flex justify-content-between">
      <a [routerLink]="['/admin/profile']" routerLinkActive="active" class="action-item text-white action-item-lg pl-0">
        <i class="fas fa-user"></i>
      </a>
      <a href="#modal-chat" data-toggle="modal" class="action-item action-item-lg text-white text-white"
        data-toggle="modal">
        <i class="fas fa-comment-alt"></i>
      </a>
      <a [routerLink]="['/admin/setting']" routerLinkActive="active" class="action-item action-item-lg text-white pr-0">
        <i class="fas fa-cog"></i>
      </a>
    </div>
  </div>
  <!-- Application nav -->
  <div class="nav-application clearfix">
    <a [routerLink]="['/admin/dashboard']" routerLinkActive="active" class="btn btn-square text-sm">
      <span class="btn-inner--icon d-block">
        <i class="fas fa-home fa-2x"></i>
      </span>
      <span class="btn-inner--icon d-block pt-2">Home Analytics</span>
    </a>
    <a [routerLink]="['/admin/users']" routerLinkActive="active" class="btn btn-square text-sm">
      <span class="btn-inner--icon d-block">
        <i class="fas fa-users-cog fa-2x"></i>
      </span>
      <span class="btn-inner--icon d-block pt-2">Trendsfore Users</span>
    </a>
    <a [routerLink]="['/admin/volunteers']" routerLinkActive="active" class="btn btn-square text-sm">
      <span class="btn-inner--icon d-block">
        <i class="fas fa-layer-group fa-2x"></i>
      </span>
      <span class="btn-inner--icon d-block pt-2">Volunteer Levels</span>
    </a>

    <a [routerLink]="['/admin/questions']" routerLinkActive="active" class="btn btn-square text-sm">
      <span class="btn-inner--icon d-block">
        <i class="fas fa-tasks fa-2x"></i>
      </span>
      <span class="btn-inner--icon d-block pt-2">Trendsfore Questions</span>
    </a>
    <a [routerLink]="['/admin/opportunities']" routerLinkActive="active" class="btn btn-square text-sm">
      <span class="btn-inner--icon d-block">
        <i class="fas fa-briefcase fa-2x"></i>
      </span>
      <span class="btn-inner--icon d-block pt-2">Opportunity Management</span>
    </a>
    <a [routerLink]="['/admin/complaints']" routerLinkActive="active" class="btn btn-square text-sm">
      <span class="btn-inner--icon d-block">
        <i class="fas fa-flag fa-2x"></i>
      </span>
      <span class="btn-inner--icon d-block pt-2">Reports & Complaints</span>
    </a>
    <!--
    <a [routerLink]="['/admin/trendsfore']" routerLinkActive="active" class="btn btn-square text-sm">
      <span class="btn-inner--icon d-block">
        <i class="fas fa-tenge fa-2x"></i>
      </span>
      <span class="btn-inner--icon d-block pt-2">Trendsfore Official</span>
    </a>
    -->
    <a [routerLink]="['/admin/databank']" routerLinkActive="active" class="btn btn-square text-sm">
      <span class="btn-inner--icon d-block">
        <i class="fas fa-database fa-2x "></i>
      </span>
      <span class="btn-inner--icon d-block pt-2">Trendsfore Data Bank</span>
    </a>
    <a [routerLink]="['/admin/pages']" routerLinkActive="active" class="btn btn-square text-sm">
      <span class="btn-inner--icon d-block">
        <i class="fas fa-pager fa-2x"></i>
      </span>
      <span class="btn-inner--icon d-block pt-2">Trendsfore Pages</span>
    </a>
    <a [routerLink]="['/admin/communities']" routerLinkActive="active" class="btn btn-square text-sm">
      <span class="btn-inner--icon d-block">
        <i class="fas fa-users fa-2x"></i>
      </span>
      <span class="btn-inner--icon d-block pt-2">Trendsfore Communities</span>
    </a>
    <a [routerLink]="['/admin/initiatives']" routerLinkActive="active" class="btn btn-square text-sm">
      <span class="btn-inner--icon d-block">
        <i class="fas fa-donate fa-2x"></i>
      </span>
      <span class="btn-inner--icon d-block pt-2">Trendsfore Initiatives</span>
    </a>
    <a [routerLink]="['/admin/keyword']" routerLinkActive="active" class="btn btn-square text-sm">
      <span class="btn-inner--icon d-block">
        <i class="fas fa-keyboard fa-2x"></i>
      </span>
      <span class="btn-inner--icon d-block pt-2">Keyword Management</span>
    </a>
    <!--
    <a [routerLink]="" routerLinkActive="-active" class="btn btn-square text-sm">
      <span class="btn-inner--icon d-block">
        <i class="fas fa-box fa-2x"></i>
      </span>
      <span class="btn-inner--icon d-block pt-2">Category Management</span>
    </a>
    -->
    <a [routerLink]="['/admin/videos']" routerLinkActive="active" class="btn btn-square text-sm">
      <span class="btn-inner--icon d-block">
        <i class="fas fa-video fa-2x"></i>
      </span>
      <span class="btn-inner--icon d-block pt-2">Live Video Management</span>
    </a>
  </div>
  <!-- Misc area -->
  <div class="card bg-gradient-info">
    <div class="card-body text-center text-white">
      <p>Trendsfore &copy; 2021-22</p>
    </div>
  </div>
</div>
