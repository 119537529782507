<app-aside [isLoadEnable]="this.isLoadEnable"></app-aside>
<div class="container-fluid container-application">
  <app-sidebar></app-sidebar>
  <!-- Content -->
  <div class="main-content position-relative">
    <app-header></app-header>
    <div class="page-content">

      <!-- APPLICANT MODAL -->
      <div class="modal fade" id="modal-application-user" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-body">
              <div class="card card-fluid">
                <div class="card-body text-center">
                  <div class="avatar-parent-child">
                    <a class="avatar avatar-lg rounded-circle">
                      <img alt="Image placeholder" src="{{this.baseUrl+this.ApplicantModel?.ApplicantImage }}">
                    </a>
                    <span class="avatar-child avatar-badge bg-primary"></span>
                  </div>
                  <h5 class="h6 mt-4 mb-0">{{ this.ApplicantModel?.ApplicantPhone }}</h5>
                  <a class="d-block text-sm text-muted mb-3">{{ this.ApplicantModel?.ApplicantEmail }}</a>
                  <div class="actions justify-content-between px-4 text-center">

                    <a class="btn btn-sm btn-primary rounded-pill mr-auto"
                      href="{{ this.baseUrl+ this.ApplicantModel?.ApplicantResume }}" download target="_blank">
                      <i class=" fa fa-file-download "></i>
                      Applicant Resume
                    </a>
                    <br>
                    <a [routerLink]="" (click)="this.deleteApplicant(this.ApplicantModel?.ApplicantId)"
                      class="mt-4 btn btn-sm btn-danger rounded-pill mr-auto text-light" data-dismiss="modal">
                      <i class="fa fa-trash"></i>
                      Delete Applicant
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-sm btn-link text-primary px-2" data-dismiss="modal">Cancel</button>
            </div>
          </div>
        </div>
      </div>

      <!-- APPLICATIONS -->
        <div class="modal fade" id="modal-users" tabindex="-1" role="dialog" aria-hidden="true">
          <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header align-items-center">
                <div class="modal-title">
                  <h6 class="mb-0">Opportunity Applications</h6>
                </div>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <!-- Connections -->
                <div class="card" *ngIf="this.ApplicationsModel?.length <= 0">
                  <img class="img-center img" src="../../../assets/img/errors/nodata-found.png" alt="NO DATA">
                </div>
                <div class="row" *ngIf="this.ApplicationsModel?.length >0">
                  <div class="col-6 col-md-3" *ngFor="let application of this.ApplicationsModel">
                    <div class="card">
                      <div class="card-body text-center">
                        <div class="avatar-parent-child">
                          <a class="avatar avatar-lg rounded-circle">
                            <img alt="Image placeholder" src="{{ this.baseUrl+ application.ApplicantImage }}">
                          </a>
                          <span class="avatar-child avatar-badge bg-info"></span>
                        </div>
                        <h5 class="text-sm my-3">{{application.ApplicantEmail }}</h5>

                        <a class="btn btn-xs btn-primary rounded-pill mr-auto"
                          href="{{ this.baseUrl+ application.ApplicantResume }}" download target="_blank">
                          <i class=" fa fa-file-download "></i>
                        </a>
                        <a [routerLink]="" (click)="this.deleteApplicant(application.ApplicantId)"
                          class="ml-2 btn btn-xs btn-danger rounded-pill mr-auto text-light" data-dismiss="modal">
                          <i class="fa fa-trash"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      <!--Create Opportunity-->
      <div class="modal fade docs-example-modal-xl" id="modal-project-create" tabindex="-1" role="dialog"
        aria-labelledby="myExtraLargeModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-xl">
          <form [formGroup]="OpportunityForm">
            <div class="modal-content">
              <div class="modal-body">
                <div class="row">
                  <div class="col-12">
                    <div *ngIf="this.isLoginOnSubmitErrorEnabled" class="alert alert-danger"
                      [innerHTML]="this.errorOnLoginSubmit">
                    </div>
                  </div>
                </div>

                <div class="row">
                  <!-- Opportunity Title -->
                  <div class="col-6 form-group">
                    <label class="form-control-label">
                      Opportunity Title
                    </label>
                    <input *ngIf="!this.isModalCreate" formControlName="OpportunityId" type="hidden"
                      class="form-control">
                    <input formControlName="OpportunityTitle" type="text" placeholder="Please enter opportunity title"
                      class="form-control">
                  </div>
                  <!-- Opportunity Title -->
                  <div class="col-6 form-group">
                    <label class="form-control-label">
                      Volunteer Level
                    </label>
                    <select formControlName="LevelId" class="form-control">
                      <option selected value="" disabled>Please select Volunteer level</option>
                      <optgroup *ngFor="let option of this.OptionLevel" label="{{ option.OptionName }}">
                        <option *ngFor="let level of option.Levels" value="{{ level.LevelId }}">{{ level.LevelName }}
                        </option>
                      </optgroup>
                    </select>
                  </div>
                </div>

                <div class="row">
                  <!-- Company Name -->
                  <div class=" col-6 form-group">
                    <label class="form-control-label mb-0">
                      Company Name
                    </label>
                    <input formControlName="OpportunityCompanyName" type="text" placeholder="Please enter company name"
                      class="form-control">
                  </div>
                  <!-- Opportunity Industry -->
                  <div class="col-6 form-group">
                    <label class="form-control-label">
                      Opportunity Industry
                    </label>
                    <select formControlName="Industry" class="form-control">
                      <option selected value="" disabled>Please select the target industry</option>
                      <option *ngFor="let industry of this.Categories" value="{{ industry.CategoryName }}">
                        {{ industry.CategoryName }}
                      </option>
                    </select>
                  </div>
                </div>
                <!-- Opportunity Location -->
                <div class="form-group">
                  <label class="form-control-label">
                    Opportunity Location
                  </label>
                  <select formControlName="Location" class="form-control">
                    <option selected value="" disabled>Please select the target location</option>
                    <option *ngFor="let country of this.UtilModel?.location" value="{{ country }}">
                      {{ country }}
                    </option>
                  </select>
                </div>
                <!-- Opportunity Description -->
                <div class="form-group">
                  <label class="form-control-label">
                    Opportunity Description
                  </label>
                  <textarea formControlName="Description" class="form-control"
                    placeholder="Tell them a few words about this opportunity" rows="3"></textarea>
                </div>
                <!-- Opportunity Image -->
                <div class="form-group">
                  <label class="form-control-label">
                    Opportunity Image
                  </label>

                  <div class="row text-center" *ngIf="!this.isModalCreate">
                    <a class="avatar rounded-circle avatar-xl hover-translate-y-n3">
                      <img class="ml-lg-5 img img-center" alt="Image placeholder" src="{{ this.OpportunityImage }}">
                    </a>
                  </div>
                  <input formControlName="Image" type="file" (change)="uploadFile($event)" accept=".png, .jpg, .jpeg"
                    class="form-control">
                </div>
              </div>
              <div class="modal-footer">
                <button *ngIf="this.isModalCreate" type="button" (click)="create()"
                  class="btn btn-sm btn-primary rounded-pill mr-auto">Save</button>

                <button *ngIf="!this.isModalCreate" type="button" (click)="update()"
                  class="btn btn-sm btn-primary rounded-pill mr-auto">Update</button>
                <button type="button" class="btn btn-sm btn-link text-danger px-2" data-dismiss="modal">Cancel</button>
              </div>
            </div>
          </form>

        </div>
      </div>




      <div class="card" *ngIf="this.OpportunityModel?.length <= 0">
        <img class="img-center img" src="../../../assets/img/errors/nodata-found.png" alt="NO DATA">
      </div>
      <div *ngIf="this.OpportunityModel?.length > 0">
        <!-- Page title -->
        <div class="page-title">
          <div class="row justify-content-between align-items-center">
            <div
              class="col-md-6 d-flex align-items-center justify-content-between justify-content-md-start mb-3 mb-md-0">
              <!-- Page title + Go Back button -->
              <div class="d-inline-block">
                <h5 class="h4 d-inline-block font-weight-400 mb-0 text-white">Trendsfore Opportunities</h5>
              </div>
              <!-- Additional info -->
              <div class="align-items-center ml-4 d-inline-flex">
                <span class="h4 text-info mb-0 mr-2">{{ this.OpportunityModel?.length }}</span>
                <span class="text-sm opacity-7 text-white">Total Opportunities</span>
              </div>
            </div>
            <div class="col-md-6 d-flex align-items-center justify-content-between justify-content-md-end">
              <!-- Filter input -->
              <form class="bg-neutral rounded-pill d-inline-block">
                <div class="input-group input-group-sm input-group-merge input-group-flush">
                  <div class="input-group-prepend">
                    <span class="input-group-text bg-transparent"><i class="fas fa-filter"></i></span>
                  </div>
                  <input type="text" (keyup)="search($event.target.value)" class="form-control form-control-flush"
                    placeholder="Filter results">
                </div>
              </form>
              <a [routerLink]="" (click)="this.refresh()"
                class="btn btn-sm btn-white btn-icon-only rounded-circle ml-3">
                <span class="btn-inner--icon"><i class="fas fa-sync"></i></span>
              </a>
              <a href="#modal-project-create" class="btn btn-sm btn-white btn-icon-only rounded-circle ml-2"
                data-toggle="modal" (click)="this.save()">
                <span class="btn-inner--icon"><i class="fas fa-plus"></i></span>
              </a>
            </div>
          </div>
        </div>

        <!-- Project cards -->
        <div class="row">


          <div class="col-xl-3 col-lg-4 col-sm-6" *ngFor="let opportunity of this.OpportunityModel">
            <div class="card hover-shadow-lg">
              <div class="card-body text-center">

                <a class="avatar rounded-circle avatar-lg hover-translate-y-n3">
                  <img alt="Image placeholder" src="{{ this.baseUrl+opportunity.Image }}" class="">
                </a>
                <h4 class="my-2">
                  <a>{{ opportunity.Title }}</a>
                </h4>
                <h6 class="text-muted">
                  <a>{{ opportunity.Location }}</a>
                </h6>

                <h6 class="mt-3">
                  <a>Applications</a>
                </h6>
                <div class="avatar-group mb-3" *ngIf="opportunity.Applications?.length > 0">

                  <a href="#modal-application-user" data-toggle="modal"
                    *ngFor="let applicant of opportunity.Applications" class="avatar rounded-circle avatar-sm">
                    <img alt="Image placeholder" (click)="applicationUser(applicant)"
                      src="{{ this.baseUrl+applicant.ApplicantImage}}" class="">
                  </a>
                </div>

                <div *ngIf="opportunity.Applications?.length <=0" class="mt-4">
                  <span class="badge badge-pill badge-primary badge-lg">No Applications</span>
                </div>
                <span class="clearfix"></span>

              </div>
              <div class="card-footer">
                <div class="actions d-flex justify-content-between px-4">
                  <a href="#modal-project-create" class="action-item text-primary" data-toggle="modal"
                    (click)="this.edit(opportunity)">
                    <i class="fas fa-edit"></i>
                  </a>
                  <a href="#modal-users" data-toggle="modal" (click)="this.setApplications(opportunity.Applications)" class="action-item text-primary">
                    <i class="fas fa-address-book"></i>
                  </a>
                  <a (click)="this.delete(opportunity.OpportunityId)" class="action-item text-danger">
                    <i class="fas fa-trash-alt"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-3 col-sm-6">
            <div class="card card-fluid card-placeholder align-items-center justify-content-center">
              <div class="text-center">
                <a [routerLink]="" (click)="this.loadMore()" *ngIf="this.isLoadMoreVisible" class="stretched-link">
                  <i class="fas fa-plus fa-2x text-muted"></i>
                  <span class="h6 text-muted d-block mt-3">More Opportunities</span>
                </a>
                <a [routerLink]="" (click)="this.refresh()" *ngIf="!this.isLoadMoreVisible" class="stretched-link">
                  <i class="fas fa-sync fa-2x text-muted"></i>
                  <span class="h6 text-muted d-block mt-3">Refresh</span>
                </a>
              </div>
            </div>
          </div>

        </div>

      </div>

    </div>
    <app-footer></app-footer>
  </div>
</div>
