<app-aside [isLoadEnable]="this.isLoadEnable"></app-aside>
<div class="container-fluid container-application">
  <app-sidebar></app-sidebar>
    <!-- Content -->
    <div class="main-content position-relative">
      <app-header></app-header>

    <!-- Page content -->
      <div class="page-content">
      <!-- Page title -->
      <div class="page-title">
        <div class="row justify-content-between align-items-center">
          <div class="col-md-6 mb-3 mb-md-0">
            <h5 class="h3 font-weight-400 mb-0 text-white">Trendsfore Databank</h5>
          </div>

          <div class="col-md-6 d-flex align-items-center justify-content-between justify-content-md-end">
            <!-- Filter input -->
            <form class="bg-neutral rounded-pill d-inline-block">
              <div class="input-group input-group-sm input-group-merge input-group-flush">
                <div class="input-group-prepend">
                  <span class="input-group-text bg-transparent"><i class="fas fa-filter"></i></span>
                </div>
                <input type="text" (keyup)="search($event.target.value)" class="form-control form-control-flush"
                  placeholder="Enter a word">
              </div>
            </form>
            <a [routerLink]="" class="btn btn-sm btn-white btn-icon-only rounded-circle ml-4">
              <span class="btn-inner--icon"><i class="fas fa-sync"></i></span>
            </a>
          </div>
        </div>

      </div>
      <div class="card" *ngIf="!this.DataBankModel?.Flag">
        <img class="img-center img" src="../../../assets/img/errors/nodata-found.png" alt="NO DATA">
      </div>
      <!--CHART-->
        <div class="row" *ngIf="this.DataBankModel?.Flag">
          <div class="col-xl-12">
            <div class="card shadow-dark-sm card-fluid">
              <div class="card-header">
                <h6 class="mb-0">Questions & Answers Via "{{this.searchTxt}}"</h6>
              </div>
              <div class="card-body">
                <!-- Chart -->
                <apx-chart [series]="this.CountryChartOptions.series" [chart]="this.CountryChartOptions.chart"
                  [xaxis]="this.CountryChartOptions.xaxis" [title]="this.CountryChartOptions.title">
                </apx-chart>
              </div>
            </div>
          </div>
        </div>
      </div>
      <app-footer></app-footer>
    </div>
  </div>
