import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { MustMatch } from '../../../../Validators/mustmatch.validator';
import { TrendsforeService } from '../../../../services/trendsfore.service';
import { adminProfile } from '../../../../models/admin/login';
@Component({
  selector: 'app-setting',
  templateUrl: './setting.component.html',
  styleUrls: ['./setting.component.css']
})
export class SettingComponent implements OnInit {

  changePasswordForm: FormGroup;
  changePasswordModel: adminProfile.ChangePassword
  errorOnLoginSubmit: String;
  isLoginOnSubmitErrorEnabled: boolean;
  isLoadEnable: boolean;

  constructor(private formBuilder: FormBuilder, private trendsfore: TrendsforeService) { }

  ngOnInit(): void {
    if (this.trendsfore.VerifyToken()) {
      this.isLoadEnable = true;
      setTimeout(() => {
        this.isLoadEnable = false;
      }, 1000);
      this.changePasswordForm = this.formBuilder.group({
        CurrentPassword: new FormControl('', [
          Validators.required,
          Validators.minLength(8),
          Validators.maxLength(30)
        ]),
        NewPassword: new FormControl('', [
          Validators.required,
          Validators.minLength(8),
          Validators.maxLength(30)
        ]),
        ConfirmPassword: new FormControl('', [
          Validators.required,
        ])
      },
        {
          validator: MustMatch('NewPassword', 'ConfirmPassword')
        }
      );
    }
  }

  changePassword() {
    if (this.changePasswordForm.valid) {
      this.changePasswordModel = new adminProfile.ChangePassword();
      this.changePasswordModel.CurrentPassword = this.changePasswordForm.get('CurrentPassword').value;
      this.changePasswordModel.NewPassword = this.changePasswordForm.get('NewPassword').value;
      this.changePasswordModel.ConfirmPassword = this.changePasswordForm.get('ConfirmPassword').value;

      this.trendsfore.changePassword(this.changePasswordModel).subscribe(
        response => {
          if (response.Flag) {
            this.trendsfore.success("You've changed your password successfully", "Change Password");
          } else {
            this.changePasswordForm.reset();
            this.enableError(response.Message);
          }
        }
      );

    } else {
      this.enableError("Incomplete change password request");
    }
  }

  private enableError(error: string): void {
    this.errorOnLoginSubmit = error;
    this.isLoginOnSubmitErrorEnabled = true;
    setTimeout(() => {
      this.isLoginOnSubmitErrorEnabled = false;
    }, 5000);
  }

}
