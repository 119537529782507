import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TrendsforeService } from '../../../services/trendsfore.service';
import { adminLoginScreen } from '../../../models/admin/login';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  baseUrl: String;
  DataModel: adminLoginScreen.User;

  @Input() UserModel: adminLoginScreen.User;

  constructor(private router: Router, private trendsfore: TrendsforeService, private toastr: ToastrService,) { }

  ngOnInit(): void {
    this.baseUrl = this.trendsfore.imageUrl();
    this.DataModel = this.trendsfore.getData()['User'];
  }
  logout() {
    this.trendsfore.adminLogout().subscribe(
      data => {
        if (data.Flag) {
          this.trendsfore.deleteToken();
          this.trendsfore.logout();
        } else {
          console.log(data.Message);
        }
      },
      error => {
        console.log(error);
      }
    );
  }
}
