import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TrendsforeService } from '../../../../services/trendsfore.service';
import { userManagement } from '../../../../models/admin/login';
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.css']
})
export class UserProfileComponent implements OnInit {

  UserProfile: userManagement.UserProfile;
  volunteerLevels: userManagement.VolunteerLevels;
  baseUrl: String;
  errorOnLoginSubmit: String;
  isLoginOnSubmitErrorEnabled: boolean;
  isLoadEnable: boolean;
  baseId: number;
  volunteerForm: FormGroup;
  private userVolunteerLevel: string;

  constructor(private formBuilder: FormBuilder, private activatedRoute: ActivatedRoute, private trendsfore: TrendsforeService,) { }

  ngOnInit(): void {
    if (this.trendsfore.VerifyToken()) {
      this.baseUrl = this.trendsfore.imageUrl();
      this.baseId = Number(this.activatedRoute.snapshot.paramMap.get('id'));
      if (isNaN(this.baseId)) {
        this.trendsfore.redirection('/admin/dashboard');
        this.trendsfore.error('Access denied', 'User Profile');
      } else {
        this.isLoadEnable = true;
        this.getBaseUser();
        this.volunteerForm = new FormGroup({
          LevelId: new FormControl('', [
            Validators.required,
          ])
        });
        this.volunteerForm.patchValue({ UserId: this.baseId });
      }
    }
    /*
    this.activatedRoute.snapshot.paramMap.get('id');
    this.id = this.activatedRoute.snapshot.paramMap.get("id");
    this.activatedRoute.paramMap.subscribe(params => {
      this.id = params.get('id');
    });
    */

  }

  submitVolunteer() {
    if (this.volunteerForm.valid) {
      const level = Number(this.volunteerForm.get('LevelId').value);
      this.trendsfore.updateUserVolunteerLevel(this.baseId, level).subscribe(
        data => {
          if (data.Flag) {
            this.trendsfore.success("You've updated volunteer successfully", "Digital Volunteer");
            this.UserProfile.Data.UserPersonal.LevelId = level;
            this.UserProfile.Data.UserPersonal.Level = this.userVolunteerLevel;
          } else {
            this.trendsfore.error(data.Message, "Digital Volunteer");
          }
        }
      );

    } else {
      if (this.volunteerForm.get('LevelId').errors.required) {
        this.enableError("You need to select a level");
      } else {
        this.enableError("Something went wrong");
      }
    }
  }
  getSelectedOptionText(event: Event) {
    let selectedOptions = event.target['options'];
    let selectedIndex = selectedOptions.selectedIndex;
    this.userVolunteerLevel = selectedOptions[selectedIndex].text;
    console.log(this.userVolunteerLevel);
  }

  private getVolunteerLevels() {
    this.trendsfore.userVolunteerLevels(this.UserProfile.Data.UserPersonal.OptionId, this.UserProfile.Data.UserPersonal.LevelId).subscribe(
      (data: userManagement.VolunteerLevels) => {
        if (data.Flag) {
          this.volunteerLevels = data;
        }
      }
    )
  }

  private getBaseUser() {
    this.trendsfore.userProfile(this.baseId).subscribe((data: userManagement.UserProfile) => {
      if (data.Flag) {
        this.isLoadEnable = false;
        this.UserProfile = data;
        this.getVolunteerLevels();
      } else {
        this.trendsfore.redirection('/admin/dashboard');
        this.trendsfore.error('Access denied', 'User Profile');
      }
    });

  }

  private enableError(error: string): void {
    this.errorOnLoginSubmit = error;
    this.isLoginOnSubmitErrorEnabled = true;
    setTimeout(() => {
      this.isLoginOnSubmitErrorEnabled = false;
    }, 5000);
  }

}
