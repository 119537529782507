import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-trendsfore',
  templateUrl: './trendsfore.component.html',
  styleUrls: ['./trendsfore.component.css'],
})
export class TrendsforeComponent implements OnInit {
  pageNo: number;
  baseUrl: String;
  isLoadEnable: boolean;
  isLoadMoreVisible: boolean;
  isLoginOnSubmitErrorEnabled: boolean;
  subQuestionOptions: string;
  constructor() {}

  ngOnInit(): void {
    this.subQuestionOptions="<div class='col-6 form-group'><div class='d-flex align-items-center justify-content-between justify-content-md-end'><input type='text' placeholder='Please enter question option' class='form-control'><button type='button' class='btn btn-light'><i class='fas fa-times'></i></button></div></div>";
  }
}
