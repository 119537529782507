import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { TrendsforeService } from '../../../../services/trendsfore.service';
import { keywordManagement } from '../../../../models/admin/login';
@Component({
  selector: 'app-keyword',
  templateUrl: './keyword.component.html',
  styleUrls: ['./keyword.component.css']
})
export class KeywordComponent implements OnInit {

  Keywords: keywordManagement.Keyword[];
  bannedUser: keywordManagement.BannedUser;
  baseUrl: String;
  isKeywordDeleteUpdateCreate: Number;
  keywordForm: FormGroup;
  errorOnLoginSubmit: String;
  isLoginOnSubmitErrorEnabled: boolean;
  isReadOnly: boolean;
  pageNo: number;
  isLoadMoreVisible: boolean;
  isLoadEnable: boolean;

  constructor(private trendsfore: TrendsforeService, private formBuilder: FormBuilder,) { }

  ngOnInit(): void {
    if (this.trendsfore.VerifyToken()) {
      this.isLoadMoreVisible = true;
      this.isLoadEnable = true;
      this.pageNo = 1;
      this.getKeywords(this.pageNo.toString());
      this.baseUrl = this.trendsfore.imageUrl();
      this.keywordForm = this.formBuilder.group({
        Keyword: new FormControl('', Validators.required),
        KeywordId: new FormControl('')
      });
    }
  }

  saveKeyword() {
    if (this.keywordForm.valid) {
      const SaveCreateKeyword = new keywordManagement.SaveKeyword();
      SaveCreateKeyword.Keyword = this.keywordForm.get('Keyword').value;
      this.trendsfore.addKeyword(SaveCreateKeyword).subscribe(
        (data: keywordManagement.KeywordResponse) => {
          if (data.Flag) {
            this.trendsfore.success('New banned keyword added', 'Keyword Management');
            this.keywordForm.reset();
            this.refresh();
          } else {
            this.trendsfore.error(data.Message, 'Keyword Management');
          }
        }
      );
    } else {
      if (this.keywordForm.get('Keyword').errors.required) {
        this.enableError("Keyword is required.");
      }
    }
  }

  updateKeyword() {
    if (this.keywordForm.valid) {
      const UpdateKeyword = new keywordManagement.UpdateKeyword();
      UpdateKeyword.Keyword = this.keywordForm.get('Keyword').value;
      UpdateKeyword.KeywordId = this.keywordForm.get('KeywordId').value;
      this.trendsfore.updateKeyword(UpdateKeyword, UpdateKeyword.KeywordId).subscribe(
        (data: keywordManagement.KeywordResponse) => {
          if (data.Flag) {
            this.trendsfore.success('Keyword has been updated', 'Keyword Management');
            this.refresh();
          } else {
            this.trendsfore.error(data.Message, 'Keyword Management');
          }
        }
      );
    } else {
      if (this.keywordForm.get('Keyword').errors.required) {
        this.enableError("Keyword is required.");
      }
    }
  }

  deleteKeyword() {
    if (this.keywordForm.valid) {
      const DeleteKeyword = new keywordManagement.DeleteKeyword();
      DeleteKeyword.KeywordId = this.keywordForm.get('KeywordId').value;
      this.trendsfore.deleteKeyword(DeleteKeyword.KeywordId).subscribe(
        (data: keywordManagement.KeywordResponse) => {
          if (data.Flag) {
            this.trendsfore.success('Keyword has been deleted', 'Keyword Management');
            this.refresh();
          } else {
            this.trendsfore.error(data.Message, 'Keyword Management');
          }
        }
      );
    }
  }

  unbanUser(id: number) {
    this.trendsfore.unbanUser(id).subscribe(
      data => {
        if (data.Flag) {
          this.trendsfore.success('User has been unbanned', 'Keyword Management');
          this.refresh();
        } else {
          this.trendsfore.error(data.Message, 'Keyword Management');
        }
      }
    );
  }


  refresh() {
    this.pageNo = 1;
    this.isLoadEnable = true;
    this.getKeywords(this.pageNo.toString());
    this.isLoadMoreVisible = true;
  }

  loadMore() {
    this.pageNo += 1;
    this.isLoadEnable = true;
    this.getKeywords(this.pageNo.toString());
  }

  create() {
    this.isKeywordDeleteUpdateCreate = 1;
    this.keywordForm.reset();
    this.isReadOnly = false;
  }

  search(value: string) {
    if (value !== "") {
      this.isLoadMoreVisible = false;
      this.trendsfore.searchKeyword(value).subscribe(
        (data: keywordManagement.KeywordRoot) => {
          if (data.Flag) {
            this.Keywords = data.Data.Keyword;
          }
        }
      );
    } else {
      this.refresh()
    }
  }

  userModal(model: keywordManagement.BannedUser) {
    this.bannedUser = model;
  }

  edit(keyword: string, id: number) {
    this.isKeywordDeleteUpdateCreate = 2;
    this.isReadOnly = false;
    this.keywordForm.patchValue({ Keyword: keyword });
    this.keywordForm.patchValue({ KeywordId: id });
  }

  deletes(keyword: string, id: number) {
    this.isKeywordDeleteUpdateCreate = 3;
    this.isReadOnly = true;
    this.keywordForm.patchValue({ Keyword: keyword });
    this.keywordForm.patchValue({ KeywordId: id });
  }

  private getKeywords(page: string) {
    this.trendsfore.keywords(page).subscribe(
      (data: keywordManagement.KeywordRoot) => {
        if (data.Flag) {
          if (page == '1') {
            this.Keywords = data.Data.Keyword;
            this.isLoadEnable = false;
          } else {
            this.Keywords = this.Keywords.concat(data.Data.Keyword);
            this.isLoadEnable = false;
          }
        } else {
          this.isLoadMoreVisible = false;
          this.isLoadEnable = false;
        }
      }
    );
  }

  private enableError(error: string): void {
    this.errorOnLoginSubmit = error;
    this.isLoginOnSubmitErrorEnabled = true;
    setTimeout(() => {
      this.isLoginOnSubmitErrorEnabled = false;
    }, 3000);
  }

}
