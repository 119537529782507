import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
  HttpParams,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { AppUtil } from '../app.util';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import {
  LocalStorageService,
  SessionStorageService,
  LocalStorage,
  SessionStorage,
} from 'angular-web-storage';
@Injectable({
  providedIn: 'root',
})
export class TrendsforeService {
  private httpOptions: any;
  private CompleteUrl: string;
  constructor(
    private httpClient: HttpClient,
    private local: SessionStorageService,
    private router: Router,
    private toastr: ToastrService
  ) {
    //localStorage.setItem('Z4YE048SZZ3KSH323DL53A', '107|zyngCye4KzlZmiHjBDzSY3pqejlDmaJPV2lKVlIh');
    //this.authorizationToken = localStorage.getItem('Z4YE048SZZ3KSH323DL53A');
    //this.authorizationToken = "107|zyngCye4KzlZmiHjBDzSY3pqejlDmaJPV2lKVlIh";
    // this.baseUrl = "https://api.smartwaytechnasdasdologies.com/api";
  }

  /*APIS*/

  //GENERAL ERROR HANDLING
  handleError(error: HttpErrorResponse) {
    let errorMessage = 'Unknown error!';
    if (error.error instanceof ErrorEvent) {
      errorMessage = `Error: ${error.error.message}`;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    window.alert(errorMessage);
    return throwError(errorMessage);
  }
  //GENERAL ERROR HANDLING

  //USER AUTH

  adminLogin(model?: any): Observable<any> {
    this.CompleteUrl = AppUtil.ADMIN_API_ENDPOINT + '/admin/login';
    return this.httpClient
      .post(this.CompleteUrl, model)
      .pipe(catchError(this.handleError));
  }


  adminLogout(): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders().set(
        'Authorization',
        'Bearer ' + this.getToken()
      ),
    };
    this.CompleteUrl = AppUtil.ADMIN_API_ENDPOINT + '/admin/logout';
    return this.httpClient
      .delete(this.CompleteUrl, this.httpOptions)
      .pipe(catchError(this.handleError));
  }
  adminAllLogout(): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders().set(
        'Authorization',
        'Bearer ' + this.getToken()
      ),
    };
    this.CompleteUrl = AppUtil.ADMIN_API_ENDPOINT + '/admin/logout-all';
    return this.httpClient
      .delete(this.CompleteUrl, this.httpOptions)
      .pipe(catchError(this.handleError));
  }
  //USER AUTH

  //USER PROFILE & SETTINGS
  updateProfile(model?: any): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders().set(
        'Authorization',
        'Bearer ' + this.getToken()
      ),
    };
    this.CompleteUrl = AppUtil.ADMIN_API_ENDPOINT + '/admin/update-profile';
    return this.httpClient
      .post(this.CompleteUrl, model, this.httpOptions)
      .pipe(catchError(this.handleError));
  }
  changePassword(model?: any): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders().set(
        'Authorization',
        'Bearer ' + this.getToken()
      ),
    };
    this.CompleteUrl = AppUtil.ADMIN_API_ENDPOINT + '/admin/change-password';
    return this.httpClient
      .put(this.CompleteUrl, model, this.httpOptions)
      .pipe(catchError(this.handleError));
  }
  //USER PROFILE & SETTINGS

  //KEYWORD MANAGEMENT
  keywords(page: string): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders().set(
        'Authorization',
        'Bearer ' + this.getToken()
      ),
      params: new HttpParams().set('page', page),
    };
    this.CompleteUrl = AppUtil.ADMIN_API_ENDPOINT + '/keyword';
    return this.httpClient
      .get(this.CompleteUrl, this.httpOptions)
      .pipe(catchError(this.handleError));
  }
  searchKeyword(query: string): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders().set(
        'Authorization',
        'Bearer ' + this.getToken()
      ),
    };
    this.CompleteUrl = AppUtil.ADMIN_API_ENDPOINT + '/keyword/' + query;
    return this.httpClient
      .get(this.CompleteUrl, this.httpOptions)
      .pipe(catchError(this.handleError));
  }
  addKeyword(model?: any): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders().set(
        'Authorization',
        'Bearer ' + this.getToken()
      ),
    };
    this.CompleteUrl = AppUtil.ADMIN_API_ENDPOINT + '/keyword';
    return this.httpClient
      .post(this.CompleteUrl, model, this.httpOptions)
      .pipe(catchError(this.handleError));
  }
  updateKeyword(model?: any, id?: number): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders().set(
        'Authorization',
        'Bearer ' + this.getToken()
      ),
    };
    this.CompleteUrl = AppUtil.ADMIN_API_ENDPOINT + '/keyword/' + id;
    return this.httpClient
      .put(this.CompleteUrl, model, this.httpOptions)
      .pipe(catchError(this.handleError));
  }
  deleteKeyword(id?: number): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders().set(
        'Authorization',
        'Bearer ' + this.getToken()
      ),
    };
    this.CompleteUrl = AppUtil.ADMIN_API_ENDPOINT + '/keyword/' + id;
    return this.httpClient
      .delete(this.CompleteUrl, this.httpOptions)
      .pipe(catchError(this.handleError));
  }

  unbanUser(id?: number): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders().set(
        'Authorization',
        'Bearer ' + this.getToken()
      ),
    };
    this.CompleteUrl = AppUtil.ADMIN_API_ENDPOINT + '/keyword/unbanned/' + id;
    return this.httpClient
      .delete(this.CompleteUrl, this.httpOptions)
      .pipe(catchError(this.handleError));
  }
  //KEYWORD MANAGEMENT

  //USER MANAGEMENT

  users(page: string): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders().set(
        'Authorization',
        'Bearer ' + this.getToken()
      ),
      params: new HttpParams().set('page', page),
    };
    this.CompleteUrl = AppUtil.ADMIN_API_ENDPOINT + '/admin/users';
    return this.httpClient
      .get(this.CompleteUrl, this.httpOptions)
      .pipe(catchError(this.handleError));
  }

  deleteUser(id?: number): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders().set(
        'Authorization',
        'Bearer ' + this.getToken()
      ),
    };
    this.CompleteUrl = AppUtil.ADMIN_API_ENDPOINT + '/admin/users/delete/' + id;
    return this.httpClient
      .delete(this.CompleteUrl, this.httpOptions)
      .pipe(catchError(this.handleError));
  }

  searchedUsers(txt: string): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders().set(
        'Authorization',
        'Bearer ' + this.getToken()
      ),
    };
    this.CompleteUrl = AppUtil.ADMIN_API_ENDPOINT + '/admin/users/' + txt;
    return this.httpClient
      .get(this.CompleteUrl, this.httpOptions)
      .pipe(catchError(this.handleError));
  }
  userProfile(id: number): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders().set(
        'Authorization',
        'Bearer ' + this.getToken()
      ),
    };
    this.CompleteUrl = AppUtil.ADMIN_API_ENDPOINT + '/admin/user/' + id;
    return this.httpClient
      .get(this.CompleteUrl, this.httpOptions)
      .pipe(catchError(this.handleError));
  }

  userVolunteerLevels(OptionId: number, LevelId: number): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders().set(
        'Authorization',
        'Bearer ' + this.getToken()
      ),
    };
    this.CompleteUrl =
      AppUtil.ADMIN_API_ENDPOINT +
      '/admin/volunteer-levels/' +
      OptionId +
      '/' +
      LevelId;
    return this.httpClient
      .get(this.CompleteUrl, this.httpOptions)
      .pipe(catchError(this.handleError));
  }

  updateUserVolunteerLevel(UserId: number, LevelId: number): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders().set(
        'Authorization',
        'Bearer ' + this.getToken()
      ),
    };
    this.CompleteUrl =
      AppUtil.ADMIN_API_ENDPOINT +
      '/admin/upgrade-volunteer/' +
      UserId +
      '/' +
      LevelId;
    //console.log(this.CompleteUrl + "  AUTH:" + this.getToken());
    return this.httpClient
      .put(this.CompleteUrl, null, this.httpOptions)
      .pipe(catchError(this.handleError));
  }

  //USER MANAGEMENT

  //VOLUNTEER LEVEL MANAGEMENT
  userVolunteerOptionLevels(): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders().set(
        'Authorization',
        'Bearer ' + this.getToken()
      ),
    };
    this.CompleteUrl = AppUtil.ADMIN_API_ENDPOINT + '/admin/options';
    return this.httpClient
      .get(this.CompleteUrl, this.httpOptions)
      .pipe(catchError(this.handleError));
  }
  addLevel(model?: any): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders().set(
        'Authorization',
        'Bearer ' + this.getToken()
      ),
    };
    this.CompleteUrl = AppUtil.ADMIN_API_ENDPOINT + '/admin/add-level';
    return this.httpClient
      .post(this.CompleteUrl, model, this.httpOptions)
      .pipe(catchError(this.handleError));
  }

  updateLevel(model?: any): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders().set(
        'Authorization',
        'Bearer ' + this.getToken()
      ),
    };
    this.CompleteUrl = AppUtil.ADMIN_API_ENDPOINT + '/admin/update-level';
    return this.httpClient
      .put(this.CompleteUrl, model, this.httpOptions)
      .pipe(catchError(this.handleError));
  }
  //VOLUNTEER LEVEL MANAGEMENT

  //HOME ANALYTICS
  homeAnalytics(): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders().set(
        'Authorization',
        'Bearer ' + this.getToken()
      ),
    };
    this.CompleteUrl = AppUtil.ADMIN_API_ENDPOINT + '/admin/dashboard';
    return this.httpClient
      .get(this.CompleteUrl, this.httpOptions)
      .pipe(catchError(this.handleError));
  }

  donationHistory(page: string): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders().set(
        'Authorization',
        'Bearer ' + this.getToken()
      ),
      params: new HttpParams().set('page', page),
    };
    this.CompleteUrl = AppUtil.ADMIN_API_ENDPOINT + '/admin/donations';
    return this.httpClient
      .get(this.CompleteUrl, this.httpOptions)
      .pipe(catchError(this.handleError));
  }
  //HOME ANALYTICS

  //COMPLAINTS
  complaintList(page: string): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders().set(
        'Authorization',
        'Bearer ' + this.getToken()
      ),
      params: new HttpParams().set('page', page),
    };
    this.CompleteUrl = AppUtil.ADMIN_API_ENDPOINT + '/admin/complaints';
    return this.httpClient
      .get(this.CompleteUrl, this.httpOptions)
      .pipe(catchError(this.handleError));
  }

  resolvedComplaint(Id: number): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders().set(
        'Authorization',
        'Bearer ' + this.getToken()
      ),
    };
    this.CompleteUrl =
      AppUtil.ADMIN_API_ENDPOINT + '/admin/resolved-complaint/' + Id;
    return this.httpClient
      .put(this.CompleteUrl, null, this.httpOptions)
      .pipe(catchError(this.handleError));
  }
  searchedComplaints(txt: string): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders().set(
        'Authorization',
        'Bearer ' + this.getToken()
      ),
    };
    this.CompleteUrl = AppUtil.ADMIN_API_ENDPOINT + '/admin/complaints/' + txt;
    return this.httpClient
      .get(this.CompleteUrl, this.httpOptions)
      .pipe(catchError(this.handleError));
  }
  //COMPLAINTS

  //OPPORTUNITIES
  opportunities(page: string): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders().set(
        'Authorization',
        'Bearer ' + this.getToken()
      ),
      params: new HttpParams().set('page', page),
    };
    this.CompleteUrl = AppUtil.ADMIN_API_ENDPOINT + '/admin/opportunities';
    return this.httpClient
      .get(this.CompleteUrl, this.httpOptions)
      .pipe(catchError(this.handleError));
  }
  searchedopportunities(txt: string): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders().set(
        'Authorization',
        'Bearer ' + this.getToken()
      ),
    };
    this.CompleteUrl =
      AppUtil.ADMIN_API_ENDPOINT + '/admin/opportunities/' + txt;
    return this.httpClient
      .get(this.CompleteUrl, this.httpOptions)
      .pipe(catchError(this.handleError));
  }

  categories(): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders().set(
        'Authorization',
        'Bearer ' + this.getToken()
      ),
    };
    this.CompleteUrl =
      AppUtil.ADMIN_API_ENDPOINT + '/admin/opportunity-industry';
    return this.httpClient
      .get(this.CompleteUrl, this.httpOptions)
      .pipe(catchError(this.handleError));
  }
  deleteOpportunity(id?: number): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders().set(
        'Authorization',
        'Bearer ' + this.getToken()
      ),
    };
    this.CompleteUrl =
      AppUtil.ADMIN_API_ENDPOINT + '/admin/opportunities/' + id;
    return this.httpClient
      .delete(this.CompleteUrl, this.httpOptions)
      .pipe(catchError(this.handleError));
  }

  deleteApplicant(id?: number): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders().set(
        'Authorization',
        'Bearer ' + this.getToken()
      ),
    };
    this.CompleteUrl =
      AppUtil.ADMIN_API_ENDPOINT + '/admin/applicants/delete/' + id;
    return this.httpClient
      .delete(this.CompleteUrl, this.httpOptions)
      .pipe(catchError(this.handleError));
  }

  addOpportunity(model?: any): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders().set(
        'Authorization',
        'Bearer ' + this.getToken()
      ),
    };
    this.CompleteUrl = AppUtil.ADMIN_API_ENDPOINT + '/admin/add-opportunity';
    return this.httpClient
      .post(this.CompleteUrl, model, this.httpOptions)
      .pipe(catchError(this.handleError));
  }

  updateOpportunity(model?: any): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders().set(
        'Authorization',
        'Bearer ' + this.getToken()
      ),
    };
    this.CompleteUrl = AppUtil.ADMIN_API_ENDPOINT + '/admin/update-opportunity';
    return this.httpClient
      .post(this.CompleteUrl, model, this.httpOptions)
      .pipe(catchError(this.handleError));
  }
  //OPPORTUNITIES

  //COMMUNTY
  communities(page: string): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders().set(
        'Authorization',
        'Bearer ' + this.getToken()
      ),
      params: new HttpParams().set('page', page),
    };
    this.CompleteUrl = AppUtil.ADMIN_API_ENDPOINT + '/admin/communities';
    return this.httpClient
      .get(this.CompleteUrl, this.httpOptions)
      .pipe(catchError(this.handleError));
  }
  searchCommunities(txt: string): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders().set(
        'Authorization',
        'Bearer ' + this.getToken()
      ),
    };
    this.CompleteUrl =
      AppUtil.ADMIN_API_ENDPOINT + '/admin/communities/search/' + txt;
    return this.httpClient
      .get(this.CompleteUrl, this.httpOptions)
      .pipe(catchError(this.handleError));
  }

  upgradeCommunity(Id: number): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders().set(
        'Authorization',
        'Bearer ' + this.getToken()
      ),
      params: new HttpParams().set('CommunityId', Id.toString()),
    };
    this.CompleteUrl = AppUtil.ADMIN_API_ENDPOINT + '/admin/community-upgrade';
    return this.httpClient
      .put(this.CompleteUrl, null, this.httpOptions)
      .pipe(catchError(this.handleError));
  }

  deleteCommunity(id?: number): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders().set(
        'Authorization',
        'Bearer ' + this.getToken()
      ),
    };
    this.CompleteUrl = AppUtil.ADMIN_API_ENDPOINT + '/admin/communities/' + id;
    return this.httpClient
      .delete(this.CompleteUrl, this.httpOptions)
      .pipe(catchError(this.handleError));
  }
  //COMMUNTY

  //PAGE
  pages(page: string): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders().set(
        'Authorization',
        'Bearer ' + this.getToken()
      ),
      params: new HttpParams().set('page', page),
    };
    this.CompleteUrl = AppUtil.ADMIN_API_ENDPOINT + '/admin/pages';
    return this.httpClient
      .get(this.CompleteUrl, this.httpOptions)
      .pipe(catchError(this.handleError));
  }

  searchPages(txt: string): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders().set(
        'Authorization',
        'Bearer ' + this.getToken()
      ),
    };
    this.CompleteUrl =
      AppUtil.ADMIN_API_ENDPOINT + '/admin/pages/search/' + txt;
    return this.httpClient
      .get(this.CompleteUrl, this.httpOptions)
      .pipe(catchError(this.handleError));
  }

  upgradePage(Id: number): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders().set(
        'Authorization',
        'Bearer ' + this.getToken()
      ),
      params: new HttpParams().set('PageId', Id.toString()),
    };
    this.CompleteUrl = AppUtil.ADMIN_API_ENDPOINT + '/admin/page-upgrade';
    return this.httpClient
      .put(this.CompleteUrl, null, this.httpOptions)
      .pipe(catchError(this.handleError));
  }
  deletePage(id?: number): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders().set(
        'Authorization',
        'Bearer ' + this.getToken()
      ),
    };
    this.CompleteUrl = AppUtil.ADMIN_API_ENDPOINT + '/admin/pages/' + id;
    return this.httpClient
      .delete(this.CompleteUrl, this.httpOptions)
      .pipe(catchError(this.handleError));
  }
  //PAGE

  //INITIATIVE
  initiatives(page: string): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders().set(
        'Authorization',
        'Bearer ' + this.getToken()
      ),
      params: new HttpParams().set('page', page),
    };
    this.CompleteUrl = AppUtil.ADMIN_API_ENDPOINT + '/admin/initiatives';
    return this.httpClient
      .get(this.CompleteUrl, this.httpOptions)
      .pipe(catchError(this.handleError));
  }

  searchInitiatives(txt: string): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders().set(
        'Authorization',
        'Bearer ' + this.getToken()
      ),
    };
    this.CompleteUrl =
      AppUtil.ADMIN_API_ENDPOINT + '/admin/initiatives/search/' + txt;
    return this.httpClient
      .get(this.CompleteUrl, this.httpOptions)
      .pipe(catchError(this.handleError));
  }
  deleteInitiative(id?: number): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders().set(
        'Authorization',
        'Bearer ' + this.getToken()
      ),
    };
    this.CompleteUrl = AppUtil.ADMIN_API_ENDPOINT + '/admin/initiatives/' + id;
    return this.httpClient
      .delete(this.CompleteUrl, this.httpOptions)
      .pipe(catchError(this.handleError));
  }
  //INITIATIVE

  //DATABANK
  dataBank(Word: string): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders().set(
        'Authorization',
        'Bearer ' + this.getToken()
      ),
      params: new HttpParams().set('Word', Word),
    };
    this.CompleteUrl = AppUtil.ADMIN_API_ENDPOINT + '/admin/databank';
    return this.httpClient
      .get(this.CompleteUrl, this.httpOptions)
      .pipe(catchError(this.handleError));
  }
  //DATABANK

  //STARS & COINS
  addStars(model?: any): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders().set(
        'Authorization',
        'Bearer ' + this.getToken()
      ),
    };
    this.CompleteUrl = AppUtil.ADMIN_API_ENDPOINT + '/admin/add-stars';
    return this.httpClient
      .post(this.CompleteUrl, model, this.httpOptions)
      .pipe(catchError(this.handleError));
  }
  addCoins(model?: any): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders().set(
        'Authorization',
        'Bearer ' + this.getToken()
      ),
    };
    this.CompleteUrl = AppUtil.ADMIN_API_ENDPOINT + '/admin/add-coins';
    return this.httpClient
      .post(this.CompleteUrl, model, this.httpOptions)
      .pipe(catchError(this.handleError));
  }
  //STARS & COINS

  //BROADCAST MESSAGING
  broadcasting(model?: any): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders().set(
        'Authorization',
        'Bearer ' + this.getToken()
      ),
    };
    this.CompleteUrl = AppUtil.ADMIN_API_ENDPOINT + '/admin/broadcasting';
    return this.httpClient
      .post(this.CompleteUrl, model, this.httpOptions)
      .pipe(catchError(this.handleError));
  }
  broadcasts(): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders().set(
        'Authorization',
        'Bearer ' + this.getToken()
      ),
    };
    this.CompleteUrl = AppUtil.ADMIN_API_ENDPOINT + '/admin/broadcasts';
    return this.httpClient
      .get(this.CompleteUrl, this.httpOptions)
      .pipe(catchError(this.handleError));
  }
  //BROADCAST MESSAGING

  //TRENDSFORE QUESTIONS
  allQuestions(page: string, type: number): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders().set(
        'Authorization',
        'Bearer ' + this.getToken()
      ),
      params: new HttpParams().set('page', page),
    };
    this.CompleteUrl = AppUtil.ADMIN_API_ENDPOINT + '/admin/questions/' + type;
    return this.httpClient
      .get(this.CompleteUrl, this.httpOptions)
      .pipe(catchError(this.handleError));
  }

  searchedQuestions(txt: string): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders().set(
        'Authorization',
        'Bearer ' + this.getToken()
      ),
    };
    this.CompleteUrl =
      AppUtil.ADMIN_API_ENDPOINT + '/admin/questions/search/' + txt;
    return this.httpClient
      .get(this.CompleteUrl, this.httpOptions)
      .pipe(catchError(this.handleError));
  }

  pinQuestion(Question: number, Pinned: boolean): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders().set(
        'Authorization',
        'Bearer ' + this.getToken()
      ),
    };
    this.CompleteUrl =
      AppUtil.ADMIN_API_ENDPOINT +
      '/admin/questions/pin/' +
      Question +
      '/' +
      Pinned;
    return this.httpClient
      .put(this.CompleteUrl, null, this.httpOptions)
      .pipe(catchError(this.handleError));
  }
  //TRENDSFORE QUESTIONS

  //OPPORTUNITIES
  liveVideos(page: string): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders().set(
        'Authorization',
        'Bearer ' + this.getToken()
      ),
      params: new HttpParams().set('page', page),
    };
    this.CompleteUrl = AppUtil.ADMIN_API_ENDPOINT + '/admin/videos';
    return this.httpClient
      .get(this.CompleteUrl, this.httpOptions)
      .pipe(catchError(this.handleError));
  }
  searchedLiveVideos(txt: string): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders().set(
        'Authorization',
        'Bearer ' + this.getToken()
      ),
    };
    this.CompleteUrl =
      AppUtil.ADMIN_API_ENDPOINT + '/admin/videos/' + txt;
    return this.httpClient
      .get(this.CompleteUrl, this.httpOptions)
      .pipe(catchError(this.handleError));
  }

  stopLive(id: number): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders().set(
        'Authorization',
        'Bearer ' + this.getToken()
      ),
    };
    this.CompleteUrl = AppUtil.ADMIN_API_ENDPOINT + '/admin/videos/' + id;
    return this.httpClient
      .put(this.CompleteUrl, null, this.httpOptions)
      .pipe(catchError(this.handleError));
  }
  //OPPORTUNITIES



  /*APIS*/

  /*TOKEN*/
  setToken(token: String, data: any): void {
    this.local.set(AppUtil.ADMIN_API_TOKEN, token, 3600, 's');
    this.local.set(AppUtil.ADMIN_DATA_TOKEN, data, 3600, 's');
  }

  getToken(): String {
    return this.local.get(AppUtil.ADMIN_API_TOKEN);
  }

  getData(): any {
    return this.local.get(AppUtil.ADMIN_DATA_TOKEN);
  }

  setAdminData(data: any): void {
    this.local.set(AppUtil.ADMIN_DATA_TOKEN, data, 3600, 's');
  }

  deleteToken() {
    this.local.remove(AppUtil.ADMIN_API_TOKEN);
    this.local.remove(AppUtil.ADMIN_DATA_TOKEN);
  }

  VerifyToken(): boolean {
    let token = this.local.get(AppUtil.ADMIN_API_TOKEN);
    if (token == null) {
      /* this.adminAllLogout().subscribe(data => {
        if (data.Flag) {
          this.router.navigate(['/']);
          this.toastr.error("We're sorry, but you need to login!", 'Access Denied');
        }
      }) */
      this.router.navigate(['/']);
      this.toastr.error("We're sorry, but you need to login!", 'Access Denied');
      return false;
    } else {
      return true;
    }
  }

  logout(): void {
    let token = this.local.get(AppUtil.ADMIN_API_TOKEN);
    if (token == null) {
      this.router.navigate(['/']);
      this.toastr.success('See you again!', 'Logout');
    }
  }

  login(): void {
    let token = this.local.get(AppUtil.ADMIN_API_TOKEN);
    if (token != null) {
      this.toastr.success('Welcome Abroad', 'Login');
      this.router.navigate(['/admin/dashboard']);
    }
  }
  /*TOKEN*/

  /*ROUTING*/
  redirection(route: string): void {
    if (route != null) {
      this.router.navigate([route]);
    }
  }
  /*ROUTING*/

  /*URLS*/
  imageUrl(): String {
    return AppUtil.IMAGE_ENDPOINT;
  }
  /*URLS*/

  /*TOAST*/
  success(message: string, title: string) {
    this.toastr.success(message, title);
  }
  error(message: string, title: string) {
    this.toastr.error(message, title);
  }
  /*TOAST*/
}
