import { Component, OnInit } from '@angular/core';
import { TrendsforeService } from '../../../../services/trendsfore.service';
import { communityManagement } from '../../../../models/admin/login';

@Component({
  selector: 'app-communities',
  templateUrl: './communities.component.html',
  styleUrls: ['./communities.component.css'],
})
export class CommunitiesComponent implements OnInit {
  CommunityModel: communityManagement.Community[];
  NumId: number;
  NumStars: number;
  IsStars: boolean;
  pageNo: number;
  baseUrl: String;
  isLoadEnable: boolean;
  isLoadMoreVisible: boolean;

  constructor(protected trendsfore: TrendsforeService) {}

  ngOnInit(): void {
    if (this.trendsfore.VerifyToken()) {
      this.baseUrl = this.trendsfore.imageUrl();
      this.isLoadMoreVisible = true;
      this.isLoadEnable = true;
      this.pageNo = 1;
      this.getCommunities(this.pageNo.toString());
    }
  }

  setId(Id: number, mode: boolean) {
    this.NumId = Id;
    this.IsStars = mode;
  }

  addCoins() {
    if (this.NumId !== 0 && this.NumStars !== 0) {
      this.isLoadEnable = true;
      const formData = new FormData();
      formData.append('ReferenceType', 'community');
      formData.append('ReceiverId', this.NumId.toString());
      formData.append('Donation', this.NumStars.toString());
      this.trendsfore.addCoins(formData).subscribe((data) => {
        if (data.Flag) {
          this.refresh();
          this.trendsfore.success(
            'Community received coins successfully',
            'Trendsfore Communities'
          );
          this.NumStars = 0;
        } else {
          this.isLoadEnable = false;
          this.trendsfore.error(data.Message, 'Trendsfore Communities');
        }
      });
    } else {
      this.trendsfore.error(
        "Coins Can't be null or empty",
        'Trendsfore Communities'
      );
    }
  }

  addStars() {
    if (this.NumId !== 0 && this.NumStars !== 0) {
      this.isLoadEnable = true;
      const formData = new FormData();
      formData.append('ReferenceType', 'community');
      formData.append('ReceiverId', this.NumId.toString());
      formData.append('Stars', this.NumStars.toString());
      this.trendsfore.addStars(formData).subscribe((data) => {
        if (data.Flag) {
          this.refresh();
          this.trendsfore.success(
            'Community received stars successfully',
            'Trendsfore Communities'
          );
          this.NumStars = 0;
        } else {
          this.isLoadEnable = false;
          this.trendsfore.error(data.Message, 'Trendsfore Communities');
        }
      });
    } else {
      this.trendsfore.error(
        "Stars Can't be null or empty",
        'Trendsfore Communities'
      );
    }
  }

  search(value: string) {
    if (value !== '') {
      this.trendsfore
        .searchCommunities(value)
        .subscribe((data: communityManagement.Communities) => {
          if (data.Flag) {
            this.CommunityModel = data.Data;
          }
        });
    } else {
      this.refresh();
    }
  }

  delete(Id: number) {
    if (confirm('Are you sure, you want to delete this community?')) {
      this.trendsfore.deleteCommunity(Id).subscribe((data) => {
        if (data.Flag) {
          this.trendsfore.success(
            'Community has been deleted',
            'Trendsfore Communities'
          );
          this.refresh();
        } else {
          this.trendsfore.error(data.Message, 'Trendsfore Communities');
        }
      });
    }
  }

  upgrade(Id: number) {
    if (confirm('Are you sure, you want to upgrade this community?')) {
      this.trendsfore.upgradeCommunity(Id).subscribe((data) => {
        if (data.Flag) {
          this.trendsfore.success(
            'Community has been upgraded',
            'Trendsfore Communities'
          );
          this.refresh();
        } else {
          this.trendsfore.error(data.Message, 'Trendsfore Communities');
        }
      });
    }
  }

  refresh() {
    this.pageNo = 1;
    this.isLoadEnable = true;
    this.getCommunities(this.pageNo.toString());
    this.isLoadMoreVisible = true;
  }

  loadMore() {
    this.pageNo += 1;
    this.isLoadEnable = true;
    this.getCommunities(this.pageNo.toString());
  }

  private getCommunities(page: string) {
    this.trendsfore
      .communities(page)
      .subscribe((data: communityManagement.Communities) => {
        if (data.Flag) {
          if (page == '1') {
            this.CommunityModel = data.Data;
            this.isLoadEnable = false;
          } else {
            this.CommunityModel = this.CommunityModel.concat(data.Data);
            this.isLoadEnable = false;
          }
        } else {
          this.isLoadMoreVisible = false;
          this.isLoadEnable = false;
        }
      });
  }
}
