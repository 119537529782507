import { Component, OnInit } from '@angular/core';
import { liveVideoManagement } from '../../../../models/admin/login';
import { TrendsforeService } from '../../../../services/trendsfore.service';
@Component({
  selector: 'app-videos',
  templateUrl: './videos.component.html',
  styleUrls: ['./videos.component.css']
})
export class VideosComponent implements OnInit {
  LiveVideoModel: liveVideoManagement.Live[];
  isLoadEnable: boolean;
  isLoadMoreVisible: boolean;
  pageNo: number;
  baseUrl: String;

  constructor(private trendsfore: TrendsforeService) { }

  ngOnInit(): void {
    if (this.trendsfore.VerifyToken()) {
      this.baseUrl = this.trendsfore.imageUrl();
      this.isLoadMoreVisible = true;
      this.isLoadEnable = true;
      this.pageNo = 1;
      this.getLiveVideos(this.pageNo.toString());
    }
  }

  stop(item: liveVideoManagement.Live) {
    this.trendsfore.stopLive(item.LiveId)
      .subscribe((data) => {
        if (data.Flag) {
          this.trendsfore.success('Live video has been stoped', 'Trendsfore Live Video');
          let index = this.LiveVideoModel.indexOf(item);
          this.LiveVideoModel.splice(index, 1);
          this.refresh();
        } else {
          this.trendsfore.error(data.Message, 'Trendsfore Live Video');
        }
      });
  }


  search(value: string) {
    if (value !== '') {
      this.trendsfore
        .searchedLiveVideos(value)
        .subscribe((data: liveVideoManagement.LiveVideos) => {
          if (data.Flag) {
            this.LiveVideoModel = data.Data;
          }
        });
    } else {
      this.refresh();
    }
  }

  loadMore() {
    this.pageNo += 1;
    this.isLoadEnable = true;
    this.getLiveVideos(this.pageNo.toString());
  }

  refresh() {
    this.pageNo = 1;
    this.isLoadMoreVisible = true;
    this.isLoadEnable = true;
    this.getLiveVideos(this.pageNo.toString());
  }

  private getLiveVideos(page: string) {
    this.trendsfore
      .liveVideos(page)
      .subscribe((data: liveVideoManagement.LiveVideos) => {
        if (data.Flag) {
          if (page == '1') {
            this.LiveVideoModel = data.Data;
            this.isLoadEnable = false;
          } else {
            this.LiveVideoModel = this.LiveVideoModel.concat(data.Data);
            this.isLoadEnable = false;
          }
        } else {
          this.isLoadEnable = false;
          this.isLoadMoreVisible = false;
        }
      });
  }

}
