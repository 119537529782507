import { Component, OnInit, ViewChild } from '@angular/core';
import { TrendsforeService } from '../../../services/trendsfore.service';
import { homeAnalytics } from '../../../models/admin/login';
import {
  ChartComponent,
  ApexNonAxisChartSeries,
  ApexResponsive,
  ApexChart,
  ApexTheme,
  ApexTitleSubtitle,
  ApexFill,
  ApexStroke,
  ApexYAxis,
  ApexXAxis,
  ApexLegend,
  ApexAxisChartSeries,
  ApexPlotOptions,
} from 'ng-apexcharts';

export type ChartOptions = {
  series: ApexNonAxisChartSeries;
  chart: ApexChart;
  responsive: ApexResponsive[];
  labels: any;
  theme: ApexTheme;
  title: ApexTitleSubtitle;
  fill: ApexFill;
  yaxis: ApexYAxis;
  stroke: ApexStroke;
  legend: ApexLegend;
  plotOptions: ApexPlotOptions;
  xaxis: ApexXAxis;
};

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class HomeComponent implements OnInit {
  @ViewChild('chart') chart: ChartComponent;
  public CountryChartOptions: Partial<any>;
  public VolunteerChartOptions: Partial<any>;

  HomeModel: homeAnalytics.Home;
  DonationModel: homeAnalytics.Donation[];
  isLoadEnable: boolean;
  isLoadMoreVisible: boolean;
  pageNo: number;
  baseUrl: String;
  date: string;

  constructor(private trendsfore: TrendsforeService) {
    let dat = new Date();
    var months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];
    this.date = months[dat.getMonth()] + ' ' + dat.getFullYear();
  }

  ngOnInit(): void {
    if (this.trendsfore.VerifyToken()) {
      this.pageNo = 1;
      this.isLoadEnable = true;
      this.isLoadMoreVisible = true;
      this.baseUrl = this.trendsfore.imageUrl();
      this.getHomeAnalytics();
    }
  }

  private getHomeAnalytics() {
    this.isLoadEnable = true;
    this.trendsfore.homeAnalytics().subscribe((data: homeAnalytics.Home) => {
      if (data.Flag) {
        this.HomeModel = data;
        this.CountryChartOptions = {
          series: [
            {
              name: 'Users',
              data: this.HomeModel.Data.LocationUsers,
            },
            {
              name: 'Volunteers',
              data: this.HomeModel.Data.LocationVolunteers,
            },
          ],
          chart: {
            height: 350,
            type: 'bar',
            color: ['#153a6c', '#ff5630'],
          },
          plotOptions: {
            bar: {
              color: ['#153a6c', '#ff5630'],
            },
          },
          xaxis: {
            categories: this.HomeModel.Data.Location,
          },
          color: ['#153a6c', '#ff5630'],
        };

        this.VolunteerChartOptions = {
          series: this.HomeModel.Data.VolunteerUsers,
          chart: {
            type: 'polarArea',
          },
          labels: this.HomeModel.Data.VolunteerLevel,
          fill: {
            opacity: 1,
          },
          stroke: {
            width: 1,
            colors: undefined,
          },
          yaxis: {
            show: false,
          },
          legend: {
            position: 'bottom',
          },
          plotOptions: {
            polarArea: {
              rings: {
                strokeWidth: 0,
              },
            },
          },
          theme: {
            monochrome: {
              enabled: true,
              shadeTo: 'light',
              shadeIntensity: 0.6,
            },
          },
        };
        this.getDonations(this.pageNo.toString());
      } else {
        this.isLoadEnable = false;
      }
    });
  }

  loadMore() {
    this.pageNo += 1;
    this.isLoadEnable = true;
    this.getDonations(this.pageNo.toString());
  }

  private getDonations(page: string) {
    this.trendsfore
      .donationHistory(page)
      .subscribe((data: homeAnalytics.DonationHistory) => {
        if (data.Flag) {
          if (page == '1') {
            this.DonationModel = data.Data;
            this.isLoadEnable = false;
          } else {
            this.DonationModel = this.DonationModel.concat(data.Data);
            this.isLoadEnable = false;
          }
        } else {
          this.isLoadMoreVisible = false;
          this.isLoadEnable = false;
        }
      });
  }
}
