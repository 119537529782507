import { Component, OnInit } from '@angular/core';
import { TrendsforeService } from '../../../../services/trendsfore.service';
import { volunteerManagement } from '../../../../models/admin/login';
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
@Component({
  selector: 'app-volunteers',
  templateUrl: './volunteers.component.html',
  styleUrls: ['./volunteers.component.css']
})
export class VolunteersComponent implements OnInit {

  OptionModel: volunteerManagement.Option[];
  OptionLevel: volunteerManagement.Level;
  OptionLevelForm: FormGroup;
  isLevelCreate: boolean;
  errorOnLoginSubmit: String;
  isLoginOnSubmitErrorEnabled: boolean;
  isLoadEnable: boolean;
  baseUrl: String;

  constructor(private trendsfore: TrendsforeService) { }

  ngOnInit(): void {
    if (this.trendsfore.VerifyToken()) {
      this.baseUrl = this.trendsfore.imageUrl();
      this.isLoadEnable = true;
      this.getVolunteerLevels();
      this.OptionLevelForm = new FormGroup({
        LevelId: new FormControl(''),
        LevelName: new FormControl('', [
          Validators.required
        ]),
        OptionId: new FormControl('', [
          Validators.required
        ]),
        PerAnswerPoints: new FormControl('', [
          Validators.required
        ]),
        RequiredPoints: new FormControl('', [
          Validators.required
        ]),
        RequiredStars: new FormControl('', [
          Validators.required
        ]),
        LevelDetail: new FormControl('', [
          Validators.required
        ]),
      });
    }
  }

  create() {
    if (this.OptionLevelForm.valid) {
      this.OptionLevel = this.OptionLevelForm.value;
      this.trendsfore.addLevel(this.OptionLevel).subscribe((data?: any) => {
        if (data.Flag) {
          this.trendsfore.success("New volunteer level has been created", "Volunteer Level");
          this.refresh();
          this.OptionLevelForm.reset();
        } else {
          this.enableError(data.Message);
        }
      });
    } else {
      this.formValidation();
    }

  }

  update() {
    if (this.OptionLevelForm.valid) {
      this.OptionLevel = this.OptionLevelForm.value;
      this.trendsfore.updateLevel(this.OptionLevel).subscribe((data?: any) => {
        if (data.Flag) {
          this.trendsfore.success("Volunteer level has been updated", "Volunteer Level");
          this.refresh();
          this.OptionLevelForm.reset();
        } else {
          this.trendsfore.error(data.Message, "Volunteer Level");
          this.OptionLevelForm.reset();
        }
      });
    } else {
      this.enableError('All fields required');
    }
  }
  save() {
    this.isLevelCreate = true;
    this.OptionLevelForm.reset();
  }

  refresh() {
    this.isLoadEnable = true;
    this.getVolunteerLevels();
  }

  edit(levelModel: volunteerManagement.Level) {
    this.isLevelCreate = false;
    this.OptionLevelForm.reset();
    this.OptionLevelForm.patchValue({ OptionId: levelModel.OptionId });
    this.OptionLevelForm.patchValue({ LevelId: levelModel.LevelId });
    this.OptionLevelForm.patchValue({ LevelName: levelModel.LevelName });
    this.OptionLevelForm.patchValue({ PerAnswerPoints: levelModel.PerAnswerPoints });
    this.OptionLevelForm.patchValue({ RequiredPoints: levelModel.RequiredPoints });
    this.OptionLevelForm.patchValue({ RequiredStars: levelModel.RequiredStars });
    this.OptionLevelForm.patchValue({ LevelDetail: levelModel.LevelDetail });
  }
  private formValidation() {

    if (this.OptionLevelForm.get('OptionId').errors?.required) {
      this.enableError('-You need to select one of the level options.*');
    }
    if (this.OptionLevelForm.get('LevelName').errors?.required) {
      this.enableError('-Level Name cannot be empty.*');
    }
    if (this.OptionLevelForm.get('RequiredPoints').errors?.required) {
      this.enableError('-Required Points cannot be empty.*');
    }
    if (this.OptionLevelForm.get('RequiredStars').errors?.required) {
      this.enableError('-Required Stars cannot be empty.*');
    }
    if (this.OptionLevelForm.get('PerAnswerPoints').errors?.required) {
      this.enableError('-Per Answer Points cannot be empty.*');
    }
    if (this.OptionLevelForm.get('LevelDetail').errors?.required) {
      this.enableError('-Level Detail cannot be empty.*');
    }
  }
  private getVolunteerLevels() {
    this.trendsfore.userVolunteerOptionLevels().subscribe(
      (data: volunteerManagement.VolunteerOptions) => {
        if (data.Flag) {
          this.OptionModel = data.Data;
          this.isLoadEnable = false;
        }
      }
    );
  }

  private enableError(error: string): void {
    if (this.errorOnLoginSubmit === undefined) {
      this.errorOnLoginSubmit = error;
    } else {
      this.errorOnLoginSubmit += "<br>" + error;
    }
    this.isLoginOnSubmitErrorEnabled = true;
    setTimeout(() => {
      this.isLoginOnSubmitErrorEnabled = false;
      this.errorOnLoginSubmit = undefined;
    }, 10000);
  }

}
