import { Component, OnInit } from '@angular/core';
import { trendsforeQuestions } from '../../../../models/admin/login';
import { TrendsforeService } from '../../../../services/trendsfore.service';

@Component({
  selector: 'app-questions',
  templateUrl: './questions.component.html',
  styleUrls: ['./questions.component.css'],
})
export class QuestionsComponent implements OnInit {
  AllQuestionModel: trendsforeQuestions.Question[];
  QuestionText: string;
  isLoadEnable: boolean;
  isLoadMoreVisible: boolean;
  pageNo: number;
  baseUrl: String;
  constructor(private trendsfore: TrendsforeService) {}
  ngOnInit(): void {
    if (this.trendsfore.VerifyToken()) {
      this.baseUrl = this.trendsfore.imageUrl();
      this.isLoadMoreVisible = true;
      this.isLoadEnable = true;
      this.pageNo = 1;
      this.getAllQuestions(this.pageNo.toString());
    }
  }

  search(value: string) {
    this.isLoadMoreVisible = false;
    if (value !== '') {
      this.trendsfore
        .searchedQuestions(value)
        .subscribe((data: trendsforeQuestions.AllQuestions) => {
          if (data.Flag) {
            this.AllQuestionModel = data.Data;
          }
        });
    } else {
      this.refresh();
    }
  }

  loadMore() {
    this.pageNo += 1;
    this.isLoadEnable = true;
    this.getAllQuestions(this.pageNo.toString());
  }

  refresh() {
    this.pageNo = 1;
    this.isLoadMoreVisible = true;
    this.isLoadEnable = true;
    this.getAllQuestions(this.pageNo.toString());
  }

  getAnonymousQuestions(page: string) {
    this.QuestionText = 'Anonymous Questions';
    this.isLoadEnable = true;
    this.trendsfore
      .allQuestions(page, 2)
      .subscribe((data: trendsforeQuestions.AllQuestions) => {
        if (data.Flag) {
          this.AllQuestionModel = data.Data;
          this.isLoadEnable = false;
          this.isLoadMoreVisible = false;
        } else {
          this.AllQuestionModel = null;
          this.isLoadEnable = false;
          this.isLoadMoreVisible = false;
        }
      });
  }

  getTrendingQuestions(page: string) {
    this.QuestionText = 'Trending Questions';
    this.isLoadEnable = true;
    this.trendsfore
      .allQuestions(page, 1)
      .subscribe((data: trendsforeQuestions.AllQuestions) => {
        if (data.Flag) {
          this.AllQuestionModel = data.Data;
          this.isLoadEnable = false;
          this.isLoadMoreVisible = false;
        } else {
          this.AllQuestionModel = null;
          this.isLoadEnable = false;
          this.isLoadMoreVisible = false;
        }
      });
  }

  pinQuestion(item: trendsforeQuestions.Question, Pinned: boolean) {
    this.isLoadEnable = true;
    this.trendsfore.pinQuestion(item.QuestionId, Pinned).subscribe((data) => {
      if (data.Flag) {
        let index = this.AllQuestionModel.indexOf(item);
        item.IsPinned = data.Data;
        this.AllQuestionModel[index] = item;
        this.isLoadEnable = false;
        if (data.Data) {
          this.trendsfore.success(
            'Question has been pinned.',
            'Trendsfore Questions'
          );
        } else {
          this.trendsfore.success(
            'Question has been unpinned.',
            'Trendsfore Questions'
          );
        }
      } else {
        this.trendsfore.error(data.Message, 'Trendsfore Questions');
        this.isLoadEnable = false;
      }
    });
  }

  private getAllQuestions(page: string) {
    this.QuestionText = 'Questions';
    this.trendsfore
      .allQuestions(page, 0)
      .subscribe((data: trendsforeQuestions.AllQuestions) => {
        if (data.Flag) {
          if (page == '1') {
            this.AllQuestionModel = data.Data;
            this.isLoadEnable = false;
          } else {
            this.AllQuestionModel = this.AllQuestionModel.concat(data.Data);
            this.isLoadEnable = false;
          }
        } else {
          this.isLoadEnable = false;
          this.isLoadMoreVisible = false;
        }
      });
  }
}
