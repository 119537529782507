<app-aside [isLoadEnable]="this.isLoadEnable"></app-aside>
<div class="container-fluid container-application">
  <app-sidebar></app-sidebar>
  <!-- Content -->
  <div class="main-content position-relative">
    <app-header></app-header>
    <!-- Page content -->

    <div class="page-content">
      <div class="card" *ngIf="this.AllQuestionModel?.length <= 0">
        <img class="img-center img" src="../../../assets/img/errors/nodata-found.png" alt="NO DATA">
      </div>
      <div *ngIf="this.AllQuestionModel?.length > 0">
        <!-- Page title -->
        <div class="page-title">
          <div class="row justify-content-between align-items-center">
            <div
              class="col-md-6 d-flex align-items-center justify-content-between justify-content-md-start mb-3 mb-md-0">
              <!-- Page title + Go Back button -->
              <div class="d-inline-block">
                <h5 class="h4 d-inline-block font-weight-400 mb-0 text-white">Trendsfore Questions</h5>
              </div>
              <!-- Additional info -->
              <div class="align-items-center ml-4 d-inline-flex">
                <span class="h4 text-info mb-0 mr-2">{{ this.AllQuestionModel?.length }}</span>
                <span class="text-sm opacity-7 text-white">Total {{ this.QuestionText }}</span>
              </div>
            </div>
            <div class="col-md-6 d-flex align-items-center justify-content-between justify-content-md-end">
              <!-- Filter input -->
              <form class="bg-neutral rounded-pill d-inline-block">
                <div class="input-group input-group-sm input-group-merge input-group-flush">
                  <div class="input-group-prepend">
                    <span class="input-group-text bg-transparent"><i class="fas fa-filter"></i></span>
                  </div>
                  <input type="text" (keyup)="search($event.target.value)" class="form-control form-control-flush"
                    placeholder="Filter results">
                </div>
              </form>
              <div class="btn-group ml-3">
                <button type="button" class="btn btn-sm btn-light rounded-pill dropdown-toggle" data-toggle="dropdown"
                  aria-haspopup="true" aria-expanded="false">
                  Sort By
                </button>
                <div class="dropdown-menu dropdown-menu-right">
                  <a class="dropdown-item text-primary" [routerLink]="" (click)="this.getTrendingQuestions('0')">
                    <i class="fas fa-hashtag text-primary"></i>Trending Questions
                  </a>
                  <div class="dropdown-divider" role="presentation"></div>
                  <a class="dropdown-item" [routerLink]="" (click)="this.getAnonymousQuestions('0')">
                    <i class="fas fa-user text-primary"></i>Anonymous Questions
                  </a>
                </div>
              </div>
              <a [routerLink]="" (click)="this.refresh()"
                class="btn btn-sm btn-white btn-icon-only rounded-circle ml-4">
                <span class="btn-inner--icon"><i class="fas fa-sync"></i></span>
              </a>
            </div>
          </div>
        </div>
        <!-- Listing -->
        <div class="card-columns mt-4">
          <!--ALL QUESTIONS-->
          <div class="card shadow-sm hover-shadow-lg" *ngFor="let question of this.AllQuestionModel">
            <div class="card-header border-0">
              <div class="row align-items-center">
                <div class="col-9">
                  <div class="media mb-0 align-items-center">
                    <img alt="Image placeholder" src="{{ this.baseUrl+question.UserImage }}"
                      class="avatar  rounded-circle avatar-md">
                    <div class="media-body pl-2">
                      <div class="text-md my-0">
                        <a class="text-primary font-weight-600">{{ question.UserFullName }}</a>
                      </div>
                      <div class="text-sm my-0">
                        Asked <a class="text-primary font-weight-600">{{question.Date }}</a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-3">
                  <a title="Question is pinned already" [routerLink]="" (click)='pinQuestion(question,0)'
                    class=" rounded-pill btn btn-primary btn-xs" *ngIf="question.IsPinned">Pinned</a>
                  <a title="Pin this question" [routerLink]="" (click)='pinQuestion(question,1)'
                    class="rounded-pill btn btn-light btn-xs" *ngIf="!question.IsPinned">Pin</a>

                </div>
              </div>
            </div>
            <div class="card-body pt-0">
              <div class="p-3 border border-dashed">

                <!--IF OPEN QUESTION-->
                <div class="TrendsforeOpenQuestion" *ngIf="question.QuestionType==='open'">
                  <h6 class="mb-2">{{ question.QuestionHeadline }}</h6>
                  <span class="text-md text-muted font-weight-bolder">{{ question.MainQuestion }}</span>

                  <!--SUB-QUESTION-->
                  <div class="SubQuestions ml-2" *ngIf="question.SubQuestions?.length > 0">
                    <hr class="my-2">
                    <div class="SubQuestion" *ngFor="let subquestion of question.SubQuestions">
                      <span class="text-sm font-weight-bold">{{ subquestion.Question }}</span>
                      <ul class="text-sm">
                        <li *ngFor="let questionoption of subquestion.Options">{{ questionoption.option }}</li>
                      </ul>
                    </div>
                  </div>

                </div>

                <!--IF VOICE QUESTION-->
                <div class="TrendsforeVoiceQuestion" *ngIf="question.QuestionType==='voice'">
                  <h6 class="mb-2">Question Headline</h6>
                  <audio style="width: 100%;" controls>
                    <source src="{{ this.baseUrl+question.QuestionHeadline }}" type="audio/mpeg">
                    Your browser does not support the audio element.
                  </audio>
                  <h6 class="mb-2">Main Question</h6>
                  <audio style="width: 100%;" controls>
                    <source src="{{ this.baseUrl+question.MainQuestion }}" type="audio/mpeg">
                    Your browser does not support the audio element.
                  </audio>
                </div>

                <!--IF POLL QUESTION-->
                <div class="TrendsforePollQuestion" *ngIf="question.QuestionType==='poll'">
                  <h6 class="mb-2">{{ question.QuestionHeadline }}</h6>
                  <span class="text-md text-muted font-weight-bolder">{{ question.MainQuestion }}</span>
                  <div class="MainQuestionOptions ml-2">
                    <span class="text-sm font-weight-bold">Options</span>
                    <ul class="text-sm">
                      <li *ngFor="let questionoption of question.MainQuestionOptions">{{questionoption.option}}</li>
                    </ul>
                  </div>
                  <!--SUB-QUESTION-->
                  <div class="SubQuestions ml-2" *ngIf="question.SubQuestions?.length > 0">
                    <hr class="my-2">
                    <div class="SubQuestion" *ngFor="let subquestion of question.SubQuestions">
                      <span class="text-sm font-weight-bold">{{ subquestion.Question }}</span>
                      <ul class="text-sm">
                        <li *ngFor="let questionoption of subquestion.Options">{{ questionoption.option }}</li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div class="ReferencesAndMedia" *ngIf="question.Content?.length > 0">
                  <hr class="my-3">
                  <h6 class="mb-2">Media & References</h6>
                  <span *ngFor="let media of question.Content">
                    <a *ngIf="media.ContentType==='video'" href="{{ this.baseUrl+media.ContentUrl }}" target="_blank"
                      class="btn btn-primary btn-xs ml-1 mb-1" download>{{ media.ContentTitle }}</a>
                    <a *ngIf="media.ContentType==='image'" href="{{ this.baseUrl+media.ContentUrl }}" target="_blank"
                      class="btn btn-primary btn-xs ml-1 mb-1" download>{{ media.ContentTitle }}</a>
                    <a style="width:100px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;"
                      *ngIf="media.ContentType==='link'" href="{{ media.ContentUrl }}" target="_blank"
                      class="btn btn-primary btn-xs  ml-1 mb-1">{{ media.ContentTitle }}</a>
                  </span>
                </div>
                <div class="QuestionHashtags mt-2" *ngIf="question.Hastags?.length > 0">
                  <a *ngFor="let tag of question.Hastags"
                    class="btn btn-light btn-xs text-dark font-weight-bolder ml-1 mb-1">#{{ tag.Hashtag }}</a>
                </div>
                <hr class="my-3">
                <div class="row align-items-center">
                  <div class="col-4 text-center">
                    <h6 class="mb-0">{{ question.Answers }}</h6>
                    <span class="text-sm text-muted">Answers</span>
                  </div>
                  <div class="col-4 text-center">
                    <h6 class="mb-0">{{ question.Comments }}</h6>
                    <span class="text-sm text-muted">Comments</span>
                  </div>
                  <div class="col-4 text-center">
                    <h6 class="mb-0">{{ question.Stars }}</h6>
                    <span class="text-sm text-muted">Stars</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Load more -->
        <div class="mt-4 mb-4 text-center" *ngIf="this.AllQuestionModel?.length >= 9 && this.isLoadMoreVisible">
          <a [routerLink]="" routerLinkActive="router-link-active"
            class="btn btn-sm btn-neutral rounded-pill shadow-sm hover-translate-y-n3" (click)="this.loadMore()">Load
            more ...</a>
        </div>
      </div>

    </div>
    <app-footer></app-footer>
  </div>
</div>
