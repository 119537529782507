import { Component, OnInit,Input } from '@angular/core';
import { TrendsforeService } from '../../../services/trendsfore.service';
import { adminLoginScreen} from '../../../models/admin/login';
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  DataModel: adminLoginScreen.User;
  baseUrl: String;

  @Input() UserModel : adminLoginScreen.User;

  constructor(private trendsfore: TrendsforeService) {}
  ngOnInit(): void {
    this.baseUrl=this.trendsfore.imageUrl();
    this.DataModel = this.trendsfore?.getData()['User'];
  }

}
