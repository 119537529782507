import { Component, OnInit } from '@angular/core';

import { TrendsforeService } from '../../../../services/trendsfore.service';
import { initiativeManagement } from '../../../../models/admin/login';

@Component({
  selector: 'app-initiatives',
  templateUrl: './initiatives.component.html',
  styleUrls: ['./initiatives.component.css'],
})
export class InitiativesComponent implements OnInit {
  InitiativeModel: initiativeManagement.Initiative[];
  NumId: number;
  NumStars: number;
  IsStars: boolean;
  pageNo: number;
  baseUrl: String;
  isLoadEnable: boolean;
  isLoadMoreVisible: boolean;

  constructor(protected trendsfore: TrendsforeService) {}

  ngOnInit(): void {
    if (this.trendsfore.VerifyToken()) {
      this.baseUrl = this.trendsfore.imageUrl();
      this.isLoadMoreVisible = true;
      this.isLoadEnable = true;
      this.pageNo = 1;
      this.getInitiatives(this.pageNo.toString());
    }
  }

  setId(Id: number, mode: boolean) {
    this.NumId = Id;
    this.IsStars = mode;
  }

  addCoins() {
    if (this.NumId !== 0 && this.NumStars !== 0) {
      this.isLoadEnable = true;
      const formData = new FormData();
      formData.append('ReferenceType', 'initiative');
      formData.append('ReceiverId', this.NumId.toString());
      formData.append('Donation', this.NumStars.toString());
      this.trendsfore.addCoins(formData).subscribe((data) => {
        if (data.Flag) {
          this.refresh();
          this.trendsfore.success(
            'Initiative received coins successfully',
            'Trendsfore Initiatives'
          );
          this.NumStars = 0;
        } else {
          this.isLoadEnable = false;
          this.trendsfore.error(data.Message, 'Trendsfore Initiatives');
        }
      });
    } else {
      this.trendsfore.error(
        "Coins Can't be null or empty",
        'Trendsfore Initiatives'
      );
    }
  }

  addStars() {
    if (this.NumId !== 0 && this.NumStars !== 0) {
      this.isLoadEnable = true;
      const formData = new FormData();
      formData.append('ReferenceType', 'initiative');
      formData.append('ReceiverId', this.NumId.toString());
      formData.append('Stars', this.NumStars.toString());
      this.trendsfore.addStars(formData).subscribe((data) => {
        if (data.Flag) {
          this.refresh();
          this.trendsfore.success(
            'Initiative received stars successfully',
            'Trendsfore Initiatives'
          );
          this.NumStars = 0;
        } else {
          this.isLoadEnable = false;
          this.trendsfore.error(data.Message, 'Trendsfore Initiatives');
        }
      });
    } else {
      this.trendsfore.error(
        "Stars Can't be null or empty",
        'Trendsfore Initiatives'
      );
    }
  }

  search(value: string) {
    if (value !== '') {
      this.trendsfore
        .searchInitiatives(value)
        .subscribe((data: initiativeManagement.Initiatives) => {
          if (data.Flag) {
            this.InitiativeModel = data.Data;
          }
        });
    } else {
      this.refresh();
    }
  }

  delete(Id: number) {
    if (confirm('Are you sure, you want to delete this initiative?')) {
      this.trendsfore.deleteInitiative(Id).subscribe((data) => {
        if (data.Flag) {
          this.trendsfore.success(
            'Initiative has been deleted',
            'Trendsfore Initiatives'
          );
          this.refresh();
        } else {
          this.trendsfore.error(data.Message, 'Trendsfore Initiatives');
        }
      });
    }
  }
  refresh() {
    this.pageNo = 1;
    this.isLoadEnable = true;
    this.getInitiatives(this.pageNo.toString());
    this.isLoadMoreVisible = true;
  }

  loadMore() {
    this.pageNo += 1;
    this.isLoadEnable = true;
    this.getInitiatives(this.pageNo.toString());
  }

  private getInitiatives(page: string) {
    this.trendsfore
      .initiatives(page)
      .subscribe((data: initiativeManagement.Initiatives) => {
        if (data.Flag) {
          if (page == '1') {
            this.InitiativeModel = data.Data;
            this.isLoadEnable = false;
          } else {
            this.InitiativeModel = this.InitiativeModel.concat(data.Data);
            this.isLoadEnable = false;
          }
        } else {
          this.isLoadMoreVisible = false;
          this.isLoadEnable = false;
        }
      });
  }
}
