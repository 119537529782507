<app-aside [isLoadEnable]="this.isLoadEnable"></app-aside>
<div class="container-fluid container-application">
  <app-sidebar></app-sidebar>
  <!-- Content -->
  <div class="main-content position-relative">
    <app-header></app-header>
    <!-- Page content -->
    <div class="page-content">

      <!-- Create keyword modal -->
      <div class="modal fade" id="modal-project-create" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <form [formGroup]="this.keywordForm">
              <div class="modal-body">
                <!--REACTIVE FORM VALIDATION-->
                <div *ngIf="this.isLoginOnSubmitErrorEnabled" class="alert alert-danger">
                  {{ this.errorOnLoginSubmit }}
                </div>
                <!--REACTIVE FORM VALIDATION-->
                <!-- Keyword name -->
                <div class="form-group">
                  <label class="form-control-label">
                    Keyword*
                  </label>
                  <input *ngIf="this.isKeywordDeleteUpdateCreate!==1" type="hidden" formControlName="KeywordId"
                    class="form-control">

                  <input type="text" [readonly]="isReadOnly" placeholder="Enter any keyword" formControlName="Keyword"
                    class="form-control">
                </div>
              </div>
              <div class="modal-footer">
                <button *ngIf="this.isKeywordDeleteUpdateCreate===1" (click)="saveKeyword()" type="button"
                  class="btn btn-sm btn-primary rounded-pill mr-auto">Save</button>
                <button *ngIf="this.isKeywordDeleteUpdateCreate===2" type="button"
                  class="btn btn-sm btn-info rounded-pill mr-auto" data-dismiss="modal"
                  (click)="updateKeyword()">Update</button>
                <button *ngIf="this.isKeywordDeleteUpdateCreate===3" type="button"
                  class="btn btn-sm btn-danger rounded-pill mr-auto" data-dismiss="modal"
                  (click)="deleteKeyword()">Delete</button>
                <button type="button" class="btn btn-sm btn-link text-primary px-2" data-dismiss="modal">Cancel</button>
              </div>
            </form>
          </div>
        </div>
      </div>


      <!-- Unbanned User modal -->
      <div class="modal fade" id="modal-unbanned-user" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-body">
              <div class="card card-fluid">
                <div class="card-body text-center">
                  <div class="avatar-parent-child">
                    <a class="avatar avatar-lg rounded-circle">
                      <img alt="Image placeholder" src="{{this.baseUrl+this.bannedUser?.UserImage }}">
                    </a>
                    <span class="avatar-child avatar-badge bg-danger"></span>
                  </div>
                  <h5 class="h6 mt-4 mb-0">{{ this.bannedUser?.UserFullName }}</h5>
                  <a class="d-block text-sm text-muted mb-3">{{ this.bannedUser?.UserEmail }}</a>
                  <div class="actions justify-content-between px-4 text-center">
                    <button type="button" data-dismiss="modal" (click)="unbanUser(this.bannedUser?.BannedId)"
                      class="btn btn-sm btn-primary rounded-pill mr-auto">UNBAN USER</button>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-sm btn-link text-primary px-2" data-dismiss="modal">Cancel</button>
            </div>
          </div>
        </div>
      </div>


      <!-- Page title -->
      <div class="page-title" *ngIf="this.Keywords?.length > 0">
        <div class="row justify-content-between align-items-center">
          <div class="col-md-6 d-flex align-items-center justify-content-between justify-content-md-start mb-3 mb-md-0">
            <!-- Page title + Go Back button -->
            <div class="d-inline-block">
              <h5 class="h4 d-inline-block font-weight-400 mb-0 text-white">Keywords & Banned Users</h5>
            </div>
            <!-- Additional info -->
            <div class="align-items-center ml-4 d-inline-flex">
              <span class="h4 text-info mb-0 mr-2">{{ this.Keywords?.length }}</span>
              <span class="text-sm opacity-7 text-white">Banned Keywords</span>
            </div>
          </div>
          <div class="col-md-6 d-flex align-items-center justify-content-between justify-content-md-end">
            <a href="#modal-project-create" class="btn btn-sm btn-white btn-icon-only rounded-circle ml-4"
              data-toggle="modal" (click)="create()">
              <span class="btn-inner--icon"><i class="fas fa-plus"></i></span>
            </a>
          </div>
        </div>
      </div>

      <div class="card" *ngIf="this.Keywords?.length <= 0">
        <img class="img-center img" src="../../../assets/img/errors/nodata-found.png" alt="NO DATA">
      </div>

      <!-- Listing -->
      <div class="card" *ngIf="this.Keywords?.length > 0">
        <!-- Card header -->
        <div class="card-header actions-toolbar border-0">
          <div class="actions-search" id="actions-search">
            <div class="input-group input-group-merge input-group-flush">
              <div class="input-group-prepend">
                <span class="input-group-text bg-transparent"><i class="fas fa-search"></i></span>
              </div>
              <input type="text" (keyup)="search($event.target.value)" class="form-control form-control-flush"
                placeholder="Type and hit enter ...">
              <div class="input-group-append">
                <a href="#" class="input-group-text bg-transparent" data-action="search-close"
                  data-target="#actions-search"><i class="fas fa-times"></i></a>
              </div>
            </div>
          </div>
          <div class="row justify-content-between align-items-center">
            <div class="col">
              <h6 class="d-inline-block mb-0">Keywords</h6>
            </div>
            <div class="col text-right">
              <div class="actions">
                <a [routerLink]="" routerLinkActive="router-link-active" class="action-item mr-3"
                  data-action="search-open" data-target="#actions-search">
                  <i class="fas fa-search"></i>
                </a>
                <div class="dropdown" data-toggle="dropdown">
                  <a href="#" class="action-item" role="button" data-toggle="dropdown" aria-haspopup="true"
                    aria-expanded="false">
                    <i class="fas fa-ellipsis-h"></i>
                  </a>
                  <div class="dropdown-menu dropdown-menu-right">
                    <a (click)="refresh()" [routerLink]="" routerLinkActive="router-link-active"
                      class="dropdown-item">Refresh</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Table -->
        <div class="table-responsive">
          <table class="table align-items-center" *ngIf="this.Keywords?.length > 0">
            <thead>
              <tr>
                <th scope="col" class="sort" data-sort="name" class="text-lg">Keywords</th>
                <th scope="col" class="text-lg">Banned Users</th>
                <th scope="col" class="text-lg">Privileges</th>
              </tr>
            </thead>
            <tbody class="list">
              <tr *ngFor="let keyword of Keywords">
                <th scope="row">
                  <div class="media align-items-center">
                    <div>
                      <i class="fas fa-keyboard fa-1x"></i>
                    </div>
                    <div class="media-body ml-4">
                      <a [routerLink]="" routerLinkActive="router-link-active" class="name mb-0 h6 text-sm">{{
                        keyword.Keyword }}</a>
                    </div>
                  </div>
                </th>
                <td>
                  <div class="avatar-group" *ngIf="keyword.User?.length > 0">
                    <a href="#modal-unbanned-user" data-toggle="modal" *ngFor="let user of keyword.User"
                      (click)="userModal(user)" class="avatar rounded-circle avatar-sm">
                      <img alt="Image placeholder" src="{{ this.baseUrl+user.UserImage }}" class="">
                    </a>
                  </div>
                  <i class="fas fa-ban" *ngIf="keyword.User?.length <= 0"></i>
                </td>
                <td class="text-left">
                  <div class="dropdown" data-toggle="dropdown">
                    <a href="#" class="action-item" role="button" data-toggle="dropdown" aria-haspopup="true"
                      aria-expanded="false">
                      <i class="fas fa-ellipsis-h"></i>
                    </a>
                    <div class="dropdown-menu dropdown-menu-right">
                      <a href="#modal-project-create" data-toggle="modal"
                        (click)="edit(keyword.Keyword,keyword.KeywordId)" class="dropdown-item"> <i
                          class="fas fa-pencil-alt"></i> Edit</a>
                      <a href="#modal-project-create" data-toggle="modal"
                        (click)="deletes(keyword.Keyword,keyword.KeywordId)" class="dropdown-item"><i
                          class="fas fa-trash-alt"></i> Delete</a>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <!-- Load more -->
      <div class="mt-4 text-center" *ngIf="this.Keywords?.length > 0">
        <a [routerLink]="" routerLinkActive="router-link-active"
          class="btn btn-sm btn-neutral rounded-pill shadow hover-translate-y-n3" (click)="this.loadMore()"
          *ngIf="this.isLoadMoreVisible">Load
          more ...</a>
      </div>
    </div>

    <app-footer></app-footer>
  </div>
</div>
