import { Component, OnInit } from '@angular/core';
import { TrendsforeService } from '../../../../services/trendsfore.service';
import { complaintManagement } from '../../../../models/admin/login';
@Component({
  selector: 'app-complaints',
  templateUrl: './complaints.component.html',
  styleUrls: ['./complaints.component.css']
})
export class ComplaintsComponent implements OnInit {
  isLoadEnable: boolean;

  ComplaintModel: complaintManagement.Complaint[];
  isLoadMoreVisible: boolean;
  pageNo: number;
  baseUrl: String;
  constructor(private trendsfore: TrendsforeService) { }

  ngOnInit(): void {
    if (this.trendsfore.VerifyToken()) {
      this.pageNo = 1;
      this.isLoadEnable = true;
      this.isLoadMoreVisible = true;
      this.baseUrl = this.trendsfore.imageUrl();
      this.getComplaints(this.pageNo.toString());
    }

  }

  resolved(Id: number) {
    this.trendsfore.resolvedComplaint(Id).subscribe(data => {
      if (data.Flag) {
        this.refresh();
        this.trendsfore.success('Complaint has been resolved', 'Complaints & Reports');
      } else {
        this.trendsfore.error(data.Message, 'Complaints & Reports');
      }
    })
  }

  search(value: string) {
    if (value !== "") {
      this.trendsfore.searchedComplaints(value).subscribe(
        (data: complaintManagement.Complaints) => {
          if (data.Flag) {
            this.ComplaintModel = data.Data;
          }
        }
      );
    } else {
      this.refresh()
    }
  }

  loadMore() {
    this.pageNo += 1;
    this.isLoadEnable = true;
    this.getComplaints(this.pageNo.toString());
  }

  refresh() {
    this.pageNo = 1;
    this.isLoadMoreVisible = true;
    this.isLoadEnable = true;
    this.getComplaints(this.pageNo.toString());
  }
  private getComplaints(page: string) {
    this.trendsfore.complaintList(page).subscribe(
      (data: complaintManagement.Complaints) => {
        if (data.Flag) {
          if (page == '1') {
            this.ComplaintModel = data.Data;
            this.isLoadEnable = false;
          } else {
            this.ComplaintModel = this.ComplaintModel.concat(data.Data);
            this.isLoadEnable = false;
          }
        } else {
          this.isLoadEnable = false;
          this.isLoadMoreVisible = false;
        }
      }
    );

  }

}
