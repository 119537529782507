<app-aside [isLoadEnable]="this.isLoadEnable"></app-aside>
<div class="container-fluid container-application">
  <app-sidebar></app-sidebar>
  <!-- Content -->
  <div class="main-content position-relative">
    <app-header></app-header>
    <div class="page-content">
      <div class="card" *ngIf="this.ComplaintModel?.length <=0">
        <img class="img-center img" src="../../../assets/img/errors/nodata-found.png" alt="NO DATA">
      </div>
      <div *ngIf="this.ComplaintModel?.length > 0">
        <!-- Page title -->
        <div class="page-title">
          <div class="row justify-content-between align-items-center">
            <div
              class="col-md-6 d-flex align-items-center justify-content-between justify-content-md-start mb-3 mb-md-0">
              <!-- Page title + Go Back button -->
              <div class="d-inline-block">
                <h5 class="h4 d-inline-block font-weight-400 mb-0 text-white">Complaints & Reports</h5>
              </div>
              <!-- Additional info -->
              <div class="align-items-center ml-4 d-inline-flex">
                <span class="h4 text-info mb-0 mr-2">{{ this.ComplaintModel?.length }}</span>
                <span class="text-sm opacity-7 text-white">Total Complaints</span>
              </div>
            </div>
          </div>
        </div>
        <!-- Listing -->
        <div class="card">
          <!-- Card header -->
          <div class="card-header actions-toolbar border-0">
            <div class="actions-search" id="actions-search">
              <div class="input-group input-group-merge input-group-flush">
                <div class="input-group-prepend">
                  <span class="input-group-text bg-transparent"><i class="fas fa-search"></i></span>
                </div>
                <input type="text" (keyup)="search($event.target.value)" class="form-control form-control-flush"
                  placeholder="Type and hit enter ...">
                <div class="input-group-append">
                  <a (click)="this.refresh()" [routerLink]="" routerLinkActive="router-link-active"
                    class="input-group-text bg-transparent" data-action="search-close" data-target="#actions-search"><i
                      class="fas fa-times"></i></a>
                </div>
              </div>
            </div>
            <div class="row justify-content-between align-items-center">
              <div class="col">
                <h6 class="d-inline-block mb-0">Complaints & Reports</h6>
              </div>
              <div class="col text-right">
                <div class="actions">
                  <a [routerLink]="" routerLinkActive="router-link-active" class="action-item mr-3"
                    data-action="search-open" data-target="#actions-search">
                    <i class="fas fa-search"></i>
                  </a>
                  <a [routerLink]="" routerLinkActive="router-link-active" (click)="this.refresh()" class="action-item">
                    <i class="fas fa-sync"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <!-- Table -->
          <div class="table-responsive">
            <table class="table align-items-center">
              <thead>
                <tr>
                  <th scope="col" class="sort" data-sort="name">User</th>
                  <th scope="col" class="sort" data-sort="budget">Complaint</th>
                  <th scope="col" class="sort" data-sort="status">Status</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody class="list">
                <tr *ngFor="let complaint of ComplaintModel">
                  <th scope="row">
                    <div class="media align-items-center">
                      <div>
                        <img alt="Image placeholder" src="{{ this.baseUrl+complaint.UserImage }}"
                          class="avatar  rounded-circle">
                      </div>
                      <div class="media-body ml-4">
                        <a href="mailto:{{complaint.UserEmail}}" class="name mb-0 h6 text-sm">{{ complaint.UserFullName
                          }}</a>
                      </div>
                    </div>
                  </th>
                  <td class="budget">
                    {{ complaint.Complaint }}
                  </td>
                  <td>

                    <div class="dropdown" data-toggle="dropdown" *ngIf="!complaint.IsResolved">
                      <a class="action-item" role="button" data-toggle="dropdown" aria-haspopup="true"
                        aria-expanded="false">
                        <span class="badge badge-danger badge-lg mr-4">
                          <span class="status text-light">pending <i class="fas fa-edit"></i></span>
                        </span>
                      </a>
                      <div class="dropdown-menu dropdown-menu-right">
                        <a (click)="this.resolved(complaint.ComplaintId)" class="dropdown-item">Resolved</a>
                      </div>
                    </div>

                    <span class="badge badge-primary badge-lg mr-4" *ngIf="complaint.IsResolved">
                      <span class="status text-light">Resolved</span>
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <!-- Load more -->
        <div class="mt-4 text-center" *ngIf="this.isLoadMoreVisible">
          <a [routerLink]="" routerLinkActive="router-link-active"
            class="btn btn-sm btn-neutral rounded-pill shadow hover-translate-y-n3" (click)="this.loadMore()">Load more
            ...</a>
        </div>
      </div>
    </div>
    <app-footer></app-footer>
  </div>
</div>
