import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-page-profile',
  templateUrl: './page-profile.component.html',
  styleUrls: ['./page-profile.component.css']
})
export class PageProfileComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
