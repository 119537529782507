import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './layout/header/header.component';
import { FooterComponent } from './layout/footer/footer.component';
import { LoginComponent } from './auth/login/login.component';
import { HomeComponent } from './dashboard/home/home.component';
import { AsideComponent } from './layout/aside/aside.component';
import { SidebarComponent } from './layout/sidebar/sidebar.component';
import { ReactiveFormsModule } from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { RecoverComponent } from './auth/recover/recover.component';
import { ProfileComponent } from './dashboard/account/profile/profile.component';
import { SettingComponent } from './dashboard/account/setting/setting.component';
import { KeywordComponent } from './dashboard/keyword/keyword/keyword.component';
import { UsersComponent } from './dashboard/user/users/users.component';
import { UserProfileComponent } from './dashboard/user/user-profile/user-profile.component';
import { VolunteersComponent } from './dashboard/volunteer/volunteers/volunteers.component';
import { OpportunitiesComponent } from './dashboard/opportunity/opportunities/opportunities.component';
import { ComplaintsComponent } from './dashboard/feedback/complaints/complaints.component';
import { NgApexchartsModule } from 'ng-apexcharts';
import { PagesComponent } from './dashboard/page/pages/pages.component';
import { PageProfileComponent } from './dashboard/page/page-profile/page-profile.component';
import { CommunityProfileComponent } from './dashboard/community/community-profile/community-profile.component';
import { CommunitiesComponent } from './dashboard/community/communities/communities.component';
import { InitiativesComponent } from './dashboard/initiative/initiatives/initiatives.component';
import { InitiativeProfileComponent } from './dashboard/initiative/initiative-profile/initiative-profile.component';
import { TrendsforeComponent } from './dashboard/page/trendsfore/trendsfore.component';
import { DatabankComponent } from './dashboard/databank/databank.component';
import { FormsModule } from '@angular/forms';
import { QuestionsComponent } from './dashboard/trendsfore-questions/questions/questions.component';
import { VideosComponent } from './dashboard/live/videos/videos.component';

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    LoginComponent,
    HomeComponent,
    AsideComponent,
    SidebarComponent,
    RecoverComponent,
    ProfileComponent,
    SettingComponent,
    KeywordComponent,
    UsersComponent,
    UserProfileComponent,
    VolunteersComponent,
    OpportunitiesComponent,
    ComplaintsComponent,
    PagesComponent,
    PageProfileComponent,
    CommunityProfileComponent,
    CommunitiesComponent,
    InitiativesComponent,
    InitiativeProfileComponent,
    TrendsforeComponent,
    DatabankComponent,
    QuestionsComponent,
    VideosComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NgApexchartsModule,
    RouterModule,
    FormsModule,
  ],
})
export class AdminModule {}
