<app-aside [isLoadEnable]="this.isLoadEnable"></app-aside>
<div class="container-fluid container-application">
  <app-sidebar></app-sidebar>
  <!-- Content -->
  <div class="main-content position-relative">
    <app-header></app-header>
    <!-- Page content -->
    <div class="page-content">
      <!-- Create project modal -->
      <div class="modal fade" id="modal-project-create" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <form [formGroup]="this.OptionLevelForm">
              <div class="modal-body">
                <div *ngIf="this.isLoginOnSubmitErrorEnabled" class="alert alert-danger"
                  [innerHTML]="this.errorOnLoginSubmit">
                </div>
                <!-- Level Option -->
                <div class="form-group">
                  <label class="form-control-label">
                    Level Option*
                  </label>
                  <select formControlName="OptionId" class="form-control">
                    <option selected value="" disabled>Please select option for level</option>
                    <option *ngFor="let option of this.OptionModel" value="{{option.OptionId}}">
                      {{ option.OptionName }}
                    </option>
                  </select>
                </div>
                <!-- Level Name -->
                <div class="form-group">
                  <label class="form-control-label">
                    Level Name*
                  </label>
                  <input formControlName="LevelId" type="hidden" *ngIf="!this.isLevelCreate">
                  <input formControlName="LevelName" type="text" placeholder="Please enter level name"
                    class="form-control">
                </div>
                <!-- Required Points -->
                <div class="form-group">
                  <label class="form-control-label">
                    Required Points*
                  </label>
                  <input formControlName="RequiredPoints" type="number" placeholder="Please enter required points"
                    min="1" class="form-control">
                </div>
                <!-- Required Stars -->
                <div class="form-group">
                  <label class="form-control-label">
                    Required Stars*
                  </label>
                  <input formControlName="RequiredStars" type="number" min="1" placeholder="Please enter required stars"
                    class="form-control">
                </div>
                <!-- Per Answer Points -->
                <div class="form-group">
                  <label class="form-control-label">
                    Per Answer Points*
                  </label>
                  <input formControlName="PerAnswerPoints" type="number" min="1"
                    placeholder="Please enter per answer points" class="form-control">
                </div>
                <!-- Level Message -->
                <div class="form-group">
                  <label class="form-control-label mb-0">
                    Level Message*
                  </label>
                  <textarea formControlName="LevelDetail" class="form-control"
                    placeholder="Please enter prompt or message for level" data-toggle="autosize" rows="3"
                    style="resize: none;"></textarea>
                </div>
              </div>
              <div class="modal-footer">
                <button (click)='create()' type="button" class="btn btn-sm btn-primary rounded-pill mr-auto"
                  *ngIf="this.isLevelCreate">Save</button>

                <button (click)='update()' type="button" class="btn btn-sm btn-primary rounded-pill mr-auto"
                  data-dismiss="modal" *ngIf="!this.isLevelCreate">Update</button>

                <button type="button" data-dismiss="modal" class="btn btn-sm btn-link text-danger px-2">Cancel</button>
              </div>
            </form>
          </div>

        </div>
      </div>

      <!-- Page title -->
      <div class="page-title" *ngIf="this.OptionModel?.length > 0">
        <div class="row justify-content-between align-items-center">
          <div class="col-md-6 d-flex align-items-center justify-content-between justify-content-md-start mb-3 mb-md-0">
            <!-- Page title + Go Back button -->
            <div class="d-inline-block">
              <h5 class="h4 d-inline-block font-weight-400 mb-0 text-white">Volunteer Levels</h5>
            </div>
            <!-- Additional info -->
            <div class="align-items-center ml-4 d-inline-flex">
              <span class="h4 text-info mb-0 mr-2">{{ this.OptionModel?.length }}</span>
              <span class="text-sm opacity-7 text-white">Total Options</span>
            </div>
          </div>
          <div class="col-md-6 d-flex align-items-center justify-content-between justify-content-md-end">
            <div class="actions actions-dark d-inline-block">
              <a (click)="refresh()" class="action-item ml-3">
                <i class="fas fa-recycle mr-2"></i>Refresh
              </a>
            </div>
            <a href="#modal-project-create" (click)="save()"
              class="btn btn-sm btn-white btn-icon-only rounded-circle ml-4" data-toggle="modal">
              <span class="btn-inner--icon"><i class="fas fa-plus"></i></span>
            </a>
          </div>
        </div>
      </div>




      <!-- Listing -->

      <div class="card" *ngIf="this.OptionModel?.length<=0">
        <img class="img img-center" src="../../../assets/img/errors/nodata-found.png" alt="NO DATA">
      </div>


      <div class="card p-4" *ngFor="let option of this.OptionModel">
        <!-- Card header -->
        <div class="card-header actions-toolbar border-0">
          <div class="row justify-content-between align-items-center">
            <div class="col">
              <h6 class="d-inline-block mb-0">{{ option.OptionName}}</h6>
              <p>{{ option.OptionDetail }}</p>
            </div>
          </div>
        </div>
        <!-- Table -->
        <div class="table-responsive">
          <table class="table align-items-center table-bordered">
            <thead>
              <tr>
                <th>Level Name</th>
                <th>Required Points</th>
                <th>Required Stars</th>
                <th>Per Answer</th>
                <th>Level Message</th>
                <th></th>
              </tr>
            </thead>
            <tbody class="list">
              <tr *ngFor="let level of option.Levels">
                <th>{{ level.LevelName }}</th>
                <td>{{ level.RequiredPoints }}</td>
                <td>{{ level.RequiredStars }}</td>
                <td>{{ level.PerAnswerPoints }} Points</td>
                <td class="wrap-details">
                  <div *ngIf="level.LevelDetail===''">
                    None
                  </div>
                  <div *ngIf="level.LevelDetail!==''">
                    {{ level.LevelDetail }}
                  </div>
                </td>
                <td>
                  <div class="dropdown" data-toggle="dropdown">
                    <a class="action-item" role="button" data-toggle="dropdown" aria-haspopup="true"
                      aria-expanded="false">
                      <i class="fas fa-ellipsis-h"></i>
                    </a>
                    <div class="dropdown-menu dropdown-menu-right">
                      <a href="#modal-project-create" class="dropdown-item" (click)="edit(level)" data-toggle="modal">
                        <i class="fas fa-pencil-alt"></i>
                        Edit
                      </a>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>


    </div>
    <app-footer></app-footer>
  </div>
</div>
