<!--LOADER-->
<div class="loader-container" *ngIf="this.isLoadEnable">
  <div class="main-loader">
  </div>
</div>
<!--LOADER-->
<app-aside></app-aside>
<div class="container-fluid container-application">
  <app-sidebar></app-sidebar>
  <!-- Content -->
  <div class="main-content position-relative">
    <app-header></app-header>
    <!-- Page content -->
    <div class="page-content">


      <div class="card" *ngIf="!this.UserProfile?.Flag">
        <img class="img-center img" src="../../../assets/img/errors/nodata-found.png" alt="NO DATA">
      </div>


      <!-- Page title -->
      <div class="page-title" *ngIf="this.UserProfile?.Flag">
        <div class="row justify-content-between align-items-center">
          <div class="col-md-6 d-flex align-items-center justify-content-between justify-content-md-start mb-3 mb-md-0">
            <!-- Page title + Go Back button -->
            <div class="d-inline-block">
              <h5 class="h4 d-inline-block font-weight-400 mb-0 text-white">{{
                this.UserProfile.Data.UserPersonal.UserFullName }}</h5>
            </div>
            <!-- Additional info -->
          </div>
          <div class="col-md-6 d-flex align-items-center justify-content-between justify-content-md-end">
            <div class="actions actions-dark d-inline-block">
              <a href="#" class="action-item ml-md-4">
                <i class="fas fa-file-export mr-2"></i>Export
              </a>
              <a href="#" class="action-item ml-3">
                <i class="fas fa-cog mr-2"></i>Settings
              </a>
            </div>
          </div>
        </div>
      </div>

      <div class="row" *ngIf="this.UserProfile?.Flag">
        <div class="col-lg-4">
          <!-- Profile stats -->
          <div class="card card-fluid">
            <div class="card-header">
              <div class="row align-items-center">
                <div class="col-auto">
                  <!-- Avatar -->
                  <a class="avatar rounded-circle">
                    <img alt="Image placeholder" src="{{ this.baseUrl + this.UserProfile.Data.UserPersonal.UserImage }}"
                      class="">
                  </a>
                </div>
                <div class="col ml-md-n2">
                  <a class="d-block h6 mb-0">{{ this.UserProfile?.Data.UserPersonal.UserFullName }}</a>
                  <small class="d-block text-muted">{{ this.UserProfile?.Data.UserPersonal.UserRole }}</small>
                </div>
                <div class="col-auto">
                  <!-- Button trigger modal -->
                  <button *ngIf="this.UserProfile?.Data.UserPersonal.UserRole==='Digital Volunteer'" data-toggle="modal"
                    data-target="#modal-change-level" type="button"
                    class="btn btn-xs btn-primary btn-icon rounded-pill">
                    <span class="btn-inner--icon"><i class="fas fa-edit"></i></span>
                    <span class="btn-inner--text">Level</span>
                  </button>
                  <!-- Modal -->
                  <div class="modal fade" id="modal-change-level" tabindex="-1" role="dialog"
                    aria-labelledby="modal-change-username" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered" role="document">
                      <form [formGroup]="volunteerForm">
                        <div *ngIf="this.isLoginOnSubmitErrorEnabled" class="alert alert-danger">
                          {{ this.errorOnLoginSubmit }}
                        </div>

                        <div class="modal-content">
                          <div class="modal-header">
                            <div class="modal-title d-flex align-items-center" id="modal-title-change-username">
                              <div>
                                <div class="icon icon-sm icon-shape icon-info rounded-circle shadow mr-3">
                                  <i class="far fa-user"></i>
                                </div>
                              </div>
                              <div>
                                <h6 class="mb-0">Change Volunteer Level</h6>
                              </div>
                            </div>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>
                          <div class="modal-body">
                            <div class="row">
                              <div class="col-sm-12" *ngIf="this.UserProfile?.Data.UserPersonal.Level!=null">
                                <div class="form-group">
                                  <label class="form-control-label">Current Level</label>
                                  <input class="form-control" readonly type="text"
                                    value="{{ this.UserProfile?.Data.UserPersonal.Level }}">
                                </div>
                              </div>
                              <div class="col-sm-12">
                                <div class="form-group">
                                  <label class="form-control-label">New Level</label>
                                  <select (change)="getSelectedOptionText($event)" formControlName="LevelId"
                                    class="form-control">
                                    <option selected value="" disabled>Select volunteer level</option>
                                    <option *ngFor="let level of this.volunteerLevels?.Data" value="{{level.LevelId}}">
                                      {{ level.LevelName }}</option>
                                  </select>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="modal-footer">
                            <button (click)="submitVolunteer()" type="button"
                              class="btn btn-sm btn-secondary rounded-pill">
                              Update Volunteer
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>

                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="row mb-3">
                <div class="col-6 text-center">
                  <span class="h5 mb-0">{{ this.UserProfile?.Data.UserPersonal.UserStars }}</span>
                  <span class="d-block text-sm">Stars</span>
                </div>
                <div class="col-6 text-center">
                  <span class="h5 mb-0">{{ this.UserProfile?.Data.UserPersonal.UserCoins }}</span>
                  <span class="d-block text-sm">Coins</span>
                </div>
              </div>
              <div class="row">
                <div class="col-6 text-center">
                  <span class="h5 mb-0">{{ this.UserProfile?.Data.UserPersonal.UserFollowers }}</span>
                  <span class="d-block text-sm">Followers</span>
                </div>
                <div class="col-6 text-center">
                  <span class="h5 mb-0">{{ this.UserProfile?.Data.UserPersonal.UserFollowers }}</span>
                  <span class="d-block text-sm">Following</span>
                </div>
              </div>
            </div>
            <div class="card-footer">
              <div class="row align-items-center">
                <div class="col-12">
                  <h5>About</h5>
                  <h6 class="h6 text-muted">{{ this.UserProfile?.Data.UserPersonal.CategoryName }}-{{
                    this.UserProfile?.Data.UserPersonal.Level }}</h6>
                  <p>{{ this.UserProfile?.Data.UserPersonal.UserAbout }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-8">
          <!-- Profile contacts -->
          <div class="card card-fluid">
            <div class="card-body">

              <div class="row align-items-center">
                <div class="col">
                  <h6 class="text-sm mb-0">
                    <i class="fas fa-envelope mr-2"></i>Email Address
                  </h6>
                </div>
                <div class="col-auto">
                  <span class="text-sm">{{ this.UserProfile?.Data.UserPersonal.UserEmail }}</span>
                </div>
              </div>
              <hr class="my-3">
              <div class="row align-items-center">
                <div class="col">
                  <h6 class="text-sm mb-0">
                    <i class="fas fa-phone-alt mr-2"></i>Phone Number
                  </h6>
                </div>
                <div class="col-auto">
                  <span class="text-sm">{{ this.UserProfile?.Data.UserPersonal.UserPhone }}</span>
                </div>
              </div>
              <hr class="my-3">
              <div class="row align-items-center">
                <div class="col">
                  <h6 class="text-sm mb-0">
                    <i class="fab fa-linkedin mr-2"></i>Linkedin
                  </h6>
                </div>
                <div class="col-auto">
                  <span class="text-sm" *ngIf="this.UserProfile?.Data.UserPersonal.UserLinkedin!=null ">{{
                    this.UserProfile?.Data.UserPersonal.UserLinkedin }}</span>
                  <span class="text-sm" *ngIf="this.UserProfile?.Data.UserPersonal.UserLinkedin===null ">None</span>
                </div>
              </div>
              <hr class="my-3">
              <div class="row align-items-center">
                <div class="col">
                  <h6 class="text-sm mb-0">
                    <i class="fab fa-instagram mr-2"></i>Instagram
                  </h6>
                </div>
                <div class="col-auto">
                  <span class="text-sm" *ngIf="this.UserProfile?.Data.UserPersonal.UserInstagram!=null ">{{
                    this.UserProfile?.Data.UserPersonal.UserInstagram }}</span>
                  <span class="text-sm" *ngIf="this.UserProfile?.Data.UserPersonal.UserInstagram===null ">None</span>
                </div>
              </div>
              <hr class="my-3">
              <div class="row align-items-center">
                <div class="col">
                  <h6 class="text-sm mb-0">
                    <i class="fab fa-youtube mr-2"></i>Youtube
                  </h6>
                </div>
                <div class="col-auto">
                  <span class="text-sm" *ngIf="this.UserProfile?.Data.UserPersonal.UserYoutube!=null ">{{
                    this.UserProfile?.Data.UserPersonal.UserYoutube }}</span>
                  <span class="text-sm" *ngIf="this.UserProfile?.Data.UserPersonal.UserYoutube===null ">None</span>
                </div>
              </div>
              <hr class="my-3">
              <div class="row align-items-center">
                <div class="col">
                  <h6 class="text-sm mb-0">
                    <i class="fab fa-twitter mr-2"></i>Twitter
                  </h6>
                </div>
                <div class="col-auto">
                  <span class="text-sm" *ngIf="this.UserProfile?.Data.UserPersonal.UserTwitter!=null ">{{
                    this.UserProfile?.Data.UserPersonal.UserTwitter }}</span>
                  <span class="text-sm" *ngIf="this.UserProfile?.Data.UserPersonal.UserTwitter===null ">None</span>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>

      <div class="row" *ngIf="this.UserProfile?.Flag">
        <div class="col-xl-9 col-md-6">
          <div class="card card-fluid">
            <div class="card-header border-0">
              <h6 class="mb-0">{{ this.UserProfile?.Data.UserPersonal.UserFullName }}'s Background</h6>
            </div>
            <img *ngIf="this.UserProfile?.Data.UserEducation===null" class="img card-img-top"
              src="../../../assets/img/errors/nodata-found.png" alt="NO DATA">

            <div class="table-responsive scroll-bar scroll-content table-fixed-height"
              *ngIf="this.UserProfile?.Data.UserEducation!=null">
              <table class="table align-items-center">
                <thead>
                  <tr>
                    <th scope="col">School Name</th>
                    <th scope="col">Degree Name</th>
                    <th scope="col">Field Name</th>
                    <th scope="col">Start Date</th>
                    <th scope="col">End Date</th>
                  </tr>
                </thead>
                <tbody class="list">
                  <tr *ngFor="let education of UserProfile.Data.UserEducation">
                    <th scope="row">
                      {{ education.SchoolName }}
                    </th>
                    <td>
                      {{ education.DegreeName }}
                    </td>
                    <td>
                      {{ education.FieldName }}

                    </td>
                    <td>
                      {{ education.StartDate }}
                    </td>
                    <td>
                      <span class="badge badge-dot mr-4" *ngIf="education.IsPresent">
                        <i class="bg-info"></i>
                        <span class="status">Present</span>
                      </span>
                      <span *ngIf="!education.IsPresent">
                        {{ education.EndDate }}
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-md-6">
          <div class="card card-fluid">
            <div class="card-header border-0">
              <h6 class="mb-0">{{ this.UserProfile?.Data.UserPersonal.UserFullName }}'s Initiatives</h6>
            </div>
            <img *ngIf="this.UserProfile?.Data.UserInitiative===null" class="img card-img-top"
              src="../../../assets/img/errors/nodata-found.png" alt="NO DATA">
            <div class="table-responsive scroll-bar scroll-content table-fixed-height"
              *ngIf="this.UserProfile?.Data.UserInitiative!=null">
              <table class="table align-items-center">
                <thead>
                  <tr>
                    <th scope="col">Initiative Name</th>
                  </tr>
                </thead>
                <tbody class="list">
                  <tr *ngFor="let initiative of UserProfile.Data.UserInitiative">
                    <th scope="row">
                      <div class="media align-items-center">
                        <div>
                          <img alt="Image placeholder" src="{{ this.baseUrl+initiative.InitiativeImage }}"
                            class="avatar  rounded-circle avatar-sm">
                        </div>
                        <div class="media-body ml-4">
                          <a class="name mb-0 h6 text-sm">{{ initiative.InitiativeName }}</a>
                        </div>
                      </div>
                    </th>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="this.UserProfile?.Flag">
        <div class="col-xl-12">
          <div class="card card-fluid">
            <div class="card-header border-0">
              <h6 class="mb-0">{{ this.UserProfile?.Data.UserPersonal.UserFullName }}'s Experience</h6>
            </div>
            <img *ngIf="this.UserProfile?.Data.UserExperience===null" class="img card-img-top"
              src="../../../assets/img/errors/nodata-found.png" alt="NO DATA">

            <div class="table-responsive scroll-bar scroll-content table-fixed-height"
              *ngIf="this.UserProfile?.Data.UserExperience!=null">
              <table class="table align-items-center">
                <thead>
                  <tr>
                    <th scope="col">Title</th>
                    <th scope="col">Company Name</th>
                    <th scope="col">Industry</th>
                    <th scope="col">Location</th>
                    <th scope="col">Start Date</th>
                    <th scope="col">End Date</th>
                  </tr>
                </thead>
                <tbody class="list">
                  <tr *ngFor="let experience of UserProfile.Data.UserExperience">

                    <th scope="row">
                      {{ experience.Title }}
                    </th>
                    <td>
                      {{ experience.CompanyName }}
                    </td>
                    <td>
                      {{ experience.Industry }}
                    </td>
                    <td>
                      {{ experience.Location }}
                    </td>
                    <td>
                      {{ experience.StartDate }}
                    </td>
                    <td>
                      <span class="badge badge-dot mr-4" *ngIf="experience.IsPresent">
                        <i class="bg-info"></i>
                        <span class="status">Present</span>
                      </span>
                      <span *ngIf="!experience.IsPresent">
                        {{ experience.EndDate }}
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <app-footer></app-footer>
  </div>
</div>
