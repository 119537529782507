<app-aside [isLoadEnable]="this.isLoadEnable"></app-aside>
<div class="container-fluid container-application">
  <app-sidebar></app-sidebar>
  <!-- Content -->
  <div class="main-content position-relative">
    <app-header></app-header>
    <!-- Page content -->
    <div class="page-content">
      <!-- Page title -->
      <div class="page-title">
        <div class="row justify-content-between align-items-center">
          <div class="col-md-6 mb-3 mb-md-0">
            <h5 class="h3 font-weight-400 mb-0 text-white">Morning, Admin!</h5>
            <span class="text-sm text-white opacity-8">Have a nice day!</span>
          </div>
        </div>
      </div>
      <div class="card" *ngIf="!this.HomeModel?.Flag">
        <img class="img-center img" src="../../../assets/img/errors/nodata-found.png" alt="NO DATA">
      </div>
      <div *ngIf="this.HomeModel?.Flag">
        <!-- Stats -->
        <div class="row">
          <div class="col-xl-3 col-md-6">

            <div class="card shadow-dark-lg card-stats bg-gradient-info border-0 hover-shadow-lg">
              <!-- Card body -->
              <div class="card-body">
                <div class="row">
                  <div class="col">
                    <h6 class="text-light mb-1">New Users</h6>
                    <span class="h4 font-weight-bold mb-0 text-light ">0{{this.HomeModel?.Data.NewUsers }}</span>
                  </div>
                  <div class="col-auto">
                    <i class=" fas fa-user-friends fa-3x text-light"></i>
                  </div>
                </div>

                <div class="row mt-2">
                  <div class="col-12 ">
                    <span class="mb-0 text-light">{{this.date}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-3 col-md-6">
            <div class="card card-stats bg-gradient-info border-0 hover-shadow-lg">
              <!-- Card body -->
              <div class="card-body">
                <div class="row">
                  <div class="col">
                    <h6 class="text-light mb-1">Pages</h6>
                    <span class="h4 font-weight-bold mb-0 text-light ">0{{this.HomeModel?.Data.TotalPages }}</span>
                  </div>
                  <div class="col-auto">
                    <i class=" fas fa-book-open fa-3x text-light"></i>
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-12 ">
                    <span class="mb-0 text-light">{{this.date}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-3 col-md-6">
            <div class="card card-stats bg-gradient-info border-0 hover-shadow-lg">
              <!-- Card body -->
              <div class="card-body">
                <div class="row">
                  <div class="col">
                    <h6 class="text-light mb-1">Communities</h6>
                    <span class="h4 font-weight-bold mb-0 text-light ">0{{this.HomeModel?.Data.TotalCommunities
                      }}</span>
                  </div>
                  <div class="col-auto">
                    <i class=" fas fa-users fa-3x text-light"></i>
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-12 ">
                    <span class="mb-0 text-light">{{this.date}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-3 col-md-6">
            <div class="card card-stats bg-gradient-info border-0 hover-shadow-lg">
              <!-- Card body -->
              <div class="card-body">
                <div class="row">
                  <div class="col">
                    <h6 class="text-light mb-1">Initiatives</h6>
                    <span class="h4 font-weight-bold mb-0 text-light ">0{{this.HomeModel?.Data.TotalInitiatives
                      }}</span>
                  </div>
                  <div class="col-auto">
                    <i class=" fas fa-infinity fa-3x text-light"></i>
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-12 ">
                    <span class="mb-0 text-light">{{this.date}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Stats2 -->
        <div class="row">
          <div class="col-xl-3 col-md-6 ">
            <div class="card shadow-dark-sm card-stats bg-gradient-white border-0">
              <!-- Card body -->
              <div class="card-body">
                <div class="row">
                  <div class="col">
                    <h6 class=" mb-1">Campaigns</h6>
                    <span class="h4 font-weight-bold mb-0 ">0{{this.HomeModel?.Data.TotalCampaigns }}</span>
                  </div>
                  <div class="col-auto">
                    <i class=" fas fa-campground fa-3x text-primary"></i>
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-12 ">
                    <span class="mb-0">{{this.date}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-3 col-md-6 ">
            <div class="card shadow-dark-sm card-stats bg-gradient-white border-0">
              <!-- Card body -->
              <div class="card-body">
                <div class="row">
                  <div class="col">
                    <h6 class="mb-1">Questions</h6>
                    <span class="h4 font-weight-bold mb-0">0{{this.HomeModel?.Data.TotalQuestions }}</span>
                  </div>
                  <div class="col-auto">
                    <i class=" fas fa-question-circle fa-3x text-primary"></i>
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-12 ">
                    <span class="mb-0">{{this.date}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-3 col-md-6 ">
            <div class="card shadow-dark-sm card-stats bg-gradient-white border-0">
              <!-- Card body -->
              <div class="card-body">
                <div class="row">
                  <div class="col">
                    <h6 class="mb-1">Answers</h6>
                    <span class="h4 font-weight-bold mb-0">0{{this.HomeModel?.Data.TotalAnswers }}</span>
                  </div>
                  <div class="col-auto">
                    <i class=" fas fa-info-circle fa-3x text-primary"></i>
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-12 ">
                    <span class="mb-0">{{this.date}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-3 col-md-6 ">
            <div class="card shadow-dark-sm card-stats bg-gradient-white border-0">
              <!-- Card body -->
              <div class="card-body">
                <div class="row">
                  <div class="col">
                    <h6 class=" mb-1">Live Videos</h6>
                    <span class="h4 font-weight-bold mb-0 ">0{{this.HomeModel?.Data.TotalLiveVideos }}</span>
                  </div>
                  <div class="col-auto">
                    <i class=" fas fa-video fa-3x text-primary"></i>
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-12 ">
                    <span class="mb-0">{{this.date}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Stats3 -->
        <div class="row">
          <div class="col-xl-3 col-md-6 ">
            <div class="card shadow-dark-sm card-stats bg-gradient-white border-0">
              <!-- Card body -->
              <div class="card-body">
                <div class="row">
                  <div class="col">
                    <h6 class="mb-1">Donations</h6>
                    <span class="h4 font-weight-bold mb-0">0{{this.HomeModel.Data.TotalDonations }}C</span>
                  </div>
                  <div class="col-auto">
                    <i class=" fas fa-coins fa-3x text-primary"></i>
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-12 ">
                    <span class="mb-0">{{this.date}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-3 col-md-6 ">
            <div class="card shadow-dark-sm card-stats bg-gradient-white border-0">
              <!-- Card body -->
              <div class="card-body">
                <div class="row">
                  <div class="col">
                    <h6 class="mb-1">Sponsorships</h6>
                    <span class="h4 font-weight-bold mb-0">0{{this.HomeModel?.Data.TotalSponsorships }}C</span>
                  </div>
                  <div class="col-auto">
                    <i class=" fas fa-donate fa-3x text-primary"></i>
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-12 ">
                    <span class="mb-0">{{this.date}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-3 col-md-6 ">
            <div class="card shadow-dark-sm card-stats bg-gradient-white border-0">
              <!-- Card body -->
              <div class="card-body">
                <div class="row">
                  <div class="col">
                    <h6 class="mb-1">Premium Pages</h6>
                    <span class="h4 font-weight-bold mb-0">0{{this.HomeModel?.Data.TotalPremiumPages }}</span>
                  </div>
                  <div class="col-auto">
                    <i class=" fas fa-book-open fa-3x text-primary"></i>
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-12 ">
                    <span class="mb-0">{{this.date}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-3 col-md-6 ">
            <div class="card shadow-dark-sm card-stats bg-gradient-white border-0">
              <!-- Card body -->
              <div class="card-body">
                <div class="row">
                  <div class="col">
                    <h6 class="mb-1">Premium Comm..</h6>
                    <span class="h4 font-weight-bold mb-0">0{{this.HomeModel?.Data.TotalPremiumCommunities }}</span>
                  </div>
                  <div class="col-auto">
                    <i class=" fas fa-users fa-3x text-primary"></i>
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-12 ">
                    <span class="mb-0">{{this.date}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-xl-8 col-md-6">
            <div class="card shadow-dark-sm card-fluid">
              <div class="card-header">
                <h6 class="mb-0">Users By Location</h6>
              </div>
              <div class="card-body">
                <!-- Chart -->
                <apx-chart [series]="CountryChartOptions.series" [chart]="CountryChartOptions.chart"
                  [xaxis]="CountryChartOptions.xaxis" [title]="CountryChartOptions.title">
                </apx-chart>
              </div>
            </div>
          </div>
          <div class="col-xl-4 col-md-6">
            <div class="card shadow-dark-sm card-fluid">
              <div class="card-header">
                <h6 class="mb-0">Users By Volunteer Level</h6>
              </div>
              <div class="card-body text-center">
                <h5 class="mb-4">Analytics</h5>
                <apx-chart [series]="VolunteerChartOptions.series" [chart]="VolunteerChartOptions.chart"
                  [labels]="VolunteerChartOptions.labels" [title]="VolunteerChartOptions.title"
                  [theme]="VolunteerChartOptions.theme" [fill]="VolunteerChartOptions.fill"
                  [yaxis]="VolunteerChartOptions.yaxis" [stroke]="VolunteerChartOptions.stroke"
                  [legend]="VolunteerChartOptions.legend" [plotOptions]="VolunteerChartOptions.plotOptions"></apx-chart>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-4 col-sm-6">
            <div class="card shadow-dark-sm shadow-dark-sm card-fluid">
              <div class="card-body">
                <div class="card-header mb-3">
                  <h5>General Revenue Split</h5>
                </div>
                <div class="row align-items-center mb-4">
                  <div class="col-auto">
                    <i class="fas fa-money-bill fa-3x text-primary"></i>
                  </div>
                  <div class="col">
                    <a class="d-block h6 mb-0">Upgrading Pages</a>
                    <a class="text-sm text-muted">{{this.HomeModel?.Data.SplitRevenue.UpgradingPages }} AED</a>
                  </div>
                </div>
                <div class="row align-items-center mb-4">
                  <div class="col-auto">
                    <i class="fas fa-money-bill fa-3x text-primary"></i>
                  </div>
                  <div class="col">
                    <a class="d-block h6 mb-0">Upgrading Communities</a>
                    <a class="text-sm text-muted">{{this.HomeModel?.Data.SplitRevenue.UpgradingCommunities }} AED</a>
                  </div>
                </div>
                <div class="row align-items-center mb-4">
                  <div class="col-auto">
                    <i class="fas fa-money-bill fa-3x text-primary"></i>
                  </div>
                  <div class="col">
                    <a class="d-block h6 mb-0">Purchase Coins</a>
                    <a class="text-sm text-muted">{{this.HomeModel?.Data.SplitRevenue.PurchaseCoins }} AED</a>
                  </div>
                </div>

                <div class="row align-items-center">
                  <div class="col-auto">
                    <i class="fas fa-money-bill fa-3x text-primary"></i>
                  </div>
                  <div class="col">
                    <a class="d-block h6 mb-0">Boost & Campaigns</a>
                    <a class="text-sm text-muted">{{this.HomeModel.Data.SplitRevenue.BoostAndCampaigns }} AED</a>
                  </div>
                </div>
                <a class="btn btn-sm btn-block btn-secondary mt-3">{{this.HomeModel?.Data.SplitRevenue.Total }} AED</a>
              </div>
            </div>
          </div>

          <div class="col-lg-8">
            <div class="card shadow-dark-sm card-fluid">
              <div class="card-body">
                <div class="card-header mb-3">
                  <h5 class="mb-0">
                    General Donation History
                  </h5>
                </div>
                <div class="table-responsive scroll-bar table-fixed-height">
                  <table class="table table-bordered align-content-around">
                    <thead>
                      <tr>
                        <th>Donor</th>
                        <th>Receiver</th>
                        <th>Donation</th>
                        <th>Donted At</th>
                      </tr>
                    </thead>
                    <tbody class="list">
                      <tr *ngFor="let donation of DonationModel">
                        <th scope="row">
                          <div class="media align-items-center">
                            <div>
                              <a [routerLink]="['/admin/users/'+donation.UserId]" routerLinkActive="router-link-active">
                                <img alt="Image placeholder" src="{{ this.baseUrl+donation.UserImage }}"
                                  class="avatar  rounded-circle">
                              </a>
                            </div>
                          </div>
                        </th>
                        <td>
                          <div class="media align-items-center">
                            <div *ngIf="donation.ReferenceType==='initiative'">
                              <a [routerLink]="['/admin/initiatives/'+donation.ReceiverId]"
                                routerLinkActive="router-link-active" class="btn btn-light">{{ donation.ReferenceType
                                }}</a>
                            </div>

                            <div *ngIf="donation.ReferenceType==='page'">
                              <a [routerLink]="['/admin/pages/'+donation.ReceiverId]"
                                routerLinkActive="router-link-active" class="btn btn-light">{{ donation.ReferenceType
                                }}</a>
                            </div>

                            <div *ngIf="donation.ReferenceType==='user'">
                              <a [routerLink]="['/admin/users/'+donation.ReceiverId]"
                                routerLinkActive="router-link-active" class="btn btn-light">{{ donation.ReferenceType
                                }}</a>
                            </div>

                            <div *ngIf="donation.ReferenceType==='community'">
                              <a [routerLink]="['/admin/communities/'+donation.ReceiverId]"
                                routerLinkActive="router-link-active" class="btn btn-light">{{ donation.ReferenceType
                                }}</a>
                            </div>
                          </div>
                        </td>
                        <td>
                          {{ donation.Donation }} Coins
                        </td>
                        <td>
                          <h6>
                            <span class="badge badge-primary mr-4">
                              {{ donation.donated_at }}
                            </span>
                          </h6>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div class="mt-4 text-center">
                    <a [routerLink]="" routerLinkActive="router-link-active"
                      class="btn btn-sm btn-neutral rounded-pill shadow hover-translate-y-n3" (click)="this.loadMore()"
                      *ngIf="this.isLoadMoreVisible">Load
                      more ...</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
    <app-footer></app-footer>
  </div>
</div>
